import axios from 'axios';
import Cookies from 'js-cookie';

import { BACKEND_URL } from './constants';

export const authorization = (() => {
    const getToken = () => jwt || Cookies.get('token') || localStorage.getItem('token');
    const getTokenRefresh = () =>
        jwtRefresh || Cookies.get('tokenRefresh') || localStorage.getItem('tokenRefresh');
    let jwt = null;
    let jwtRefresh = null;
    let logoutEventName = `${BACKEND_URL}logout/`;
    let refreshEndpoint = `${BACKEND_URL}gt/customer/api/v1/auth/token/refresh/`;

    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            jwt = null;
            jwtRefresh = null;
        }
    });

    const setRefreshTokenEndpoint = (endpoint) => {
        refreshEndpoint = endpoint;
    };

    let _refreshTimeout;

    const _refreshToken = (delay = 12000) => {
        _refreshTimeout = window.setTimeout(getRefreshedToken, delay - 5000);
    };

    const _breakRefreshToken = () => {
        if (_refreshTimeout) window.clearTimeout(_refreshTimeout);
    };

    const getRefreshedToken = (callback, tokenRefresh) => {
        const data = {
            refresh: getTokenRefresh() || tokenRefresh,
        };

        const options = {
            url: refreshEndpoint,
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios(options)
            .then((response) => {
                setToken(response.data.access, response.data.refresh);

                return response.data;
            })
            .catch(() => {
                removeToken();
                if (callback) callback();
            });
    };

    const isAuthenticated = !!getToken();
    const setToken = (token, tokenRefresh, _expiry) => {
        jwt = token;
        jwtRefresh = tokenRefresh;

        localStorage.setItem('token', token);
        localStorage.setItem('tokenRefresh', tokenRefresh);

        Cookies.set('token', token, { expires: 1 / 24, path: '/' });
        Cookies.set('tokenRefresh', tokenRefresh, { expires: 1 / 24, path: '/' });

        // _refreshToken(expiry);
    };

    const removeToken = () => {
        jwt = null;
        jwtRefresh = null;
        _breakRefreshToken();
        localStorage.removeItem('token');
        localStorage.removeItem('tokenRefresh');
        Cookies.remove('tokenRefresh', { path: '/' });
        Cookies.remove('token', { path: '/' });
        window.localStorage.setItem(logoutEventName, String(Date.now()));
    };

    const setLogoutEventName = (name) => {
        logoutEventName = name;
    };

    return {
        getToken,
        getTokenRefresh,
        setToken,
        removeToken,
        setRefreshTokenEndpoint,
        getRefreshedToken,
        setLogoutEventName,
        isAuthenticated,
    };
})();
