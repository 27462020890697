import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { UserMenu } from '@components/UserMenu';
import { setOpenPlatform } from '@store/actions';
import { isOpenPlatformSelector, platformSelector } from '@store/reducers/platform';

import styles from './styles.module.scss';

export const Header = ({ title }) => {
    const dispatch = useDispatch();
    const platform = useSelector(platformSelector);
    const isMobilePlatform = platform === 'mobile';
    const isOpen = useSelector(isOpenPlatformSelector);

    const open = () => {
        dispatch(setOpenPlatform(true));
    };

    return (
        <div className={styles.line1}>
            {isMobilePlatform && !isOpen && (
                <div className={styles.menu_icon} onClick={open}>
                    <img alt='icon' src='/balance_icon.svg' />
                </div>
            )}
            <div
                className={clsx(styles.title, {
                    [styles.isMobilePlatform]: isMobilePlatform,
                })}
            >
                {title}
            </div>
            <UserMenu />
        </div>
    );
};
