import { AddressSuggestions } from 'react-dadata';

import { Editable, Select } from '@components';
import { DADATA_API_KEY } from '@utils';

import styles from './styles.module.scss';

const Elevator =
    ({ updateData, options, selected }) =>
    (_value, _setValue, handleSubmit, onBlur, setOpened) =>
        (
            <Select
                onChange={(value) => {
                    if (value !== '') {
                        handleSubmit(value);
                    }
                    onBlur();
                }}
                onDropdownVisibleChange={setOpened}
                value={selected}
                options={options}
                updateData={updateData}
            />
        );

const options = [
    {
        title: 'Неизвестно',
        value: null,
    },
    {
        title: 'Есть',
        value: true,
    },
    {
        title: 'Нет',
        value: false,
    },
];

export const elevatorEditor = ({ elem, c, updateData, itemPk }) => (
    <Editable
        inputComponent={Elevator({
            selected: elem.items[0][c],
            options: options.map(({ title, value }) => ({ title, value })),
            updateData,
        })}
        field='has_elevator'
        pk={elem.pk}
        item_pk={itemPk}
        text={elem[c]}
        editable={elem.status.id === 'autotarification_attempt'}
    />
);

export const expandedElevatorEditor = ({ elem, addr, c, updateData, index }) => (
    <Editable
        inputComponent={Elevator({
            selected: addr[c],
            options: options.map(({ title, value }) => ({ title, value })),
            updateData,
        })}
        field='has_elevator'
        pk={elem.pk}
        item_pk={addr.id}
        text={elem.elevators[index]}
        editable={elem.status.id === 'autotarification_attempt'}
    />
);

export const AddressesEditor = ({ elem, addr }) => {
    return (
        <Editable
            inputComponent={(value, setValue, handleSubmit) => (
                <AddressSuggestions
                    inputProps={{
                        autoFocus: true,
                    }}
                    defaultQuery={addr.text}
                    token={DADATA_API_KEY}
                    value={value}
                    onChange={(addr) => {
                        setValue(addr);
                        handleSubmit(addr.value);
                    }}
                    containerClassName={styles.addressSuggestions}
                />
            )}
            field='address'
            pk={elem.pk}
            item_pk={addr.id}
            text={addr.text}
            editable={elem.status.id === 'autotarification_attempt'}
        />
    );
};
