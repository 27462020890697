import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { authorization } from '@utils';

export const PrivateRoute = memo(({ children }) => {
    const user = useSelector((state) => state.user);
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

    return (
        <Route
            {...user}
            render={(props) => {
                if (isAuthenticated || authorization.getToken()) {
                    return children;
                }

                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
});
