import { combineReducers } from 'redux';

import calculate_services from './calculate_services';
import cards from './cards';
import contacts from './contacts';
import imports from './imports';
import invoices from './invoices';
import locations from './locations';
import platform from './platform';
import reports from './reports';
import requests from './requests';
import requests_on_map from './requests_on_map';
import selected_request_on_map from './selected_request_on_map';
import services from './services';
import user from './user';

export default combineReducers({
    user,
    services,
    requests,
    requests_on_map,
    selected_request_on_map,
    reports,
    contacts,
    imports,
    invoices,
    locations,
    platform,
    calculate_services,
    cards,
});
