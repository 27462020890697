export const nameItemsList = {
    date: 'Дата',
    time: 'Время',
    index: 'Индекс',
    service: 'Услуга',
    route: 'Прикрепить к маршруту',
    volume: 'Объем',
    mass: 'Масса',
    places: 'Кол-во мест',
    max_size: 'М. габарит',
    floor: 'Этаж',
    carrying_distance: 'Пронос',
    has_elevator: 'Лифт',
    shipment_type: 'Характер работ',
    address: 'Адрес',
    driver: 'Контактное лицо, ФИО',
    phones: 'Контактное лицо, тел',
    location: 'Филиал',
    workers: 'Исполнителей',
    expected_hours: 'Кол-во часов',
    workers_required: 'Грузчиков',
};

export const formatError = (messages, defaultMessage) => {
    if (!messages) {
        return defaultMessage;
    }

    let errorMessage = ``;

    for (const [name, message] of Object.entries(messages)) {
        const itemMessage = message?.join(';');

        errorMessage = `${errorMessage}
        Поле ${nameItemsList[name]}: ${itemMessage}
        `;
    }

    return errorMessage;
};

export const timeRequiredRule = [
    (form) => {
        const time = form.getFieldsValue()?.time;
        const time_interval = form.getFieldsValue()?.time_interval;

        return {
            message: '',
            type: 'boolean',
            validator() {
                if (time.every((time) => !!time) || time_interval.every((time) => !!time)) {
                    return Promise.resolve();
                }
                return Promise.reject();
            },
        };
    },
];

export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
