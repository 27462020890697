import { Button as UIButton } from 'antd';
import styled from 'styled-components';

export const Button = styled(UIButton)`
    :not(hover) {
        border: none;
    }

    ${({ type }) => {
        if (type === 'link') {
            return `
            color: var(--color-primary);
            background: none;
            padding:0;

            :hover {
                color: var(--color-secondary);
                border: none;
                background: none;
            }
            `;
        }

        return `
        border-radius:5px;
        color: var(--color-white);
        background:var(--color-primary);

        &:hover {
            color: var(--color-white);
            border: none;
            background: var(--color-primary);
        }

        &:focus{
            color: var(--color-white);
            border: none;
            background: var(--color-primary);
        }
    
        `;
    }}
`;
