import { useEffect, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';

import styles from './styles.module.scss';

export const TimeAction = ({
    time: _time,
    // requestPageProps, disabled,
    updateRequestComment,
    notShow = false,
    disabled,
}) => {
    const _input = useRef(null);

    const [time, setTime] = useState(_time);
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTime(_time);
    }, [_time]);

    useEffect(() => {
        if (open) {
            _input?.current?.focus();
        }
    }, [open]);

    useEffect(() => {
        if (!open) {
            setValue('');
        }
    }, [open]);

    const onInputChange = (value) => {
        setValue(value);
    };

    // eslint-disable-next-line consistent-return
    const onKeyDownInputTimeStop = (e) => {
        if (e.key === 'Enter') {
            if (/\d\d:\d\d/.test(value)) {
                setValue('');
                setOpen(false);
                updateRequestComment(value);
            }
        } else {
            const key = +e.key;
            const value = e.target.value || '';
            const numbers = value.match(/[_0-9]/g);

            const selectionStart = e.target?.selectionStart;
            const selectionEnd = e.target?.selectionEnd;

            const numberIndex = {
                0: 0,
                1: 1,
                2: 2,
                3: 2,
                4: 3,
            };

            const index = numberIndex?.[e.target?.selectionStart];
            if (!Number.isNaN(key) && typeof index === 'number') {
                if (index === 0) {
                    if (
                        key > 2 ||
                        (!Number.isNaN(+numbers[1]) &&
                            +numbers[1] > 3 &&
                            key > 1 &&
                            !(selectionStart === 0 && selectionEnd >= 2))
                    ) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                }
                if (index === 1) {
                    if (!Number.isNaN(+numbers[0]) && +numbers[0] === 2 && key > 3)
                        e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
                if (index === 2) {
                    if (key > 5) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                }
            }
        }
    };

    const _show = time?.isValid() ? time.format('HH:mm') : '-';
    const show = !notShow ? _show : '-';

    if (!open || disabled)
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                title={show}
            >
                {show}
            </div>
        );

    return (
        <ReactInputMask
            onClick={(e) => {
                e.stopPropagation();
            }}
            mask='99:99'
            alwaysShowMask
            className={styles.ReactInputMaskTime}
            ref={_input}
            onKeyDown={onKeyDownInputTimeStop}
            onChange={(e) => onInputChange(e.target.value)}
            value={value}
            onBlur={() => {
                setOpen(false);
            }}
        />
    );
};
