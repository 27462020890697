import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { CustomAutocomplete } from '@components';
import { fetchRequests } from '@store/actions';
import { authorization, BACKEND_URL } from '@utils';

import styles from './styles.module.scss';

export const Estimation = memo(
    ({ pk, estimation, fetchRequestsProps, itemworker_id, workerId, isEditable, disabled }) => {
        const dispatch = useDispatch();
        const [value, setValue] = useState('');
        const [open, setOpen] = useState(false);

        const options = [
            {
                id: 1,
                text: '1',
                percent: '1',
            },
            {
                id: 2,
                text: '2',
                percent: '50',
            },
            {
                id: 3,
                text: '3',
                percent: '75',
            },
            {
                id: 4,
                text: '4',
                percent: '100',
            },
            {
                id: 5,
                text: '5',
                percent: '105',
            },
        ];

        const [focus, setFocus] = useState(false);

        useEffect(() => {
            setValue('');
            setFocus(false);
        }, [pk]);

        if (!open || disabled)
            return (
                <div
                    onClick={(e) => {
                        if (isEditable && !disabled) {
                            e.stopPropagation();
                            setFocus(true);
                            setOpen(true);
                        }
                    }}
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    title={estimation ?? '-'}
                >
                    {estimation ?? '-'}
                </div>
            );

        const saveWorker = async (id) => {
            const check = false;
            if (check) return;
            if (id === -1 || !id) return;

            const item = options.find((i) => i.id === id);

            const callback = () => {
                dispatch(fetchRequests(fetchRequestsProps));
                setValue('');
                setOpen(false);
            };

            if (estimation) {
                axios({
                    method: 'PUT',
                    url: `${BACKEND_URL}gt/customer/v2/delivery/update_score/${itemworker_id}`,
                    data: {
                        score: item.text,
                        percentage: item.percent,
                    },
                    headers: {
                        Authorization: `Bearer ${authorization.getToken()}`,
                    },
                }).then(() => {
                    callback();
                });
            } else {
                axios({
                    method: 'POST',
                    url: `${BACKEND_URL}gt/customer/v2/delivery/create_score/`,
                    data: { requestworker: workerId, score: item.text, percentage: item.percent },
                    headers: {
                        Authorization: `Bearer ${authorization.getToken()}`,
                    },
                }).then(() => {
                    callback();
                });
            }
        };

        return (
            <CustomAutocomplete
                key={pk}
                onIdChange={(id) => {
                    setFocus(false);
                    saveWorker(id);
                }}
                onBlur={() => {
                    setOpen(false);
                }}
                onChange={(value) => {
                    setValue(value);
                }}
                className={styles.CustomAutocompleteEstimation}
                size='large'
                value={value}
                options={options}
                focus={focus}
                submitOnEnter
                autoFocus
                hasClose={false}
            />
        );
    }
);
