import { useEffect, useMemo, useState } from 'react';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Popup from 'reactjs-popup';
import { Button, Form, message, notification, Popconfirm, Space, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import styled from 'styled-components';
import { v4 as uuid4 } from 'uuid';

import {
    ButtonsContainer,
    CustomAutocomplete,
    FormItem,
    Input,
    PopupInfo,
    Wrapper,
} from '@components';
import { authorization, BACKEND_URL } from '@utils';

import {
    createRequest,
    fetchRequests,
    setLastStateSearch,
    setUpdateRequests,
    updateSlotRequest,
} from '../../store/actions';

import CreateReqModal from './CreateReqModal';
import { CreateServiceModal } from './CreateServiceModal';
import { formatDate, WorkerItemStatus } from './utils';
import { ViewTable } from './ViewTable';

import styles from './styles.module.scss';

import 'moment/locale/ru';

const ShortPopup = styled(Popup)`
    &-content {
        flex: 0 0 0 !important;
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
    &-Title {
        margin-bottom: 0;
    }
`;
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const HomePage = () => {
    const query = useQuery();
    const { pathname } = useLocation();
    const history = useHistory();
    const [mouseDown, setMouseDown] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);

    const [idsWorkersLocal, setIdsWorkersLocal] = useState([]);
    const [serviceModalOpen, setServiceModalOpen] = useState(false);
    const [dtPopupOpen, setDtPopupOpen] = useState(false);
    const [temporarySubmitData, setTemporarySubmitData] = useState();
    const [temporaryServiceSubmitData, setTemporaryServiceSubmitData] = useState();
    const [rowData, setRowData] = useState([]);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);
    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [columns, setColumns] = useState([]);
    const reduxRequests = useSelector((state) => state.requests);
    const [hoveredRow, setHoveredRow] = useState();
    const [form] = useForm();
    const servicesList = useSelector((state) => state.services?.list);
    const lastStateSearch = useSelector((state) => state.services.lastStateSearch);
    const locationsListState = useSelector((state) => state.locations?.list);
    const locationsList = useMemo(
        () =>
            locationsListState.map((i) => ({
                ...i,
                text: i.text + (i.type ? ` - ${i.type}` : ''),
            })),
        [locationsListState]
    );
    const serviceItems = useMemo(() => form.getFieldsValue(['items'])?.items, [form, isNeedUpdate]);
    const curReq = useMemo(() => form.getFieldsValue(['curReq'])?.curReq, [form, isNeedUpdate]);
    const curReqTableData = useMemo(
        () =>
            reduxRequests?.list?.find(({ pk }) => curReq && pk === Number(curReq?.split(' ')?.[0])),
        [curReq, reduxRequests?.list]
    );

    const location = useMemo(
        () => form.getFieldsValue(['location'])?.location,
        [form, isNeedUpdate]
    );
    const createdRequestDate = useMemo(
        () => form.getFieldsValue(['createdRequestDate'])?.createdRequestDate,
        [form, isNeedUpdate]
    );
    const locationId = useMemo(
        () =>
            locationsList?.find((i) => i.text === (location || temporarySubmitData?.location?.text))
                ?.id,
        [locationsList, location, temporarySubmitData?.location?.text]
    );

    const requestPageProps = {
        first_day: lastStateSearch.createdRequestDate,
        last_day: lastStateSearch.createdRequestDate,
        location: locationId,
    };

    const [requestPopup, setRequestPopup] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (query.has('create')) {
            query.delete('create');
            history.push(`${pathname}?${query.toString()}`);
            setRequestPopup(true);
        }
    }, [query]);

    useEffect(() => {
        form.setFieldsValue({
            ...form.getFieldsValue(),
            ...lastStateSearch,
        });
        setIsNeedUpdate((prev) => !prev);
        if (lastStateSearch.createdRequestDate) {
            dispatch(
                fetchRequests({
                    first_day: lastStateSearch.createdRequestDate,
                    last_day: lastStateSearch.createdRequestDate,
                    location: locationId,
                })
            );
        }
        if (lastStateSearch.temporarySubmitData) {
            setTemporarySubmitData(lastStateSearch.temporarySubmitData);
        }
    }, []);

    useEffect(() => {
        if (curReqTableData?.pk && curReqTableData?.status?.text) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                curReq: String(`${curReqTableData.pk} ${curReqTableData.status.text}`),
            });
        }
    }, [curReqTableData?.pk, curReqTableData?.status?.text]);

    useEffect(() => {
        if (locationsList && !location && locationsList?.length === 1) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                location: locationsList[0].text,
            });
            setIsNeedUpdate((prev) => !prev);
        }
    }, [locationsList, location]);

    useEffect(() => {
        if (createdRequestDate && !curReq) {
            dispatch(
                fetchRequests({
                    first_day: createdRequestDate,
                    last_day: createdRequestDate,
                    location: locationId,
                })
            );
        }
    }, [createdRequestDate, locationId]);

    useEffect(() => {
        dispatch(
            setLastStateSearch({
                curReq,
                location: location || temporarySubmitData?.location?.text,
                createdRequestDate: temporarySubmitData?.date1 || createdRequestDate,
            })
        );
    }, [
        curReq,
        location,
        createdRequestDate,
        temporarySubmitData?.date1,
        temporarySubmitData?.location?.text,
    ]);

    useEffect(() => {
        setRowData(
            serviceItems?.map((r) => ({
                workers_required: r?.workers_required?.map((row) => ({
                    ...row,
                    children: row?.slots || [],
                    slots: row?.slots || [],
                })),
            })) || []
        );
    }, [serviceItems]);

    useEffect(() => {
        if (!reduxRequests.isLoading && serviceItems) {
            if (!reduxRequests.error) {
                setInfoPopup({
                    open: true,
                    content: 'Заявка успешно создана',
                    title: 'Результат',
                });

                if (reduxRequests?.list?.[0]) {
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        items: undefined,
                        location: temporarySubmitData?.location?.text,
                        createdRequestDate: temporarySubmitData?.date1,
                        curReq: String(
                            `${reduxRequests?.list?.[0]?.pk} ${reduxRequests?.list?.[0]?.status?.text}`
                        ),
                    });
                    setTemporarySubmitData(undefined);
                    setRowData([]);
                    setIsNeedUpdate((prev) => !prev);
                }
            } else {
                const errors = reduxRequests.error?.items?.[0]?.workers_required;
                const data = form.getFieldsValue(['items']).items?.[0]?.workers_required;

                const content = (
                    <div>
                        {errors?.map((i, idx) => {
                            if (i?.workers_required)
                                return (
                                    <div
                                        style={{
                                            marginBottom: 10,
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 900,
                                                fontSize: 16,
                                            }}
                                        >
                                            {data?.[idx]?.service}
                                        </div>
                                        <div
                                            style={{
                                                fontWeight: 500,
                                                fontSize: 14,
                                                marginLeft: 40,
                                            }}
                                        >
                                            {i?.workers_required?.[0]}
                                        </div>
                                    </div>
                                );
                            if (i?.slots) {
                                return (
                                    <div
                                        style={{
                                            marginBottom: 10,
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 900,
                                                fontSize: 16,
                                            }}
                                        >
                                            {data?.[idx]?.service}
                                        </div>
                                        <div>
                                            {i.slots.map((j, idxx) => {
                                                if (j?.start_time || j?.end_time) {
                                                    return (
                                                        <div>
                                                            <div
                                                                style={{
                                                                    fontWeight: 700,
                                                                    fontSize: 15,
                                                                    marginLeft: 20,
                                                                }}
                                                            >
                                                                Слот {idxx + 1}
                                                            </div>
                                                            {j?.start_time?.[0] && (
                                                                <div
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        fontSize: 14,
                                                                        marginLeft: 40,
                                                                    }}
                                                                >
                                                                    Время начала{' '}
                                                                    {j?.start_time?.[0]}
                                                                </div>
                                                            )}
                                                            {j?.end_time?.[0] && (
                                                                <div
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        fontSize: 14,
                                                                        marginLeft: 40,
                                                                    }}
                                                                >
                                                                    Время окончания{' '}
                                                                    {j?.end_time?.[0]}
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                );

                setInfoPopup({
                    open: true,
                    content: content || 'При создании заявки произошла ошибка',
                    title: 'Результат',
                });
            }
        }
    }, [reduxRequests]);

    useEffect(() => {
        if (temporaryServiceSubmitData?.services) {
            const onCountBlur = (e, rowKey) => {
                if (!Number.isNaN(+e.target.value)) {
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        items: serviceItems?.map((r) => {
                            return {
                                workers_required: r.workers_required.map((row) => {
                                    if (row.key === rowKey) {
                                        if (row.slots?.length > +e.target.value) {
                                            const somethn = row.slots?.slice(+e.target.value);
                                            setIdsWorkersLocal((prev) =>
                                                prev.filter(
                                                    (i) => !somethn.find((j) => j.key === i.key)
                                                )
                                            );

                                            return {
                                                ...row,
                                                slots: row.slots?.slice(0, +e.target.value),
                                                workers_required: +e.target.value,
                                            };
                                        }
                                        const newChildren = [];
                                        for (
                                            let i = row.slots.length + 1;
                                            i <= +e.target.value;
                                            i++
                                        ) {
                                            newChildren.push({
                                                key: uuid4(),
                                                service: i,
                                                parentKey: row.key,
                                            });
                                        }
                                        return {
                                            ...row,
                                            workers_required: +e.target.value,
                                            slots: [...(row.slots || []), ...newChildren],
                                        };
                                    }
                                    return row;
                                }),
                            };
                        }),
                    });
                } else {
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        items: serviceItems?.map((row) =>
                            row.key === rowKey ? { ...row, count: row.children.length } : row
                        ),
                    });
                }
                setIsNeedUpdate((prev) => !prev);
            };

            // eslint-disable-next-line consistent-return
            const onKeyDownInputDateStop = (e) => {
                const key = +e.key;
                const value = e.target.value || '';
                const numbers = value.match(/[_0-9]/g);

                const selectionStart = e.target?.selectionStart;
                const selectionEnd = e.target?.selectionEnd;

                const numberIndex = {
                    0: 0,
                    1: 1,
                    2: 2,
                    3: 2,
                    4: 3,
                };

                const index = numberIndex?.[e.target?.selectionStart];
                if (!Number.isNaN(key) && typeof index === 'number') {
                    if (index === 0) {
                        if (
                            key > 2 ||
                            (!Number.isNaN(+numbers[1]) &&
                                +numbers[1] > 3 &&
                                key > 1 &&
                                !(selectionStart === 0 && selectionEnd >= 2))
                        ) {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }
                    }
                    if (index === 1) {
                        if (!Number.isNaN(+numbers[0]) && +numbers[0] === 2 && key > 3)
                            e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                    if (index === 2) {
                        if (key > 5) {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }
                    }
                }
            };

            const onCountChange = (e, rowKey) => {
                if (!Number.isNaN(+e.target.value)) {
                    const thisItem = serviceItems
                        ?.find((i) => i.workers_required?.find((row) => row.key === rowKey))
                        ?.workers_required?.find((row) => row.key === rowKey);

                    if (thisItem && thisItem?.slots?.length > +e.target.value) return;
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        items: serviceItems?.map((r) => {
                            return {
                                workers_required: r.workers_required.map((row) => {
                                    if (row.key === rowKey) {
                                        if (row.slots?.length > +e.target.value) {
                                            return {
                                                ...row,
                                                slots: row.slots?.slice(0, +e.target.value),
                                                workers_required: +e.target.value,
                                            };
                                        }
                                        const newChildren = [];
                                        for (
                                            let i = row.slots.length + 1;
                                            i <= +e.target.value;
                                            i++
                                        ) {
                                            newChildren.push({
                                                key: uuid4(),
                                                service: i,
                                                parentKey: row.key,
                                            });
                                        }
                                        return {
                                            ...row,
                                            workers_required: +e.target.value,
                                            slots: [...(row.slots || []), ...newChildren],
                                        };
                                    }
                                    return row;
                                }),
                            };
                        }),
                    });
                } else {
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        items: serviceItems?.map((row) =>
                            row.key === rowKey ? { ...row, count: row.children.length } : row
                        ),
                    });
                }
                setIsNeedUpdate((prev) => !prev);
            };
            const onInputChange = (v, mainRow, key) => {
                form.setFieldsValue({
                    ...form.getFieldsValue(),
                    items: serviceItems?.map((r) => ({
                        workers_required: r.workers_required?.map((row) =>
                            row.key === mainRow.parentKey
                                ? {
                                      ...row,
                                      slots: row?.slots?.map((child) => {
                                          return mainRow.key === child.key
                                              ? { ...child, [key]: v }
                                              : child;
                                      }),
                                  }
                                : row
                        ),
                    })),
                });
                setIsNeedUpdate((prev) => !prev);
            };
            setColumns([
                {
                    title: 'Услуги',
                    width: '70px',
                    dataIndex: 'service',
                    key: 'service',
                    render: (a, b) => <span>{b?.service?.text ?? b?.service}</span>,
                },
                {
                    title: 'Кол-во чел',
                    dataIndex: 'workers_required',
                    key: 'workers_required',
                    width: '50px',
                    render: (a, b, rowIndex) => {
                        return !b.parentKey ? (
                            <Form.Item
                                shouldUpdate
                                name={[
                                    'items',
                                    0,
                                    'workers_required',
                                    rowIndex,
                                    'workers_required',
                                ]}
                                required
                                isLabelVertical
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: 'Введите число',
                                        validator: (v, val) => {
                                            if (/^[0-9]+$/.test(val)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Some message here');
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: 50, padding: '1px 10px' }}
                                    onBlur={(v) => onCountBlur(v, b.key)}
                                    onChange={(v) => onCountChange(v, b.key)}
                                    min={0}
                                    max={100}
                                    value={b?.workers_required}
                                />
                            </Form.Item>
                        ) : null;
                    },
                },
                {
                    title: 'Время начала',
                    dataIndex: 'start_time',
                    width: '50px',
                    key: 'start_time',
                    render: (a, b) => {
                        const value = b.start_time;
                        let isError = false;

                        if (value && !value.includes('_')) {
                            let [HH, mm] = value.split(':');
                            if (!moment(HH, 'HH').isValid() || HH === '24') HH = '00';
                            if (!moment(mm, 'mm').isValid()) mm = '00';
                            const result = [HH, mm].join(':');

                            isError = result !== value;
                        }

                        return b?.parentKey ? (
                            <InputMask
                                onBlur={(q) =>
                                    onInputChange(
                                        q.target.value.replaceAll('_', '0'),
                                        b,
                                        'start_time'
                                    )
                                }
                                mask='99:99'
                                alwaysShowMask
                                style={{
                                    border: isError
                                        ? '1px solid var(--color-warning)'
                                        : '1px solid #d9d9d9',
                                    outline: 'none',
                                    borderRadius: '2px',
                                    padding: 4,
                                    textAlign: 'center',
                                    width: 50,
                                }}
                                onKeyDown={onKeyDownInputDateStop}
                                onChange={(e) => onInputChange(e.target.value, b, 'start_time')}
                                value={b?.start_time}
                            />
                        ) : null;
                    },
                },
                {
                    title: 'Время окончания',
                    dataIndex: 'end_time',
                    width: '50px',
                    key: 'end_time',
                    render: (a, b) => {
                        const value = b.end_time;
                        let isError = false;

                        if (value && !value.includes('_')) {
                            let [HH, mm] = value.split(':');
                            if (!moment(HH, 'HH').isValid() || HH === '24') HH = '00';
                            if (!moment(mm, 'mm').isValid()) mm = '00';
                            const result = [HH, mm].join(':');

                            isError = result !== value;
                        }
                        return b?.parentKey ? (
                            <InputMask
                                onBlur={(q) =>
                                    onInputChange(
                                        q.target.value.replaceAll('_', '0'),
                                        b,
                                        'end_time'
                                    )
                                }
                                mask='99:99'
                                alwaysShowMask
                                style={{
                                    border: isError
                                        ? '1px solid var(--color-warning)'
                                        : '1px solid #d9d9d9',
                                    outline: 'none',
                                    borderRadius: '2px',
                                    padding: 4,
                                    textAlign: 'center',
                                    width: 50,
                                    margin: '0 auto',
                                    display: 'flex',
                                }}
                                onKeyDown={onKeyDownInputDateStop}
                                onChange={(e) => onInputChange(e.target.value, b, 'end_time')}
                                value={b.end_time}
                            />
                        ) : (
                            ''
                        );
                    },
                },
                {
                    title: 'Кол-во часов',
                    dataIndex: 'difTime',
                    key: 'difTime',
                    width: '50px',
                    render: (a, row) => {
                        const startMinutes = moment(row.start_time, 'HH:mm:ss').minutes();
                        const startHours = moment(row.start_time, 'HH:mm:ss').hours();
                        const endMinutes = moment(row.end_time, 'HH:mm:ss').minutes();
                        const endHours = moment(row.end_time, 'HH:mm:ss').hours();

                        const startM = startHours * 60 + startMinutes;
                        const endM = endHours * 60 + endMinutes;

                        const f = endM - startM;
                        const s = endM + 1440 - startM;
                        let subtractM = endM - startM > 0 ? f : s;

                        if (subtractM > 390 && subtractM < 540) subtractM -= 30;
                        if (subtractM >= 540 && subtractM < 750) subtractM -= 60;
                        if (subtractM >= 750) subtractM -= 90;

                        const m = subtractM % 60;
                        const h = Math.floor(subtractM / 60);
                        const v = (h + m / 60).toFixed(2);
                        return (
                            <span
                                style={{
                                    display: 'inline-block',
                                    textAlign: 'center',
                                    width: 50,
                                }}
                            >
                                {row.start_time && row.end_time ? v : null}
                            </span>
                        );
                    },
                },
                {
                    title: 'Примечание',
                    dataIndex: 'description',
                    key: 'description',
                    width: '150px',
                    render: (_, row) => {
                        return row?.parentKey ? (
                            <Input
                                onChange={(e) => onInputChange(e.target.value, row, 'description')}
                            />
                        ) : null;
                    },
                },
                {
                    title: 'ТБ',
                    dataIndex: 'personnel_number',
                    key: 'personnel_number',
                    width: '50px',
                    render: (_, row) => {
                        const item = idsWorkersLocal?.find((i) => i.key === row.key);

                        const v =
                            item?.personnel_number ?? item?.id
                                ? `${
                                      item.personnel_number ? item.personnel_number : `ID${item.id}`
                                  }`
                                : null;
                        return (
                            <span
                                style={{
                                    display: 'block',
                                    textAlign: 'center',
                                }}
                            >
                                {row?.parentKey ? v : null}
                            </span>
                        );
                    },
                },
                {
                    title: 'ФИО',
                    width: '150px',
                    dataIndex: 'num',
                    key: 'num',
                    render: (a, row) => {
                        return (
                            <span>
                                {row.parentKey ? (
                                    <Num
                                        worker={row.workerId}
                                        row={row}
                                        serviceId={
                                            serviceItems?.[0]?.workers_required?.find(
                                                (i) => i.key === row.parentKey
                                            )?.service_id
                                        }
                                        parentKey={row.parentKey}
                                        idsWorkers={idsWorkersLocal}
                                        setIdsWorkersLocal={setIdsWorkersLocal}
                                        onInputChange={(id) =>
                                            onInputChange(id ? [id] : id, row, 'workers')
                                        }
                                    />
                                ) : null}
                            </span>
                        );
                    },
                },
                // {
                //     title: 'Время начала, факт',
                //     dataIndex: 'startFact',
                //     width: '50px',
                //     key: 'startFact',
                // },
                // {
                //     title: 'Время окончания, факт',
                //     dataIndex: 'endFact',
                //     width: '50px',
                //     key: 'endFact',
                // },
                // {
                //     title: (
                //         <div
                //             style={{
                //                 minWidth: '80px',
                //             }}
                //         >
                //             <div>Кол-во</div>
                //             <div>часов,</div>
                //             <div>факт</div>
                //         </div>
                //     ),
                //     dataIndex: 'spentFact',
                //     width: '100px',
                //     key: 'spentFact',
                // },
                {
                    title: 'Действия',
                    dataIndex: 'action',
                    width: '5px',
                    key: 'action',
                    render: (_, row) => {
                        return (
                            <Button
                                type='text'
                                onClick={() => {
                                    if (row?.parentKey) {
                                        form.setFieldsValue({
                                            ...form.getFieldsValue(),
                                            items: serviceItems?.map((serviceRow) => ({
                                                workers_required: serviceRow.workers_required?.map(
                                                    (r) =>
                                                        r.key === row.parentKey
                                                            ? {
                                                                  ...r,
                                                                  slots: r?.slots?.reduce(
                                                                      (acc, child) => {
                                                                          if (
                                                                              child.key !== row.key
                                                                          ) {
                                                                              acc.push({
                                                                                  ...child,
                                                                                  service:
                                                                                      acc.length +
                                                                                      1,
                                                                              });
                                                                          }
                                                                          return acc;
                                                                      },
                                                                      []
                                                                  ),
                                                              }
                                                            : r
                                                ),
                                            })),
                                        });
                                        form.setFieldsValue({
                                            ...form.getFieldsValue(),
                                            items: form
                                                .getFieldsValue(['items'])
                                                ?.items?.map((item) => ({
                                                    workers_required: item.workers_required?.map(
                                                        (childRow) =>
                                                            childRow.key === row.parentKey
                                                                ? {
                                                                      ...childRow,
                                                                      workers_required:
                                                                          childRow.slots?.length,
                                                                  }
                                                                : childRow
                                                    ),
                                                })),
                                        });
                                    } else {
                                        form.setFieldsValue({
                                            ...form.getFieldsValue(),
                                            items: serviceItems.map((item) => ({
                                                workers_required: item.workers_required?.filter(
                                                    ({ key }) => key !== row.key
                                                ),
                                            })),
                                        });
                                        dispatch(
                                            setUpdateRequests([
                                                ...servicesList,
                                                {
                                                    id: row.service_id,
                                                    text: row.service,
                                                    selected_text: row.service,
                                                },
                                            ])
                                        );
                                    }
                                    setIsNeedUpdate((prev) => !prev);
                                }}
                                style={{
                                    padding: 0,
                                    margin: '0 auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img alt='icon' src='/delete_icon.svg' />
                            </Button>
                        );
                    },
                },
            ]);
        }
    }, [hoveredRow, form, isNeedUpdate, serviceItems, idsWorkersLocal]);
    useEffect(() => {
        if (temporaryServiceSubmitData?.services) {
            const submitData = cloneDeep(temporaryServiceSubmitData);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                items: [
                    {
                        workers_required: [
                            ...(serviceItems?.[0]?.workers_required || []),
                            ...submitData.services.map(({ text, id }) => ({
                                service: text,
                                service_id: id,
                                workers_required: 0,
                                key: uuid4(),
                                slots: [],
                            })),
                        ],
                    },
                ],
            });
            setIsNeedUpdate((prev) => !prev);
        }
    }, [temporaryServiceSubmitData]);

    const [rowDataTable, setRowDataTable] = useState(undefined);
    useEffect(() => {
        const data = curReqTableData?.items?.[0];
        setRowDataTable(
            data?.workers_required_for_service?.map((row) => ({
                ...row,
                children:
                    row.slots?.map((slot) => {
                        const assigned_worker = data?.assigned_workers?.find(
                            ({ id }) => slot.worker === id
                        );

                        return {
                            ...slot,
                            parentId: row.service_id,
                            worker: assigned_worker?.name,
                            checked: false,
                            status: assigned_worker?.status,
                            hours_worked: assigned_worker?.hours_worked,
                            service_name: row.service_name,
                            workerId: assigned_worker?.id,
                            end: assigned_worker?.end,
                            personnel_number: assigned_worker?.personnel_number,
                            itemworker_id: assigned_worker?.itemworker_id,
                            start: assigned_worker?.start,
                        };
                    }) || [],
                slots: row.slots || [],
                checked: false,
            })) || []
        );
    }, [curReqTableData?.items?.[0]]);

    const actionMassConfirm = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_confirm/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    setSelectedRow([]);
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassStart = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_start/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    setSelectedRow([]);
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassRemove = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_remove/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    setSelectedRow([]);
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassAdd = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_add/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    setSelectedRow([]);
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };

    const menuItems = [
        {
            key: '1',
            text: 'Вернуть на адрес',
            action: actionMassAdd,
            disabled: !selectedRow?.every(
                (item) =>
                    [
                        WorkerItemStatus.Failed,
                        WorkerItemStatus.Defect,
                        WorkerItemStatus.Cancelled,
                    ].includes(item.status) && item.status !== undefined
            ),
        },
        {
            key: '2',
            text: 'Подтвердить',
            action: actionMassConfirm,
            disabled: !selectedRow?.every(
                (item) => [WorkerItemStatus.New].includes(item.status) && item.status !== undefined
            ),
        },
        {
            key: '3',
            text: 'Пришёл',
            action: actionMassStart,
            disabled: !selectedRow?.every(
                (item) =>
                    [
                        WorkerItemStatus.StartPhotoRejected,
                        WorkerItemStatus.RequestConfirmedByWorker,
                        WorkerItemStatus.SuspiciousAddress,
                    ].includes(item.status) && item.status !== undefined
            ),
        },
        {
            key: '4',
            text: 'Снять',
            action: actionMassRemove,
            disabled: !selectedRow?.every(
                (item) =>
                    ![
                        WorkerItemStatus.Failed,
                        WorkerItemStatus.Defect,
                        WorkerItemStatus.Cancelled,
                    ].includes(item.status) && item.status !== undefined
            ),
        },
    ];

    const selectsActions = () => {
        const key = selectedRow?.[0]?.key;
        const ids = selectedRow?.map((i) => i.id);

        const workerIdsByPk = selectedRow?.reduce((acc, i) => {
            const idx = acc.findIndex((j) => j.pk === i.pk);
            const item = acc[idx];

            // eslint-disable-next-line no-bitwise
            if (~idx) {
                return [
                    ...acc.slice(0, idx),
                    {
                        pk: i?.pk,
                        workerIds: [...(item?.workerIds || []), i?.workerId],
                        item_id: i?.item_id,
                    },
                    ...acc.slice(idx + 1),
                ];
            }
            return [
                ...acc,
                {
                    pk: i.pk,
                    workerIds: [i.workerId],
                    item_id: i.item_id,
                },
            ];
        }, []);

        const onClickHandler = (action) => {
            workerIdsByPk.forEach((i, idx) => {
                const dataForStatusAction = {
                    worker_ids: i.workerIds,
                    request_id: i?.pk,
                    item_id: i?.item_id,
                };

                action(dataForStatusAction, idx === workerIdsByPk.length - 1);
            });
        };

        if (!mouseDown) {
            if (key === 'status')
                return (
                    <div
                        className={styles.selectedActions}
                        style={{
                            justifyContent: 'flex-end',
                        }}
                    >
                        {menuItems
                            .filter((i) => !i.disabled)
                            .map((i) => (
                                <Button
                                    key={i.key}
                                    onClick={() => onClickHandler(i.action)}
                                    data-id-type='select'
                                >
                                    {i.text}
                                </Button>
                            ))}
                    </div>
                );
            if (key === 'description')
                return (
                    <div
                        className={styles.selectedActions}
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <CommentAction
                            ids={ids}
                            requestPageProps={{
                                first_day: createdRequestDate,
                                last_day: createdRequestDate,
                                location: locationId,
                            }}
                        />
                    </div>
                );
            if (key === 'hours_worked') {
                const disabled = selectedRow.some(
                    (i) =>
                        ![
                            WorkerItemStatus.FinishPhotoRejected,
                            WorkerItemStatus.StartConfirmed,
                            WorkerItemStatus.Completed,
                        ].includes(i.status)
                );

                return (
                    <div
                        className={styles.selectedActions}
                        style={{
                            justifyContent: 'flex-end',
                        }}
                    >
                        <HoursAction
                            disabled={disabled}
                            workerIdsByPk={workerIdsByPk}
                            requestPageProps={requestPageProps}
                        />
                    </div>
                );
            }
        }
        return null;
    };

    useEffect(() => {
        setIdsWorkers(
            rowDataTable?.reduce((prev, i) => {
                if (i.slots?.length) {
                    return [
                        ...prev,
                        ...i.slots.filter((j) => typeof j.worker === 'number').map((j) => j.worker),
                    ];
                }

                return prev;
            }, [])
        );
    }, [rowDataTable]);

    const [selectedWorkers, setSelectedWorkers] = useState([]);

    useEffect(() => {
        const data = rowDataTable?.reduce((prev, i) => {
            if (i.children?.length) {
                return [
                    ...prev,
                    ...i.children
                        .filter((j) => typeof j.status === 'number' && j.checked)
                        .map((j) => ({
                            status: j.status,
                            worker: i.slots.find((i) => i.id === j.id).worker,
                        })),
                ];
            }

            return prev;
        }, []);
        setSelectedWorkers(data || []);
    }, [rowDataTable]);

    const worker_ids = selectedWorkers.map((i) => i.worker);

    const [idsWorkers, setIdsWorkers] = useState([]);
    const [key, setKey] = useState(undefined);

    useEffect(() => {
        return () => {
            if (key) notification.close(key);
        };
    }, [key, curReqTableData]);

    useEffect(() => {
        const onEsc = (e) => {
            if (e.key === 'Escape') {
                setSelectedRow([]);
            }
        };

        document.addEventListener('keydown', onEsc);
        return () => {
            document.removeEventListener('keydown', onEsc);
        };
    }, []);

    const openNotification = () => {
        const key = `open${Date.now()}`;
        setKey(key);
        const btnClick = () => {
            notification.close(key);
        };
        const btn = (
            <Button type='primary' size='small' onClick={btnClick}>
                Ok
            </Button>
        );

        const args = {
            message: 'Отмена заявки',
            description: `Отмена заявки возможна за 24 часа. Для отмены заявки №${curReqTableData.pk} от ${createdRequestDate} свяжитесь с вашим менеджером.`,
            duration: 0,
            btn,
            key,
            placement: 'top',
        };
        notification.open(args);
    };

    return (
        <Wrapper title='Заявка'>
            <ShortPopup
                modal
                open={dtPopupOpen}
                onClose={() => setDtPopupOpen(false)}
                closeOnDocumentClick
            >
                {(close) => {
                    return (
                        <Form.Item name='date2' valuePropName='date'>
                            <Calendar
                                onChange={(v) => {
                                    form.setFieldsValue({
                                        items: undefined,
                                        curReq: '',
                                        createdRequestDate: formatDate(v),
                                    });
                                    setRowData(undefined);
                                    close();
                                    setIsNeedUpdate((prev) => !prev);
                                }}
                                locale={locales.ru}
                                color='#000000'
                                rangeColors={['#000000']}
                            />
                        </Form.Item>
                    );
                }}
            </ShortPopup>
            <CreateReqModal
                onSubmit={(v) => {
                    setRequestPopup(false);
                    setTemporarySubmitData({ ...v, date1: formatDate(v.date1) });
                    setRowDataTable(undefined);
                    setServiceModalOpen(true);
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        curReq: String(`${curReqTableData.pk} ${curReqTableData.status.text}`),
                    });
                    // код ниже - если придется блочить создание новой зявки, если на указанную дату уже есть заявка
                    // const res = dispatch(
                    //     fetchRequests({
                    //         first_day: formatDate(v.date1),
                    //         last_day: formatDate(v.date1),
                    //     })
                    // );
                    // res.then((r) => {
                    //     if (r.payload?.data?.requests?.length) {
                    //         setInfoPopup({
                    //             open: true,
                    //             title: 'Результат',
                    //             content: 'Заявка на эту дату уже создана',
                    //         });
                    //     } else {
                    //         setRequestPopup(false);
                    //         setTemporarySubmitData({ ...v, date1: formatDate(v.date1) });
                    //     }
                    // });
                }}
                infoPopup={infoPopup}
                setInfoPopup={setInfoPopup}
                isOpen={requestPopup}
                onClose={() => setRequestPopup(false)}
            />
            <Form
                form={form}
                onFinish={() => {
                    dispatch(
                        createRequest(
                            {},
                            {
                                items: form.getFieldsValue(['items']).items,
                                date: temporarySubmitData?.date1,
                                location: Number(temporarySubmitData?.location?.id),
                            }
                        )
                    );
                }}
            >
                <div style={{ marginLeft: '40px' }}>
                    {locationsList.find(({ text }) => text === location)?.address}
                </div>
                <ButtonsContainer
                    classes={styles.buttonsContainer_align}
                    left={
                        <Space size='middle' className={styles.search_panel}>
                            <FormItem className={styles.search_item_form}>
                                <Input
                                    readOnly
                                    size='large'
                                    value={temporarySubmitData?.date1 || createdRequestDate}
                                    placeholder='Дата заявки'
                                    className={styles.search_field_date}
                                    onClick={() => setDtPopupOpen(true)}
                                />
                            </FormItem>
                            {!!locationsList?.length && (
                                <FormItem className={styles.search_item_form}>
                                    <CustomAutocomplete
                                        style={{ width: '200px' }}
                                        placeholder='Торговая точка'
                                        onChange={(location) => {
                                            form.setFieldsValue({
                                                ...form.getFieldsValue(),
                                                location,
                                                curReq: '',
                                            });
                                            setIsNeedUpdate((prev) => !prev);
                                        }}
                                        className={styles.search_field}
                                        size='large'
                                        customValue={
                                            location || temporarySubmitData?.location?.text
                                        }
                                        options={locationsList}
                                    />
                                </FormItem>
                            )}
                            {!temporarySubmitData?.location && location && reduxRequests?.list && (
                                <FormItem name='curReq' className={styles.search_item_form}>
                                    <CustomAutocomplete
                                        style={{ width: '200px' }}
                                        placeholder='Заявка'
                                        onChange={(curReq) => {
                                            form.setFieldsValue({
                                                ...form.getFieldsValue(),
                                                curReq,
                                            });
                                            setRowData([]);
                                            setIsNeedUpdate((prev) => !prev);
                                        }}
                                        className={styles.search_field}
                                        size='large'
                                        options={reduxRequests?.list?.map(({ status, pk }) => ({
                                            text: `${pk} ${status.text}`,
                                            selected_text: `${pk} ${status.text}`,
                                            id: pk,
                                        }))}
                                    />
                                </FormItem>
                            )}
                            {/* {!rowData?.length && curReqTableData?.items && (
                                <Dropdown overlay={menu} placement='bottom' trigger={['click']}>
                                    <Button className={styles.button}>
                                        Поменять статус выбраным
                                    </Button>
                                </Dropdown>
                            )} */}
                        </Space>
                    }
                    right={
                        <Space>
                            <Button
                                type='default'
                                onClick={() => setRequestPopup(true)}
                                disabled={!!temporarySubmitData}
                                className={styles.button}
                            >
                                Создать заявку
                            </Button>
                            {!rowData?.length && curReqTableData?.items && (
                                <Button
                                    type='primary'
                                    onClick={openNotification}
                                    disabled={!!temporarySubmitData}
                                    className={styles.button}
                                    style={{
                                        backgroundColor: '#F44336',
                                        borderColor: '#F44336',
                                        color: '#fff',
                                    }}
                                >
                                    Отменить заявку
                                </Button>
                            )}
                            <Button
                                type='primary'
                                onClick={() => {
                                    dispatch(
                                        fetchRequests({
                                            first_day: createdRequestDate,
                                            last_day: createdRequestDate,
                                            location: locationId,
                                        })
                                    );
                                }}
                                className={styles.button}
                                disabled={!createdRequestDate}
                            >
                                <img
                                    alt='icon'
                                    src='https://cdn.icon-icons.com/icons2/510/PNG/32/refresh_icon-icons.com_50052.png'
                                />
                            </Button>
                        </Space>
                    }
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            height: '50px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {selectsActions()}
                    </div>
                </div>

                {!rowData?.length && curReqTableData?.items && (
                    <ViewTable
                        rowData={rowDataTable}
                        setRowData={setRowDataTable}
                        pk={curReqTableData?.pk}
                        item_id={curReqTableData?.items?.[0]?.id}
                        idsWorkers={idsWorkers}
                        setIdsWorkers={setIdsWorkers}
                        createdRequestDate={createdRequestDate}
                        worker_ids={worker_ids}
                        location={locationId}
                        mouseDown={mouseDown}
                        setMouseDown={setMouseDown}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        requestPopup={requestPopup}
                    />
                )}
                {!!rowData?.length && (
                    <Table
                        dataSource={rowData?.[0]?.workers_required}
                        columns={columns}
                        style={{ padding: '0 40px' }}
                        pagination={false}
                        expandable={{
                            rowExpandable: () => false,
                            defaultExpandAllRows: true,
                            expandIcon: () => null,
                            expandedRowKeys: rowData?.[0]?.workers_required?.map(({ key }) => key),
                        }}
                        onRow={({ key }) => ({
                            onMouseEnter: () => {
                                setHoveredRow(key);
                            },
                        })}
                        className={styles.table}
                    />
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: rowData?.length ? 'space-between' : 'center',
                        marginTop: 20,
                        padding: '0 40px',
                    }}
                >
                    {temporarySubmitData?.location && (
                        <Button
                            size='large'
                            type='text'
                            onClick={() => {
                                setServiceModalOpen(true);
                            }}
                            disabled={!servicesList?.length}
                        >
                            <img alt='icon' src='/add_icon.svg' />
                            &nbsp; {`${rowData?.length ? 'Добавить ' : 'Создать '}`}услуги
                        </Button>
                    )}
                    {!!rowData?.length && (
                        <Popconfirm
                            title='Вы уверены, что хотите отправить заявку? После отправки, редактирование будет невозможно!'
                            placement='topRight'
                            description=''
                            onConfirm={() => {
                                form.submit();
                            }}
                            onCancel={() => {}}
                            okText='Да'
                            cancelText='Нет'
                        >
                            <Button size='large' type='primary'>
                                Отправить заявку
                            </Button>
                        </Popconfirm>
                    )}
                </div>
                <CreateServiceModal
                    onSubmit={setTemporaryServiceSubmitData}
                    isOpen={serviceModalOpen}
                    setIsOpen={setServiceModalOpen}
                />
            </Form>
            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup?.open}
                onClose={() => {
                    setInfoPopup({ ...infoPopup, open: false });
                }}
            >
                {(close) => (
                    <PopupInfo
                        title={infoPopup?.title}
                        classNamePopupTitle={styles.PopupTitle}
                        classNamePopupContent={styles.PopupContent}
                        classNameButtons={styles.Buttons}
                        close={close}
                    >
                        {infoPopup?.content}
                    </PopupInfo>
                )}
            </ShortPopup>
        </Wrapper>
    );
};

const CommentAction = ({ ids, requestPageProps }) => {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const updateRequestComment = (value) => {
        ids.forEach((id, idx) => {
            dispatch(
                updateSlotRequest(id, () => {
                    if (idx === ids.length - 1) {
                        dispatch(fetchRequests(requestPageProps));
                        setValue('');
                    }
                })(
                    {},
                    {
                        description: value,
                    }
                )
            );
        });
    };

    return (
        <Input
            value={value}
            onChange={(e) => setValue(e.target?.value)}
            onKeyDown={({ key }) => key === 'Enter' && updateRequestComment(value)}
            placeholder='Введите комментрий...'
            data-id-type='select'
            style={{
                width: '100%',
            }}
            autoFocus
        />
    );
};

const HoursAction = ({ workerIdsByPk, requestPageProps, disabled }) => {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const updateRequestComment = (value) => {
        workerIdsByPk.forEach((item, idx) => {
            const data = {
                worker_ids: item.workerIds,
                request_id: item.pk,
                item_id: item.item_id,
                hours: value,
            };
            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            }).then(() => {
                if (idx === workerIdsByPk.length - 1) {
                    dispatch(fetchRequests(requestPageProps));
                    setValue('');
                }
            });
        });
    };

    return (
        <Input
            value={value}
            onChange={(e) => setValue(e.target?.value)}
            onKeyDown={({ key }) => key === 'Enter' && updateRequestComment(value)}
            placeholder='Введите кол-во часов...'
            data-id-type='select'
            disabled={disabled}
            className={styles.hoursInput}
            style={{
                width: '180px',
            }}
            autoFocus
        />
    );
};

const onFetchError = (error) => {
    let err = '';

    if (error?.response?.data?.messages) {
        for (const key in error?.response?.data?.messages) {
            const element = error?.response?.data?.messages[key];
            if (element.length) {
                err += ' ';
                if (Array.isArray(element) && element) {
                    err += `${key}: `;
                    err += element.join(' ');
                } else {
                    err += element;
                }
            }
        }
    }

    showError(err || error?.response?.data?.detail || error.message);
};

const showError = (error) => {
    if (error) {
        message.error({ content: error });
    }
};

const Num = ({
    worker,
    idsWorkers,
    onInputChange,
    row,
    serviceId,
    setIdsWorkersLocal,
    parentKey,
}) => {
    const [id, setId] = useState('');
    const [value, setValue] = useState('');
    const [page, setPage] = useState(1);

    const [workersOptions, setWorkersOptions] = useState({
        load: true,
        err: false,
        data: undefined,
    });

    const updateRouteOptions = async (value, page) => {
        setWorkersOptions((prev) => ({
            ...prev,
            load: true,
        }));

        const params = {
            q: value,
            forward: {},
            page,
        };

        const { data } = await axios({
            method: 'get',
            url: `${BACKEND_URL}bo/delivery/worker_autocomplete/`,
            params,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        });

        setWorkersOptions((prev) => ({
            ...prev,
            load: false,
            data: data.results.map((r) => ({
                personnel_number: r.personnel_number,
                text: `${r.personnel_number ? r.personnel_number : `ID${r.id}`} ${r.text}`,
                id: +r.id,
            })),
        }));
    };

    useEffect(() => {
        updateRouteOptions(value, page);
    }, [value, page]);

    const options = useMemo(() => {
        if (workersOptions.data) {
            return workersOptions.data.filter(
                (i) => !idsWorkers?.find((j) => i.id === j.id) ?? true
            );
        }
        return workersOptions.data;
    }, [workersOptions.data, idsWorkers]);

    const onScroll = () => {
        setPage(1);
    };

    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setValue('');
        setFocus(false);
    }, []);

    if (worker)
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    setFocus(true);
                }}
                className={styles.worker}
            >
                {worker ?? '-'}
            </div>
        );

    const saveWorker = async (value, option) => {
        if (value === -1 || !value) {
            setIdsWorkersLocal((prev) =>
                prev.filter((i) => i.id !== id && i.serviceId !== serviceId)
            );
        } else {
            setIdsWorkersLocal((prev) => [
                ...prev,
                {
                    id: value,
                    personnel_number: option?.personnel_number,
                    serviceId,
                    parentKey,
                    key: row.key,
                },
            ]);
        }

        onInputChange(value === -1 || !value ? undefined : value);
    };

    return (
        <CustomAutocomplete
            key={row.key}
            style={{ width: '200px' }}
            onIdChange={(id, option) => {
                setFocus(false);
                saveWorker(id, option);
                setId(id);
            }}
            onChange={(value) => {
                setValue(value);
            }}
            onScroll={onScroll}
            className={styles.search_field_worker}
            size='large'
            value={value}
            options={options}
            focus={focus}
            submitOnEnter
        />
    );
};
