import { forwardRef, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import Popup from 'reactjs-popup';
import { Checkbox, Col, Form, Row } from 'antd';
import styled from 'styled-components';

import { CustomAutocomplete } from '@components/CustomAutocomplete';
import { FormItem as UIFormItem } from '@components/FormItem';
import { Input } from '@components/Input';
import { NumberInput } from '@components/NumberInput';
import { PopupInfo } from '@components/PopupInfo';
import { TimePicker } from '@components/TimePicker';
import { DADATA_API_KEY } from '@utils';

import { AutoFillItem } from '../AutoFillItem';
import { DateViewer } from '../DateViewer';
import { FormItemDependentOnRoute } from '../FormItemDependentOnRoute';
import { Label } from '../Label';
import { TimePopup } from '../TimePopup';
import { nameItemsList } from '../utils';

import { Controls, serviceRequiredRule } from './Controls';

import 'react-dadata/dist/react-dadata.css';
import styles from '../styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        flex: 0 0 0 !important;
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;
const FormItem = forwardRef((props, ref) => {
    const { children, label } = props;
    return (
        <UIFormItem
            {...props}
            ref={ref}
            label={label ? <Label>{label}</Label> : label}
            marginBottom={0}
            notInfoMessage
            colon={false}
            isLabelVertical
        >
            {children}
        </UIFormItem>
    );
});
const requiredRule = [{ required: true, message: '' }];

const locationRule = [
    (form) => {
        const location = form.getFieldsValue()?.location;
        const services = form.getFieldsValue()?.services;
        return {
            required: true,
            message: '',
            type: 'boolean',
            validator() {
                if (!location) return Promise.reject();
                if (!services?.length) return Promise.reject();
                if (
                    !!Array.isArray(services) &&
                    !!services?.length &&
                    services?.every((i) => !!i?.workers && !!i?.service)
                ) {
                    return Promise.resolve();
                }
                return Promise.reject();
            },
        };
    },
];

const timeRequiredRule = [
    (form) => {
        const time = form.getFieldsValue()?.time;
        const time_interval = form.getFieldsValue()?.time_interval;

        return {
            message: '',
            type: 'boolean',
            validator() {
                if (time.every((time) => !!time) || time_interval.every((time) => !!time)) {
                    return Promise.resolve();
                }
                return Promise.reject();
            },
        };
    },
];
const transformToPossibleDisabledItem =
    (WrappedComponent, name, disableList, disablePropName = 'disable') =>
    (props) => {
        const isDisabled = disableList ? disableList.includes(name) : false;

        return <WrappedComponent {...props} {...{ [disablePropName]: isDisabled }} />;
    };
export const NewRequestMobilePopup = ({
    mainForm: form,
    routeId,
    submitForm,
    initialState,
    datePopupOpen,
    setDatePopupOpen,
    timePopupOpen,
    setTimePopupOpen,
    oneTimeInput,
    setOneTimeInput,
    disableList,
    routeOptions,
    setRouteId,
    route,
    locationsList,
    setLocationId,
    locationId,
    servicesList,
    infoPopup,
    setInfoPopup,
    close,
}) => {
    // const [form] = useForm();
    // useEffect(() => {
    //     form.setFieldsValue(form.getFieldsValue());
    // }, [form.getFieldsValue()]);

    const PossibleDisableCustomAutocomplete = transformToPossibleDisabledItem(
        CustomAutocomplete,
        'route',
        disableList,
        'disabled'
    );

    const LocationDisableCustomAutocomplete = transformToPossibleDisabledItem(
        CustomAutocomplete,
        'location',
        'disabled'
    );

    const [step, setStep] = useState(1);

    const renderRow = () => {
        return (
            <Form.List name='services'>
                {(fields, { add, remove }) => (
                    <>
                        <div className={styles.services}>
                            {fields?.map((field) => (
                                <Row gutter={16} key={field.key}>
                                    <Col span={12}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) =>
                                                prevValues.services !== curValues.services
                                            }
                                            rules={serviceRequiredRule}
                                        >
                                            {() => (
                                                <FormItem
                                                    label={nameItemsList.service}
                                                    name={[field.name, 'service']}
                                                    required
                                                    rules={[
                                                        {
                                                            required: true,
                                                            type: 'string',
                                                            message: '2',
                                                        },
                                                        (form) => {
                                                            const service =
                                                                form.getFieldsValue()?.services?.[
                                                                    field.name
                                                                ];
                                                            return {
                                                                message: '1',
                                                                type: 'string',
                                                                validator() {
                                                                    if (!service?.service) {
                                                                        return Promise.reject();
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            };
                                                        },
                                                    ]}
                                                >
                                                    <CustomAutocomplete
                                                        disabled={!form.getFieldsValue().location}
                                                        options={servicesList}
                                                    />
                                                </FormItem>
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col span={10}>
                                        <FormItem
                                            label={nameItemsList.workers}
                                            name={[field.name, 'workers']}
                                            rules={requiredRule}
                                        >
                                            <NumberInput
                                                disabled={!form.getFieldsValue().location}
                                                className={styles.form_input}
                                            />
                                        </FormItem>
                                    </Col>

                                    <Col span={2}>
                                        <div
                                            className={styles.buttonsContainerIconDelete}
                                            onClick={() => remove(field.name)}
                                        >
                                            <img
                                                alt='icon'
                                                src='/delete_icon.svg'
                                                className={styles.buttonsContainerIcon1}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </div>

                        <div
                            onClick={() => {
                                if (locationId === -1) return;

                                add();
                            }}
                            className={styles.buttonsContainerItem}
                        >
                            <img
                                alt='icon'
                                src='/add_icon.svg'
                                className={styles.buttonsContainerIcon1}
                            />
                            <div>Добавить услугу</div>
                        </div>
                    </>
                )}
            </Form.List>
        );
    };

    const s1 = ['date', 'time', 'time_interval', 'index', 'route'];
    const s2 = ['volume', 'mass', 'places', 'workers_required', 'location', 'services'];
    const s3 = [
        'shipment_type',
        'address',
        'max_size',
        'floor',
        'carrying_distance',
        'has_elevator',
        'driver',
        'phones',
    ];

    const getShame = (step) => {
        switch (step) {
            case 1:
                return s1;
            case 2:
                return s2;
            case 3:
                return s3;

            default:
                return s3;
        }
    };

    const nextStep = () => {
        form.validateFields(getShame(step)).then(() => setStep((p) => (p >= 3 ? p : p + 1)));
    };

    const prevStep = () => {
        setStep((p) => (p <= 1 ? p : p - 1));
    };

    return (
        <>
            <Form
                labelWrap
                layout='horizontal'
                form={form}
                onFinish={submitForm}
                initialValues={initialState}
            >
                <div className={styles.formBody}>
                    <div
                        style={
                            step === 1
                                ? {}
                                : {
                                      overflow: 'hidden',
                                      height: 0,
                                  }
                        }
                    >
                        <AutoFillItem form={form} routeId={routeId} />
                        <ShortPopup
                            open={datePopupOpen}
                            onClose={() => setDatePopupOpen(false)}
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (
                                <FormItem
                                    noStyle
                                    name='date'
                                    valuePropName='date'
                                    rules={requiredRule}
                                >
                                    <Calendar
                                        onChange={() => {
                                            close();
                                        }}
                                        locale={locales.ru}
                                        color='#000000'
                                        rangeColors={['#000000']}
                                    />
                                </FormItem>
                            )}
                        </ShortPopup>

                        <ShortPopup
                            open={timePopupOpen}
                            onClose={() => setTimePopupOpen(false)}
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (
                                <Form.Item
                                    noStyle
                                    name={oneTimeInput ? 'time' : 'time_interval'}
                                    rules={timeRequiredRule}
                                >
                                    <TimePicker
                                        close={close}
                                        onlyOneValue={oneTimeInput}
                                        onChange={() => {
                                            if (oneTimeInput) {
                                                form.setFields([
                                                    {
                                                        name: 'time_interval',
                                                        value: [null, null],
                                                    },
                                                ]);
                                            } else {
                                                form.setFields([
                                                    { name: 'time', value: [null, null] },
                                                ]);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            )}
                        </ShortPopup>

                        <Form.Item noStyle>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <FormItem
                                        rules={requiredRule}
                                        label={nameItemsList.date}
                                        name='date'
                                        valuePropName='date'
                                    >
                                        <DateViewer
                                            setPopupOpen={setDatePopupOpen}
                                            routeId={routeId}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem
                                        label={nameItemsList.index}
                                        rules={requiredRule}
                                        name='index'
                                        notInfoMessage
                                    >
                                        <Input className={styles.form_input} />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item noStyle>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <FormItem
                                        label='Время'
                                        name='time'
                                        required
                                        rules={timeRequiredRule}
                                    >
                                        <TimePopup
                                            setPopupOpen={(v) => {
                                                setOneTimeInput(true);
                                                setTimePopupOpen(v);
                                            }}
                                            onlyOneValue
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem
                                        label='Интервал'
                                        name='time_interval'
                                        required
                                        rules={timeRequiredRule}
                                    >
                                        <TimePopup
                                            setPopupOpen={(v) => {
                                                setOneTimeInput(false);
                                                setTimePopupOpen(v);
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form.Item>

                        <FormItem label={nameItemsList.route} name='route'>
                            <PossibleDisableCustomAutocomplete
                                options={routeOptions}
                                onIdChange={setRouteId}
                                defaultId={route?.routeId || -1}
                            />
                        </FormItem>
                    </div>

                    <div
                        style={
                            step === 2
                                ? {}
                                : {
                                      overflow: 'hidden',
                                      height: 0,
                                  }
                        }
                    >
                        <Form.Item noStyle>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <FormItem
                                        label={nameItemsList.volume}
                                        name='volume'
                                        rules={requiredRule}
                                    >
                                        <NumberInput className={styles.form_input} />
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem
                                        label={nameItemsList.mass}
                                        name='mass'
                                        rules={requiredRule}
                                    >
                                        <NumberInput className={styles.form_input} />
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem
                                        label={nameItemsList.places}
                                        name='places'
                                        rules={requiredRule}
                                    >
                                        <NumberInput className={styles.form_input} />
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem
                                        label={nameItemsList.workers_required}
                                        name='workers_required'
                                        rules={requiredRule}
                                    >
                                        <NumberInput className={styles.form_input} />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item noStyle>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <FormItem
                                        label={nameItemsList.location}
                                        rules={locationRule}
                                        name='location'
                                    >
                                        <LocationDisableCustomAutocomplete
                                            options={locationsList}
                                            onIdChange={setLocationId}
                                            defaultId={locationId ?? -1}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            {renderRow()}
                        </Form.Item>
                    </div>

                    <div
                        style={
                            step === 3
                                ? {}
                                : {
                                      overflow: 'hidden',
                                      height: 0,
                                  }
                        }
                    >
                        <FormItem
                            label={nameItemsList.shipment_type}
                            name='shipment_type'
                            rules={requiredRule}
                        >
                            <Input className={styles.form_input} />
                        </FormItem>

                        <FormItem label={nameItemsList.address} name='address' rules={requiredRule}>
                            <AddressSuggestions
                                defaultQuery={route?.address}
                                token={DADATA_API_KEY}
                                containerClassName={styles.addressSuggestions}
                                customInput={Input}
                                inputProps={{
                                    className: styles.form_input,
                                }}
                            />
                        </FormItem>

                        <Form.Item noStyle>
                            <Row gutter={16}>
                                <Col span={7}>
                                    <FormItem label={nameItemsList.max_size} name='max_size'>
                                        <Input className={styles.form_input} />
                                    </FormItem>
                                </Col>

                                <Col span={7}>
                                    <FormItem label={nameItemsList.floor} name='floor'>
                                        <Input className={styles.form_input} />
                                    </FormItem>
                                </Col>

                                <Col span={7}>
                                    <FormItem
                                        label={nameItemsList.carrying_distance}
                                        name='carrying_distance'
                                    >
                                        <Input className={styles.form_input} />
                                    </FormItem>
                                </Col>

                                <Col span={3}>
                                    <FormItem
                                        label={nameItemsList.has_elevator}
                                        name='has_elevator'
                                        valuePropName='checked'
                                    >
                                        <Checkbox
                                            className={styles.form_checkbox}
                                            type='checkbox'
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form.Item>

                        <FormItemDependentOnRoute
                            label={nameItemsList.driver}
                            name='driver'
                            routeId={routeId}
                        />

                        <FormItemDependentOnRoute
                            label={nameItemsList.phones}
                            name='phones'
                            routeId={routeId}
                        />
                    </div>
                </div>
                <Controls step={step} nextStep={nextStep} prevStep={prevStep} />
            </Form>

            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup.open}
                onClose={() => {
                    if (infoPopup.title === 'Результат') {
                        close();
                    } else {
                        setInfoPopup({ ...infoPopup, open: false });
                    }
                }}
            >
                {(close) => (
                    <PopupInfo title={infoPopup.title} close={close}>
                        {infoPopup.content}
                    </PopupInfo>
                )}
            </ShortPopup>
        </>
    );
};
