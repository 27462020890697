import { FormItem } from '@components/FormItem';
import { Input } from '@components/Input';
import { Label } from '@components/Label';

import styles from './styles.module.scss';

export const FormItemDependentOnRoute = ({ routeId, label, name, ...props }) => {
    const changeValueDependentOnRoute = (value) => {
        if (routeId === -1) {
            return { value };
        }

        return { value: '' };
    };

    return (
        <FormItem
            colon={false}
            isLabelVertical
            marginBottom={0}
            label={<Label>{label}</Label>}
            name={name}
            getValueProps={changeValueDependentOnRoute}
        >
            <Input
                disabled={routeId !== -1}
                className={`${routeId !== -1 ? styles.disabled : ''} ${styles.form_input}`}
                {...props}
            />
        </FormItem>
    );
};
