import { Header } from '@components/Header';

import styles from './styles.module.scss';

export const Wrapper = ({ title, children }) => {
    return (
        <div className={styles.wrapper}>
            <Header title={title} />
            {children}
        </div>
    );
};
