import { memo, useCallback, useMemo } from 'react';
import { AddressSuggestions } from 'react-dadata';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Dropdown, Menu, message } from 'antd';
import axios from 'axios';
import moment from 'moment';

import { Editable } from '@components';
import { fetchRequests, setLastStateSearch, updateSlotRequest } from '@store/actions';
import {
    authorization,
    BACKEND_URL,
    DADATA_API_KEY,
    REQUESTS2_PREFIX,
    storageService,
} from '@utils';

import { ButtonApprove } from './ButtonApprove';
import { elevatorEditor } from './editors';
import { Estimation } from './Estimation';
import { Num } from './Num';
import { TimeAction } from './TimeAction';
import {
    assetsRights,
    currencyFormat,
    filterMap,
    getHoursWithDinner,
    getMinutesWithDinner,
    statuses,
    WorkerItemStatus,
} from './utils';

import styles from './styles.module.scss';

const localStorage2Service = storageService(REQUESTS2_PREFIX);

export const MainCellPart = memo(
    ({
        elem,
        c,
        updateData,
        hasSelectedRow,
        setOpenD,
        rowDataTable,
        sorting,
        elemsData,
        setSelectedApprove,
        selectedApprove,
    }) => {
        const requestPageProps = {
            payment_status: filterMap(localStorage2Service.get('filter')),
            // status: localStorage2Service.get('status'),
            customer_resolution: localStorage2Service.get('customer_resolution'),
            location: localStorage2Service.get('branch'),
            first_day:
                localStorage2Service.get('startRanges') &&
                moment(localStorage2Service.get('startRanges')).format('DD.MM.YYYY'),
            last_day:
                localStorage2Service.get('startRanges') &&
                moment(localStorage2Service.get('endRanges')).format('DD.MM.YYYY'),
        };

        const dispatch = useDispatch();
        const accountType = useSelector((state) => state.user.info.account_type);
        const history = useHistory();
        const isEditable = false;

        const actionMassConfirm = (data, withSuccess) => {
            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_confirm/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            })
                .then(() => {
                    if (withSuccess) dispatch(fetchRequests(requestPageProps));
                })
                .catch((e) => {
                    onFetchError(e);
                });
        };
        const actionMassStart = (data, withSuccess) => {
            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_start/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            })
                .then(() => {
                    if (withSuccess) dispatch(fetchRequests(requestPageProps));
                })
                .catch((e) => {
                    onFetchError(e);
                });
        };

        const actionDelete = (data, withSuccess) => {
            axios({
                method: 'POST',
                url: `${BACKEND_URL}bo/delivery/request/item/worker/delete/`,
                data: {
                    request: data.request_id,
                    item: data.item_id,
                    worker: data.worker_ids[0],
                },
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            })
                .then(() => {
                    if (withSuccess) dispatch(fetchRequests(requestPageProps));
                })
                .catch((e) => {
                    onFetchError(e);
                });
        };

        const actionMassRemove = (data, withSuccess) => {
            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_remove/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            })
                .then(() => {
                    if (withSuccess) dispatch(fetchRequests(requestPageProps));
                })
                .catch((e) => {
                    onFetchError(e);
                });
        };
        const actionMassAdd = (data, withSuccess) => {
            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_add/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            })
                .then(() => {
                    if (withSuccess) dispatch(fetchRequests(requestPageProps));
                })
                .catch((e) => {
                    onFetchError(e);
                });
        };

        const menuItems = [
            {
                key: '1',
                text: 'Вернуть на адрес',
                action: actionMassAdd,
                disabled:
                    ![
                        WorkerItemStatus.Failed,
                        WorkerItemStatus.Defect,
                        WorkerItemStatus.Cancelled,
                    ].includes(elem.statusItem) && elem.statusItem !== undefined,
            },
            {
                key: '2',
                text: 'Подтвердить',
                action: actionMassConfirm,
                disabled:
                    ![WorkerItemStatus.New].includes(elem.statusItem) &&
                    elem.statusItem !== undefined,
            },
            {
                key: '3',
                text: 'Пришёл',
                action: actionMassStart,
                disabled:
                    ![
                        WorkerItemStatus.StartPhotoRejected,
                        WorkerItemStatus.RequestConfirmedByWorker,
                        WorkerItemStatus.SuspiciousAddress,
                    ].includes(elem.statusItem) && elem.statusItem !== undefined,
            },
            {
                key: '4',
                text: 'Снять',
                action: actionMassRemove,
                disabled:
                    [
                        WorkerItemStatus.Failed,
                        WorkerItemStatus.Defect,
                        WorkerItemStatus.Cancelled,
                    ].includes(elem.statusItem) && elem.statusItem !== undefined,
            },
            {
                key: '5',
                text: 'Удалить',
                action: actionDelete,
                disabled:
                    [WorkerItemStatus.Completed].includes(elem.statusItem) &&
                    elem.statusItem !== undefined,
            },
        ];

        const onClickHandler = (action) => {
            const dataForStatusAction = {
                worker_ids: [elem.workerId],
                request_id: elem?.pk,
                item_id: elem?.item_id,
            };

            action(dataForStatusAction, true);
        };

        const menu = (
            <Menu>
                {menuItems.map((i) => (
                    <Menu.Item
                        key={`${i.key} ${i.disabled}`}
                        disabled={i.disabled}
                        onClick={() => {
                            onClickHandler(i.action);
                        }}
                    >
                        {i.text}
                    </Menu.Item>
                ))}
            </Menu>
        );

        if (c === 'pk') {
            const onClick = () => {
                dispatch(
                    setLastStateSearch({
                        curReq: `${elem.pk} ${elem.status.text}`,
                        location: elem.location.name,
                        createdRequestDate: moment(elem.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    })
                );

                history.push('/home');
            };

            return (
                <div key={elem.pk} onClick={onClick} className={styles.linkStyle}>
                    {elem.pk}
                </div>
            );
        }

        if (c === 'expected_hours_max') {
            return <ExpectedHoursMax elem={elem} />;
        }
        if (c === 'expected_total_hours') {
            return <ExpectedTotalHours elem={elem} />;
        }

        if (c === 'workers_required_for_service') {
            return (
                <Editable
                    field='workers_required_for_service'
                    pk={elem.pk}
                    item_pk={elem?.addresses?.[0]?.id}
                    text={elem?.service_name}
                    editable={isEditable}
                    isEditable={isEditable}
                    notOpenedClassName={styles.leftText}
                />
            );
        }
        if (c === 'addresses') {
            return (
                <Editable
                    inputComponent={(value, setValue, handleSubmit) => (
                        <AddressSuggestions
                            inputProps={{ autoFocus: true }}
                            defaultQuery={elem.addresses?.[0]?.text}
                            token={DADATA_API_KEY}
                            value={value}
                            onChange={(addr) => {
                                setValue(addr.value);
                                handleSubmit(addr.value);
                            }}
                            containerClassName={styles.addressSuggestions}
                        />
                    )}
                    field='address'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.addresses?.[0]?.text}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'mass') {
            return (
                <Editable
                    field='mass'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.mass ? elem.mass?.toString()?.replace(/\./gi, ',') : null}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'volume') {
            return (
                <Editable
                    field='volume'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.volume ? elem.volume?.toString()?.replace(/\./gi, ',') : null}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'has_elevator') {
            return elevatorEditor({
                elem,
                c,
                updateData,
                itemPk: elem.addresses?.[0]?.id,
                index: 0,
            });
        }
        if (c === 'floor') {
            return (
                <Editable
                    field='floor'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'max_size') {
            return (
                <Editable
                    field='max_size'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'carrying_distance') {
            return (
                <Editable
                    field='carrying_distance'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'time') {
            return (
                <Editable
                    field='time'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={
                        elem.addresses?.[0]?.interval?.includes('-')
                            ? '-'
                            : elem.addresses?.[0]?.interval
                    }
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'interval') {
            return (
                <Editable
                    field='time_interval'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={
                        elem.addresses?.[0]?.interval?.includes('-')
                            ? elem.addresses?.[0]?.interval
                            : '-'
                    }
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'index') {
            return (
                <>
                    <Editable
                        field='code'
                        pk={elem.pk}
                        item_pk={elem.addresses?.[0]?.id}
                        text={elem[c]}
                        editable={isEditable}
                        isEditable={isEditable}
                    />
                    <div
                        style={{
                            width: 19,
                            minWidth: 19,
                            marginLeft: 10,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            alt={elem.customer_resolution}
                            src={`/customer_resolution_${elem.customer_resolution}.svg`}
                        />
                    </div>
                </>
            );
        }
        if (c === 'places') {
            return (
                <Editable
                    field='place_count'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'shipment_type') {
            return (
                <Editable
                    field='shipment_type'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.addresses?.[0]?.shipment_type}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'date') {
            return (
                <Editable
                    field='date'
                    pk={elem.pk}
                    item_pk={null}
                    text={moment(elem[c]).format('DD.MM.YYYY')}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }

        if (c === 'createDate') {
            return elem.timestamp ? moment(elem.timestamp).format('DD.MM.YYYY HH:mm') : null;
        }

        if (c === 'contact') {
            return (
                <Editable
                    field='driver_name'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem[c]}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }
        if (c === 'phone') {
            return (
                <Editable
                    field='driver_phones'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem[c]?.join(' ')}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }

        if (c === 'commentTT') {
            const rights = assetsRights?.[accountType];

            return (
                <CommentTT
                    disabled={rights && !rights?.includes(c)}
                    requestPageProps={requestPageProps}
                    elem={elem}
                />
            );
        }

        if (c === 'commentKA') {
            const rights = assetsRights?.[accountType];

            return (
                <CommentKA
                    disabled={rights && !rights?.includes(c)}
                    elem={elem}
                    requestPageProps={requestPageProps}
                />
            );
        }

        if (c === 'comment') {
            const submitFunction = async (value) => {
                dispatch(
                    updateSlotRequest(elem.id, () => {
                        dispatch(fetchRequests(requestPageProps));
                    })(
                        {},
                        {
                            description: value,
                        }
                    )
                );
            };
            const rights = assetsRights?.[accountType];
            if (rights && !rights?.includes(c))
                return (
                    <div>
                        <Editable
                            submitFunction={submitFunction}
                            classNameInput='commentInput'
                            field='comment'
                            pk={elem.pk}
                            item_pk={null}
                            text={elem[c]}
                            editable
                            // isEditable={false}
                            isEditable={false}
                        />
                    </div>
                );
            return (
                <div>
                    <Editable
                        submitFunction={submitFunction}
                        classNameInput='commentInput'
                        field='comment'
                        pk={elem.pk}
                        item_pk={null}
                        text={elem[c]}
                        editable
                        // isEditable={false}
                        isEditable={!hasSelectedRow}
                    />
                </div>
            );
            // return (
            //     <Editable
            //         submitFunction={submitFunction}
            //         classNameInput='commentInput'
            //         field='comment'
            //         pk={elem.pk}
            //         item_pk={null}
            //         text={elem[c]}
            //         editable
            //     />
            // );
        }

        if (c === 'start_time') {
            return (
                <span
                    key={elem[c]}
                    style={{
                        textWrap: 'wrap',
                    }}
                >
                    {elem[c] ? moment(elem[c], 'HH:mm:ss').format('HH:mm') : null}
                </span>
            );
        }

        if (c === 'start_time' || c === 'end_time') {
            return (
                <span
                    key={elem[c]}
                    style={{
                        textWrap: 'wrap',
                    }}
                >
                    {elem[c] ? moment(elem[c], 'HH:mm:ss').format('HH:mm') : null}
                </span>
            );
        }

        if (c === 'customer_comment') {
            return (
                <Editable
                    field='customer_comment'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem[c]}
                    editable={false}
                />
            );
        }

        if (c === 'workerNoWork') {
            const rights = assetsRights?.[accountType];

            return <WorkerNoWork disabled={rights && !rights?.includes(c)} elem={elem} />;
        }

        if (c === 'cansel') {
            return `${typeof elem.statusItem === 'number' ? 'Нет' : 'Да'}`;
        }

        if (c === 'estimation') {
            const rights = assetsRights?.[accountType];

            return (
                <Estimation
                    pk={elem.pk}
                    estimation={elem?.score?.score}
                    disabled={rights && !rights?.includes(c)}
                    isEditable={!!elem.requestworker_id}
                    slot={elem.id}
                    service={elem.parentId}
                    workerId={elem.requestworker_id}
                    itemworker_id={elem.requestworker_id}
                    fetchRequestsProps={{
                        payment_status: filterMap(localStorage2Service.get('filter')),
                        // status: localStorage2Service.get('status'),
                        customer_resolution: localStorage2Service.get('customer_resolution'),
                        location: localStorage2Service.get('branch'),
                        first_day:
                            localStorage2Service.get('startRanges') &&
                            moment(localStorage2Service.get('startRanges')).format('DD.MM.YYYY'),
                        last_day:
                            localStorage2Service.get('startRanges') &&
                            moment(localStorage2Service.get('endRanges')).format('DD.MM.YYYY'),
                    }}
                />
            );
        }

        if (c === 'hours_worked_plan') {
            return <HoursWorkedPlan elem={elem} />;
        }

        if (c === 'hours_worked') {
            const submitFunction = async (value) => {
                const data = {
                    worker_ids: [elem.workerId],
                    request_id: elem.pk,
                    item_id: elem.item_id,
                    hours: value,
                };
                axios({
                    method: 'POST',
                    url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                    data,
                    headers: {
                        Authorization: `Bearer ${authorization.getToken()}`,
                    },
                }).then(() => {
                    dispatch(fetchRequests(requestPageProps));
                });
            };

            const rights = assetsRights?.[accountType];

            const disabled = [
                WorkerItemStatus.FinishPhotoRejected,
                WorkerItemStatus.StartConfirmed,
                WorkerItemStatus.Completed,
            ].includes(elem.statusItem);

            const disabledRights = rights && !rights?.includes(c);

            if (disabledRights)
                return (
                    <div>
                        <Editable
                            submitFunction={submitFunction}
                            field='customer_comment'
                            pk={elem.pk}
                            item_pk={null}
                            text={
                                elem.hours_worked
                                    ? Number(elem.hours_worked).toFixed(1)
                                    : elem.hours_worked
                            }
                            editable
                            isEditable={false}
                            classNameInput={styles.hours_worked}
                            className={styles.hours_workedWrapper}
                        />
                    </div>
                );

            return (
                <div>
                    <Editable
                        submitFunction={submitFunction}
                        field='customer_comment'
                        pk={elem.pk}
                        item_pk={null}
                        text={
                            elem.hours_worked
                                ? Number(elem.hours_worked).toFixed(1)
                                : elem.hours_worked
                        }
                        editable
                        isEditable={!hasSelectedRow && disabled}
                        classNameInput={styles.hours_worked}
                        className={styles.hours_workedWrapper}
                    />
                </div>
            );
        }
        if (c === 'price') {
            return currencyFormat(elem.price);
        }

        if (c === 'status') {
            const rights = assetsRights?.[accountType];
            const disabled = rights && !rights?.includes(c);

            if (disabled)
                return (
                    <div>
                        <span>{statuses?.[elem.statusItem] ?? null}</span>
                    </div>
                );

            if (hasSelectedRow)
                return (
                    <div>
                        <span>{statuses?.[elem.statusItem] ?? null}</span>
                    </div>
                );
            return (
                <div>
                    <Dropdown
                        onVisibleChange={(v) => {
                            setOpenD(v);
                        }}
                        overlay={menu}
                        placement='right'
                        trigger={['click']}
                    >
                        <span>{statuses?.[elem.statusItem] ?? null}</span>
                    </Dropdown>
                </div>
            );
        }

        if (c === 'fact_start') {
            const rights = assetsRights?.[accountType];

            return (
                <FactStart
                    disabled={rights && !rights?.includes(c)}
                    elem={elem}
                    requestPageProps={requestPageProps}
                />
            );
        }

        if (c === 'location') {
            return (
                <div
                    key={elem.location?.name}
                    title={elem.location?.name}
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'left',
                    }}
                >
                    {elem.location?.name ? elem.location?.name : null}
                </div>
            );
        }

        if (c === 'locationAddress') {
            return (
                <div
                    key={elem.location?.address}
                    title={elem.location?.address}
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'left',
                    }}
                >
                    {elem.location?.address ? elem.location?.address : null}
                </div>
            );
        }

        if (c === 'locationCode') {
            return (
                <div
                    key={elem.location?.name}
                    title={elem.location?.name}
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'left',
                        textWrap: 'nowrap',
                    }}
                >
                    {elem.location?.name ? elem.location?.name : null}
                </div>
            );
        }

        if (c === 'locationFormat') {
            return (
                <div
                    key={elem.location?.type}
                    title={elem.location?.type}
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'left',
                    }}
                >
                    {elem.location?.type ? elem.location?.type : null}
                </div>
            );
        }

        if (c === 'fact_end') {
            const hour = elem?.hours_worked || 0;
            const minutes = getMinutesWithDinner(
                moment(elem.start).format('HH:mm'),
                moment(elem.start).add('h', hour).format('HH:mm')
            );

            const end = moment(elem.start)?.add('minutes', minutes);

            const updateRequestComment = (value) => {
                requestWithoutHour(value);
            };

            const requestWithHour = (value) => {
                const data = {
                    finish_at: moment(value, 'HH:mm').utc(false).format(),
                };
                axios({
                    method: 'POST',
                    url: `${BACKEND_URL}gt/customer/v2/delivery/update_itemworker_start_finish/${elem.itemworker_id}`,
                    data,
                    headers: {
                        Authorization: `Bearer ${authorization.getToken()}`,
                    },
                })
                    .then(() => {
                        dispatch(fetchRequests(requestPageProps));
                    })
                    .catch((e) => {
                        onFetchError(e);
                    });
            };
            const requestWithoutHour = (value) => {
                const v = getHoursWithDinner(moment(elem.start).format('HH:mm'), value);

                const data = {
                    worker_ids: [elem.workerId],
                    request_id: elem.pk,
                    item_id: elem.item_id,
                    hours: v,
                };

                axios({
                    method: 'POST',
                    url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                    data,
                    headers: {
                        Authorization: `Bearer ${authorization.getToken()}`,
                    },
                }).then(() => {
                    requestWithHour(value);
                });
            };

            const rights = assetsRights?.[accountType];

            return elem.itemworker_id && elem.start && elem.parentId ? (
                <TimeAction
                    disabled={rights && !rights?.includes(c)}
                    updateRequestComment={updateRequestComment}
                    time={elem.end ? moment(elem.end) : end}
                    notShow={!hour && !elem.end}
                />
            ) : null;
        }

        if (c === 'possible_workers') {
            return <PossibleWorkers elem={elem} />;
        }

        if (c === 'personnel_number') {
            const rights = assetsRights?.[accountType];

            const idsWorkers = rowDataTable
                .filter((i) => i.pk === elem.pk)
                .filter((i) => i.id !== elem.id)
                .map((i) => i.workerId)
                .filter((i) => i);
            return (
                <Num
                    pk={elem.pk}
                    worker={
                        elem.personnel_number ?? elem.workerId
                            ? `${
                                  elem.personnel_number
                                      ? elem.personnel_number
                                      : `ID${elem.workerId}`
                              }`
                            : null
                    }
                    disabled={rights && !rights?.includes(c)}
                    slot={elem.id}
                    service={elem.parentId}
                    idsWorkers={idsWorkers}
                    fetchRequestsProps={{
                        payment_status: filterMap(localStorage2Service.get('filter')),
                        // status: localStorage2Service.get('status'),
                        customer_resolution: localStorage2Service.get('customer_resolution'),
                        location: localStorage2Service.get('branch'),
                        first_day:
                            localStorage2Service.get('startRanges') &&
                            moment(localStorage2Service.get('startRanges')).format('DD.MM.YYYY'),
                        last_day:
                            localStorage2Service.get('startRanges') &&
                            moment(localStorage2Service.get('endRanges')).format('DD.MM.YYYY'),
                    }}
                />
            );
        }

        if (c === 'name') {
            const idsWorkers = rowDataTable
                .filter((i) => i.pk === elem.pk)
                .filter((i) => i.id !== elem.id)
                .map((i) => i.workerId)
                .filter((i) => i);

            const rights = assetsRights?.[accountType];

            return (
                <Num
                    disabled={rights && !rights?.includes(c)}
                    pk={elem.pk}
                    worker={elem[c]}
                    slot={elem.id}
                    service={elem.parentId}
                    idsWorkers={idsWorkers}
                    fetchRequestsProps={{
                        payment_status: filterMap(localStorage2Service.get('filter')),
                        // status: localStorage2Service.get('status'),
                        customer_resolution: localStorage2Service.get('customer_resolution'),
                        location: localStorage2Service.get('branch'),
                        first_day:
                            localStorage2Service.get('startRanges') &&
                            moment(localStorage2Service.get('startRanges')).format('DD.MM.YYYY'),
                        last_day:
                            localStorage2Service.get('startRanges') &&
                            moment(localStorage2Service.get('endRanges')).format('DD.MM.YYYY'),
                    }}
                />
            );
        }

        if (c === 'contact') {
            return (
                <Editable
                    field='driver_name'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem[c]}
                    editable={isEditable}
                    isEditable={isEditable}
                />
            );
        }

        if (c === 'approve') {
            const isVisible =
                elem.status.id === 'finished' || elem.status.id === 'cancelled_with_payment';

            const renderButtonApprove = (value) => (
                <ButtonApprove
                    elemsData={elemsData}
                    pk={elem.pk}
                    value={value}
                    sorting={sorting}
                    isVisible={isVisible}
                    setSelectedApprove={setSelectedApprove}
                    selectedApprove={selectedApprove}
                />
            );

            if (elem.customer_resolution !== 'confirmed') {
                return renderButtonApprove(true);
            }
            if (elem.customer_resolution === 'confirmed') {
                return renderButtonApprove(false);
            }
            return null;
        }

        if (c === 'percentWorker') {
            return elem.score
                ? `${elem?.score?.worker_score ? Number(elem?.score?.worker_score).toFixed(2) : ''}`
                : null;
        }

        if (c === 'percentKA') {
            return elem.avg_workers_score ? Number(elem.avg_workers_score).toFixed(2) : null;
            // return elem?.score?.percentage ? elem?.score?.percentage : null;
        }

        return elem[c];
    }
);

const showError = (error) => {
    if (error) {
        message.error({ content: error });
    }
};

const onFetchError = (error) => {
    let err = '';

    if (error?.response?.data?.messages) {
        for (const key in error?.response?.data?.messages) {
            const element = error?.response?.data?.messages[key];
            if (element.length) {
                err += ' ';
                if (Array.isArray(element) && element) {
                    err += `${key}: `;
                    err += element.join(' ');
                } else {
                    err += element;
                }
            }
        }
    }

    showError(err || error?.response?.data?.detail || error.message);
};

const ExpectedHoursMax = memo(({ elem }) => {
    const formattedData = elem?.items?.reduce((pprev, i) => {
        const result = i?.workers_required_for_service?.reduce(
            (prev, i) => (prev >= i.expected_hours ? prev : i.expected_hours),
            0
        );
        return pprev >= result ? pprev : result;
    }, 0);

    const formattedDataFinish =
        moment(elem.confirmed_timepoint, 'HH:mm').add(formattedData, 'hours') || moment();

    return <div>{elem.confirmed_timepoint ? formattedDataFinish.format('HH:mm') : '-'}</div>;
});

const ExpectedTotalHours = memo(({ elem }) => {
    const itemTextContent = (item, itemKey, defaultValue) => {
        if (!item) {
            return '-';
        }

        return item[itemKey] || defaultValue;
    };

    const textContent = (request, itemOrRequestKey) =>
        request[itemOrRequestKey] || itemTextContent(request.items[0], itemOrRequestKey, '');
    const workersRequiredForService =
        elem.items?.length > 1
            ? elem.items.reduce((prev, i) => {
                  if (i.workers_required_for_service)
                      return [...prev, ...i.workers_required_for_service];
                  return prev;
              }, [])
            : textContent(elem, 'workers_required_for_service');
    const maxExpectedHours = (workersRequiredForService || [])?.reduce((prev, i) => {
        return prev + Number((i.workers_required || 0) * (i.expected_hours || 0));
    }, 0);

    return <div>{maxExpectedHours}</div>;
});

const CommentTT = memo(({ requestPageProps, elem, disabled }) => {
    const dispatch = useDispatch();
    const comment = elem?.comments?.find((i) => i.type === 'commentTT')?.comment;
    const isCreated = typeof comment === 'string';
    const submitFunction = async (value, setValue) => {
        const createData = {
            requestworker: elem.requestworker_id,
            type: 'commentTT',
            comment: value,
        };
        const updateReqData = {
            type: 'commentTT',
            comment: value,
        };
        if (setValue) setValue(value);
        await axios({
            method: isCreated ? 'PUT' : 'POST',
            url: isCreated
                ? `${BACKEND_URL}gt/customer/v2/delivery/update_comment/${elem.requestworker_id}`
                : `${BACKEND_URL}gt/customer/v2/delivery/create_comment/`,
            data: isCreated ? updateReqData : createData,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                dispatch(fetchRequests(requestPageProps));
            })
            .catch((e) => {
                onFetchError(e);
                if (setValue) setValue(comment);
            });
    };

    return (
        <Editable
            submitFunction={submitFunction}
            classNameInput='commentInput'
            field='commentTT'
            pk={elem.pk}
            item_pk={null}
            text={comment}
            editable
            isEditable={!!elem.requestworker_id && !disabled}
            returnValue
        />
    );
});

const CommentKA = memo(({ elem, requestPageProps, disabled }) => {
    const dispatch = useDispatch();
    const comment = elem?.comments?.find((i) => i.type === 'commentKA')?.comment;
    const isCreated = typeof comment === 'string';
    const submitFunction = async (value, setValue) => {
        const createData = {
            requestworker: elem.requestworker_id,
            type: 'commentKA',
            comment: value,
        };
        const updateReqData = {
            type: 'commentKA',
            comment: value,
        };
        if (setValue) setValue(value);
        axios({
            method: isCreated ? 'PUT' : 'POST',
            url: isCreated
                ? `${BACKEND_URL}gt/customer/v2/delivery/update_comment/${elem.requestworker_id}`
                : `${BACKEND_URL}gt/customer/v2/delivery/create_comment/`,
            data: isCreated ? updateReqData : createData,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                dispatch(fetchRequests(requestPageProps));
            })
            .catch((e) => {
                onFetchError(e);
                if (setValue) setValue(comment);
            });
    };

    return (
        <Editable
            submitFunction={submitFunction}
            classNameInput='commentInput'
            field='commentKA'
            pk={elem.pk}
            item_pk={null}
            text={comment}
            editable
            isEditable={!!elem.requestworker_id && !disabled}
        />
    );
});

const WorkerNoWork = memo(({ elem }) => {
    const cansel = useMemo(() => {
        const cansel = elem.possible_workers?.length
            ? elem.possible_workers.filter((i) => i.worker_id !== elem.workerId)?.length
            : 0;

        return `${cansel ? 'Да' : 'Нет'}`;
    }, [elem.possible_workers, elem.possible_workers]);

    return cansel;
});

const PossibleWorkers = memo(({ elem }) => {
    const value = useMemo(() => {
        const possible_workers =
            elem.personnel_number ?? elem.workerId
                ? `${elem.personnel_number ? elem.personnel_number : `ID${elem.workerId}`}`
                : null;

        const possible_workers_list = elem.possible_workers?.filter(
            (i) => i.worker_id !== elem.workerId
        );

        const possible_worker = possible_workers_list?.[possible_workers_list.length - 1];

        const cansel = possible_worker
            ? possible_worker?.personnel_number || `ID${possible_worker?.worker_id}`
            : possible_workers;

        return cansel;
    }, [elem.possible_workers, elem.workerId, elem.personnel_number]);

    return value;
});

const HoursWorkedPlan = memo(({ elem }) => {
    const value = useMemo(() => {
        if (elem.start_time && elem.end_time) {
            const startMinutes = moment(elem.start_time, 'HH:mm:ss').minutes();
            const startHours = moment(elem.start_time, 'HH:mm:ss').hours();
            const endMinutes = moment(elem.end_time, 'HH:mm:ss').minutes();
            const endHours = moment(elem.end_time, 'HH:mm:ss').hours();

            const startM = startHours * 60 + startMinutes;
            const endM = endHours * 60 + endMinutes;

            const f = endM - startM;
            const s = endM + 1440 - startM;
            let subtractM = endM - startM > 0 ? f : s;

            if (subtractM > 390 && subtractM < 540) subtractM -= 30;
            if (subtractM >= 540 && subtractM < 750) subtractM -= 60;
            if (subtractM >= 750) subtractM -= 90;

            const m = subtractM % 60;
            const h = Math.floor(subtractM / 60);
            const v = Number(h + m / 60).toFixed(1);

            return v;
        }
        return null;
    }, [elem.start_time, elem.end_time]);

    return value;
});

const FactStart = memo(({ elem, requestPageProps, disabled }) => {
    const dispatch = useDispatch();

    const updateRequestComment = useCallback(
        (value) => {
            const hour = elem?.hours_worked || 0;
            if (!hour) {
                if (elem.itemworker_id && elem.start) {
                    requestWithoutHour(value);
                } else if (elem.statusItem === 2) {
                    actionMassStart(value);
                }
            } else {
                requestWithHour(value);
            }
        },
        [
            elem?.hours_worked,
            elem.itemworker_id,
            elem.start,
            elem.statusItem,
            requestWithoutHour,
            actionMassStart,
            requestWithHour,
        ]
    );

    const actionMassStart = useCallback(
        (value) => {
            const data = {
                worker_ids: [elem.workerId],
                request_id: elem?.pk,
                item_id: elem?.item_id,
            };

            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_start/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            })
                .then(() => {
                    requestWithoutHour(value);
                })
                .catch((e) => {
                    onFetchError(e);
                });
        },
        [elem.workerId, elem?.pk, elem?.item_id, authorization.getToken(), requestWithoutHour]
    );

    const requestWithoutHour = useCallback(
        (value) => {
            const data = {
                start_at: moment(value, 'HH:mm').utc(false).format(),
            };
            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/update_itemworker_start_finish/${elem.itemworker_id}`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            })
                .then(() => {
                    dispatch(fetchRequests(requestPageProps));
                })
                .catch((e) => {
                    onFetchError(e);
                });
        },
        [elem.itemworker_id, requestPageProps, authorization.getToken()]
    );

    const requestWithHour = useCallback(
        (value) => {
            const hour = elem?.hours_worked || 0;
            const minutes = getMinutesWithDinner(
                moment(elem.start).format('HH:mm'),
                moment(elem.start).add('h', hour).format('HH:mm')
            );

            const end = moment(elem.start)?.add('minutes', minutes);
            const _end = elem.end ? moment(elem.end) : end;

            const v = getHoursWithDinner(value, _end.format('HH:mm'));

            const data = {
                worker_ids: [elem.workerId],
                request_id: elem.pk,
                item_id: elem.item_id,
                hours: v,
            };

            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            }).then(() => {
                requestWithoutHour(value);
            });
        },
        [
            requestWithoutHour,
            elem?.hours_worked,
            elem.start,
            elem.end,
            elem.workerId,
            elem.pk,
            elem.item_id,
            authorization.getToken(),
        ]
    );

    return (elem.itemworker_id && elem.start) || elem.statusItem === 2 ? (
        <TimeAction
            updateRequestComment={updateRequestComment}
            time={elem.start ? moment(elem.start) : null}
            disabled={disabled}
        />
    ) : null;
});
