import { Select as UISelect } from 'antd';
import styled from 'styled-components';

const getWidth = (width) => {
    if (typeof width === 'string') {
        return width;
    }

    if (typeof width === 'number') {
        return `${width}px`;
    }

    return '100%';
};

export const Select = styled(UISelect)`
    ${({ width }) =>
        `
        & .ant-select-selector {
            background-color: #f2f2f2 !important;
        width:${getWidth(width)} !important; 
        border: none !important;
        display: flex;
        align-items: center;
        border-radius: 7px !important;
        box-shadow: none !important;
    }
    
    & .ant-select-arrow {
        transition: transform 0.2s ease-in;
        transform: rotate(0deg) !important;
    }
    &.ant-select-open .ant-select-arrow {
        transform: rotate(180deg) !important;
    }
    `}
`;
