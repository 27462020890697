import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withUserInfo } from '@hocs';
import { Routes } from '@pages/Routes';
import { logout, setPlatform } from '@store/actions';
import { authorization } from '@utils';
import { getPlatform } from '@utils/getPlatform';

import './styles/index.scss';
import 'antd/dist/antd.css';
import styles from './App.module.scss';

export const App = withUserInfo(() => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const resizeEvent = () => {
        const platform = getPlatform();
        dispatch(setPlatform(platform));
    };

    useLayoutEffect(() => {
        const reload = () => {
            const tokenRefresh = authorization.getTokenRefresh();
            if (!isAuthenticated && !!tokenRefresh) {
                authorization.getRefreshedToken(dispatch(logout()), tokenRefresh).catch(() => {
                    dispatch(logout());
                });
            }
        };

        reload();
    }, []);

    useLayoutEffect(() => {
        resizeEvent();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', resizeEvent);

        return () => {
            window.removeEventListener('resize', resizeEvent);
        };
    }, []);

    return (
        <div className={styles.app}>
            <Routes />
        </div>
    );
});
