import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { Form, Space } from 'antd';
import styled from 'styled-components';

import { UploadFilePopup } from '@components';
import { downloadFile } from '@utils';

import { platformSelector } from '../../../store/reducers/platform';

import { DownloadButton, ImportButton } from './Buttons';

const ShortPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;
const completedStatus = 'completed';
const downloadUrl = 'gt/customer/get_contract';

export const Controls = ({ openInfoPopup, form }) => {
    const registrationStatus = useSelector((state) => state.user.info.registrationStatus);
    const platform = useSelector(platformSelector);

    const onDownloadError = async (error) => {
        try {
            const currentError = JSON.parse(await error.response.data.text());

            await form.validateFields();

            openInfoPopup({
                content: currentError.detail || error.message,
                title: 'Ошибка',
            });
        } catch (err) {
            openInfoPopup({
                content: 'Заполните все поля',
                title: 'Ошибка',
            });
        }
    };

    const onDownload = downloadFile({
        url: downloadUrl,
        onError: onDownloadError,
        filename: 'gettask_contract.pdf',
    });

    return (
        <Form.Item>
            <Space direction={platform === 'mobile' ? 'vertical' : 'horizontal'}>
                <DownloadButton onClick={onDownload} />
                {registrationStatus !== completedStatus && (
                    <ShortPopup trigger={<ImportButton />} modal closeOnDocumentClick>
                        {(close) => (
                            <UploadFilePopup
                                close={close}
                                name='scans'
                                endpoint='/gt/customer/upload_contract_scans'
                                onSuccess={() =>
                                    openInfoPopup({
                                        content:
                                            'Спасибо, что прикрепили скан!\n' +
                                            'В ближайшее время с вами свяжется менеджер.',
                                        title: 'Результат',
                                    })
                                }
                                onFailure={(err) =>
                                    openInfoPopup({
                                        content: err,
                                        title: 'Ошибка',
                                    })
                                }
                            />
                        )}
                    </ShortPopup>
                )}
            </Space>
        </Form.Item>
    );
};
