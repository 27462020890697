import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, message } from 'antd';

import { useCreateAddresOrRequest } from '../../components/NewRequestPopup/hook';
import { formatError } from '../../components/NewRequestPopup/utils';
import { accountInfo, fetchExistingCards, fetchLocation } from '../../store/actions';
import { formatDate } from '../HomePage/utils';

import { RequestGeneralInfo } from './components/RequestGeneralInfo';
import { Requisites } from './components/Requisites';

export const CreateRequest = () => {
    const stepMap = {
        general: 'requisites',
        // other: 'requisites',
    };
    const reverseStepMap = {
        requisites: 'general',
        // other: 'general',
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null);
    const [creationFinished, setCreationFinished] = useState(false);
    const onCreateAddresOrRequest = useCreateAddresOrRequest();
    const { list: servicesList } = useSelector((state) => state.services);
    const { data: cardList } = useSelector((state) => state.cards);
    const lastStateSearch = useSelector((state) => state.services.lastStateSearch);

    useEffect(async () => {
        setCreationFinished(false);
        await dispatch(fetchLocation());
        await dispatch(fetchExistingCards({}, {}));
    }, []);

    useEffect(async () => {
        if (lastStateSearch?.curReq) {
            setFormData({
                location: lastStateSearch.location,
                locationId: lastStateSearch.location?.id,
                routeId: lastStateSearch.curReq,
                date: lastStateSearch.createdRequestDate,
            });
        }
    }, [lastStateSearch]);

    const submitRequest = async () => {
        try {
            await onCreateAddresOrRequest({
                date: formatDate(formData.date || formData.date1),
                routeId: formData.routeId,
                locationId: formData.locationId,
                value: {
                    index: formData.index,
                    mass: formData.mass,
                    places: formData.places,
                    shipment_type: formData.shipment_type,
                    address: formData.address,
                    has_elevator: formData.has_elevator,
                    driver: formData.driver,
                    phones: formData.phones,
                    time_interval: formData.time_interval?.split('-') || [null, null],
                    max_size: formData.max_size,
                    floor: formData.floor,
                    carrying_distance: formData.carrying_distance,
                    photos: formData.photos,
                },
                time: formData.time_interval?.split('-') || [formData.time, '__:__'],
                services: form
                    .getFieldValue('services')
                    ?.filter((i) => !!i?.service && !!i?.workers)
                    ?.map((i) => ({
                        workers_required: i?.workers ? Number(i?.workers) : undefined,
                        expected_hours: i?.expected_hours ? Number(i?.expected_hours) : undefined,
                        service_id: Number(servicesList?.find((j) => j?.text === i?.service)?.id),
                    })),
            });

            message.info({
                content: 'Заявка успешно создана',
            });
            window.location.href = '/requests';
        } catch (err) {
            // const errorTitle = err?.error?.response?.data?.detail || 'Ошибка';

            const defaultMessage = `${err?.error?.response?.status} ${err?.error?.response?.statusText}`;
            const errorMessage = formatError(err?.error?.response?.data?.messages, defaultMessage);

            message.error({
                content: errorMessage,
            });
        }
    };

    const stepFinished = (name) => {
        setFormData({ ...formData, ...form.getFieldsValue() });
        const nextStep = stepMap[name];
        if (nextStep === 'requisites' && (cardList === undefined || lastStateSearch?.curReq)) {
            setCreationFinished(true);
        } else if (nextStep) {
            setStep(stepMap[name]);
        } else {
            setCreationFinished(true);
        }
    };

    const stepBack = (name) => {
        if (reverseStepMap[name]) setStep(reverseStepMap[name]);
        else history.push('/requests');
    };

    const [step, setStep] = useState(Object.keys(stepMap)[0]);

    useEffect(async () => {
        const response = await dispatch(accountInfo());
        if (creationFinished) {
            if (!response.payload.data.allow_requests_creation) {
                message.error({
                    content: 'Пополните баланс или добавьте карту для подачи заявок',
                });
                return;
            }
            submitRequest();
        }
    }, [creationFinished]);

    return (
        <>
            {step === 'requisites' && (
                <Requisites
                    onFinish={() => stepFinished('requisites')}
                    onBack={() => stepBack('requisites')}
                />
            )}
            {step === 'general' && (
                <RequestGeneralInfo
                    initial={formData}
                    form={form}
                    onFinish={() => stepFinished('general')}
                    onBack={() => stepBack('general')}
                />
            )}
        </>
    );
};
