import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { CustomAutocomplete } from '@components';
import { fetchRequests } from '@store/actions';
import { authorization, BACKEND_URL } from '@utils';

import styles from './styles.module.scss';

export const Num = ({ pk, worker, slot, service, idsWorkers, fetchRequestsProps, disabled }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);

    const [workersOptions, setWorkersOptions] = useState({
        load: true,
        err: false,
        data: undefined,
    });

    const mapFunction = (r) => ({
        text: `${r.personnel_number ? r.personnel_number : `ID${r.id}`} ${r.text}`,
        id: +r.id,
    });

    const updateRouteOptions = async (value, page) => {
        setWorkersOptions((prev) => ({
            ...prev,
            load: true,
        }));

        const params = {
            q: value,
            forward: {
                delivery_request_pk: pk,
            },
            page,
        };

        const { data } = await axios({
            method: 'get',
            url: `${BACKEND_URL}bo/delivery/worker_autocomplete/`,
            params,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        });

        setWorkersOptions((prev) => ({
            ...prev,
            load: false,
            data: data.results.map(mapFunction),
        }));
    };

    useEffect(() => {
        if (open && !worker) updateRouteOptions(value, page);
    }, [value, page, open, worker]);

    const options = useMemo(() => {
        if (workersOptions.data) {
            return workersOptions.data.filter((i) => !idsWorkers?.includes(i.id) ?? true);
        }
        return workersOptions.data;
    }, [workersOptions.data, idsWorkers]);

    const onScroll = () => {
        setPage(1);
    };

    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setValue('');
        setFocus(false);
    }, [pk]);

    if (!open || worker || disabled)
        return (
            <div
                onClick={(e) => {
                    if (!worker && !disabled) {
                        e.stopPropagation();
                        setFocus(true);
                        setOpen(true);
                    }
                }}
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }}
                title={worker ?? '-'}
            >
                {worker ?? '-'}
            </div>
        );

    const saveWorker = async (id) => {
        if (id === -1 || !id) return;
        const data = {
            request: pk,
            worker: id,
            slot,
            service,
        };

        const response = await axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/add/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        });

        if (response) {
            dispatch(fetchRequests(fetchRequestsProps));
            setValue('');
            setOpen(false);
        }
    };

    return (
        <CustomAutocomplete
            key={pk}
            onIdChange={(id) => {
                setFocus(false);
                saveWorker(id);
            }}
            onChange={(value) => {
                setValue(value);
            }}
            onBlur={() => {
                setOpen(false);
            }}
            onScroll={onScroll}
            className={styles.CustomAutocompleteWorker}
            size='large'
            value={value}
            options={options}
            focus={focus}
            submitOnEnter
            autoFocus
            hasClose={false}
        />
    );
};
