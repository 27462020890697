import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, message, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Button, Checkbox, FormItem, Input, Label } from '@components';
import { withAuthentication } from '@hocs';
import { authorization } from '@utils';

import { accountInfo, loginByPhone, sendVerificationCode } from '../../store/actions';

import { formatPhone } from './utils';

import styles from './styles.module.scss';

export const PhoneLoginPage = withAuthentication(({ _setPopup }) => {
    const [codeSent, setCodeSent] = useState(false);
    const [form] = useForm();
    const dispatch = useDispatch();

    const onSendVerificationCode = async () => {
        const phone = form.getFieldValue('phone');
        if (phone) {
            await dispatch(sendVerificationCode({ phone: formatPhone(phone) }));
            setCodeSent(true);
        }
    };

    const onLogin = async () => {
        try {
            const res = await dispatch(
                loginByPhone({
                    phone: formatPhone(form.getFieldValue('phone')),
                    key: form.getFieldValue('code'),
                })
            );

            const columns = localStorage.getItem('columns');
            const columns2 = localStorage.getItem('columns2');
            localStorage.clear();
            localStorage.setItem('columns', columns);
            localStorage.setItem('columns2', columns2);
            authorization.setToken(res.payload.data.access, res.payload.data.refresh);
            dispatch(accountInfo({ token: res.payload.data.access }));
        } catch (err) {
            message.error({
                content:
                    err?.error?.response?.data?.message ||
                    `${err?.error?.response?.status || 'Error'} ${
                        err?.error?.response?.statusText || 'fetch'
                    }`,
                title: 'Ошибка',
            });
        }
    };

    const phoneRules = [
        {
            required: true,
            message: 'Введите номер телефона',
        },
        {
            pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
            message: 'Неверный номер телефона',
        },
    ];

    return (
        <>
            <Form style={{ width: 320 }} form={form}>
                <Typography.Title level={5} ellipsis style={{ fontSize: 24 }}>
                    Вход
                </Typography.Title>

                <FormItem
                    isLabelVertical
                    label={<Label>Телефон</Label>}
                    name='phone'
                    rules={phoneRules}
                >
                    <InputMask
                        mask='+7 (999) 999-99-99'
                        alwaysShowMask
                        className='ant-input ant-input-lg ant-input-status-success sc-hKMtZM liAagz'
                        disabled={codeSent}
                    />
                </FormItem>

                <FormItem
                    isLabelVertical
                    label={<Label>Код из смс</Label>}
                    name='code'
                    hidden={!codeSent}
                >
                    <Input type='code' size='large' />
                </FormItem>

                <FormItem className={styles.resetContainer}>
                    <Button type='link'>
                        <Link to='/login' className={styles.resetPasswordLink}>
                            Вход через email
                        </Link>
                    </Button>
                </FormItem>

                <FormItem className={styles.getCodeContainer}>
                    <Button
                        className={styles.login_button}
                        size='large'
                        type='primary'
                        htmlType='submit'
                        onClick={() => {
                            if (codeSent) onLogin();
                            else onSendVerificationCode();
                        }}
                    >
                        {codeSent ? 'Войти' : 'Получить код'}
                    </Button>

                    <FormItem name='rememberMe' valuePropName='checked' noStyle>
                        <Checkbox style={{ marginLeft: 45 }}>Запомнить меня</Checkbox>
                    </FormItem>
                </FormItem>
            </Form>

            <div className={styles.register}>
                <div>Нет учетной записи?</div>
                <Link to='/signup'>Зарегистрируйтесь</Link>
            </div>
        </>
    );
});
