import { memo, useCallback } from 'react';

import { MainCellPart } from './MainCellPart';
import { changedFields, statuses, statusesFull } from './utils';

import styles from './styles.module.scss';

export const TableCell = memo(
    ({
        elem,
        c,
        columns,
        setMouseDown,
        selectedRow,
        hasSelectedRow,
        setSelectedRow,
        mouseDown,
        openD,
        updateData,
        setOpenD,
        rowDataTable,
        sorting,
        setStartId,
        setEndId,
        setKeyName,
        keyName,
        idx,
        startId = undefined,
        endId = undefined,
        setRestart,
        elemsData,
        setSelectedApprove,
        selectedApprove,
    }) => {
        const getStatusClass = useCallback(
            (status) => {
                const statusClass = statusesFull[status]?.class ?? 'status-default';

                return `${styles.status} ${styles[statusClass]}`;
            },
            [statusesFull]
        );

        const getBackgroundColor = useCallback(() => {
            let _startId = startId;
            let _endId = endId;

            if (typeof startId === 'number' && typeof endId === 'number' && keyName === c) {
                if (_startId > _endId) {
                    _endId = startId;
                    _startId = endId;
                }

                if (idx >= _startId && idx <= _endId) {
                    return '#d6f4ff';
                }
            }
            const item = selectedRow?.find((i) => i.id === elem.id && i.key === c);

            if (item && c === item.key) return '#d6f4ff';
            return undefined;
        }, [startId, endId, idx, c, keyName, selectedRow, elem.id]);

        let className = null;

        if (c === 'route') {
            className = styles.tableImgWrapper;
        }

        if (c?.toString() === 'status' && statuses?.[elem.statusItem]) {
            className = getStatusClass(elem?.statusItem);
        }

        const onMouseDown = () => {
            if (!changedFields.includes(c)) return;

            setMouseDown(true);
            setEndId(idx);
            setStartId(idx);
            setSelectedRow([]);
        };
        const onMouseUp = (e) => {
            setRestart(true);
            setMouseDown(false);
            // setEndId(idx);
            if (!changedFields.includes(c)) return;

            if (
                (e.target.tagName === 'SPAN' ||
                    e.target.classList.value.includes('ant-dropdown-trigger') ||
                    e.target.classList.value.includes('commentInput') ||
                    openD) &&
                typeof startId === 'number' &&
                startId === endId
            ) {
                setEndId(undefined);
                setStartId(undefined);
                setSelectedRow([]);
            }
        };

        const onMouseMove = () => {
            if (mouseDown) {
                setEndId(idx);
                setKeyName?.(c);
            }
        };

        return (
            <td
                key={`${c}_${elem.id}_${elem.pk}`}
                style={{
                    ...columns[c]?.style,
                    backgroundColor: getBackgroundColor(),
                }}
                className={styles.tableBody_td}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
            >
                <div
                    className={`${className} ${styles.tableBodyDiv} ${styles.tableBody_td_item}`}
                    style={{
                        overflow: c === 'addresses' ? 'visible' : '',
                        ...(c === 'index'
                            ? { display: 'flex', justifyContent: 'space-between' }
                            : {}),
                    }}
                >
                    <MainCellPart
                        elem={elem}
                        c={c}
                        updateData={updateData}
                        hasSelectedRow={hasSelectedRow}
                        setOpenD={setOpenD}
                        rowDataTable={rowDataTable}
                        sorting={sorting}
                        elemsData={elemsData}
                        setSelectedApprove={setSelectedApprove}
                        selectedApprove={selectedApprove}
                    />
                </div>
            </td>
        );
    }
);
