import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Menu } from 'antd';

import styles from './styles.module.scss';

function listenForOutsideClicks(listening, setListening, menuRef, setIsOpen, onBlur) {
    return () => {
        if (listening) return;
        if (!menuRef.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach(() => {
            document.addEventListener(`click`, (evt) => {
                if (menuRef?.current?.contains(evt.target)) return;
                setIsOpen(false);
                if (onBlur) onBlur(false);
            });
        });
    };
}

export const CustomAutocomplete = ({
    onChange,
    value,
    options,
    placeholder,
    onIdChange,
    disabled,
    defaultId,
    customValue,
    onBlur,
    className,
    submitOnEnter,
    autoFocus,
    dontClose = false,
    hasClose = true,
}) => {
    const [opened, setOpened] = useState(false);
    const [id, setId] = useState(defaultId);
    const [option, setOption] = useState(defaultId);

    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);

    useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpened, onBlur));

    useEffect(() => {
        setId(defaultId);
    }, [defaultId]);

    CustomAutocomplete.handleClickOutside = () => {
        setOpened(false);
        if (onBlur) {
            onBlur();
        }
    };
    const inputRef = React.createRef();

    const dataChange = useMemo(() => ({ id, option }), [id, option]);

    useEffect(() => {
        const { id, option } = dataChange;
        onIdChange?.(id, option);
    }, [dataChange]);

    useEffect(() => {
        if (autoFocus && !disabled) {
            setOpened(true);
        }
    }, [autoFocus]);

    useEffect(() => {
        if (opened) {
            inputRef.current.focus();
        } else {
            inputRef.current.blur();
        }
    }, [opened]);

    const toggleOpened = () => {
        if (!disabled) {
            setOpened(!opened);
        }
    };

    const clearSelection = (e) => {
        e.stopPropagation();

        onChange('');
        setId(-1);
        setOption(undefined);

        inputRef.current?.focus();
        setOpened(true);
    };

    const menu = (
        <Menu className={styles.menu}>
            {options?.map((i) => (
                <Menu.Item
                    key={`${i.id}`}
                    onClick={() => {
                        onChange(i.text);
                        setId(i.id);
                        setOption(i);
                        toggleOpened();
                    }}
                >
                    {i.text}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div
            ref={menuRef}
            className={`${styles.customSelect} ${styles.dropdownMenu} ${className}`}
            data-id-type='CustomAutocomplete'
            onClick={(e) => {
                if (dontClose) {
                    e.stopPropagation();
                }
            }}
        >
            <Dropdown
                onVisibleChange={(v) => {
                    if (dontClose) setOpened(v);
                    if (onBlur) onBlur();
                }}
                overlay={menu}
                placement='bottomCenter'
                trigger={['click']}
                visible={opened}
                overlayClassName={`${styles.selectItems} ${
                    !opened ? styles.selectHide : styles.selectWhite
                }`}
                data-id-type='CustomAutocompleteDropdown'
            >
                {/* <span>{statuses?.[elem.statusItem] ?? null}</span> */}
                <div
                    onClick={toggleOpened}
                    className={`${styles.selectSelected} classFromErrorSelect ${
                        opened
                            ? `${styles.selectArrowActive} ${styles.selectNoBorder} ${styles.selectWhite}`
                            : ''
                    }${disabled ? styles.disabled : ''}`}
                >
                    <input
                        onKeyDown={(e) => {
                            if (submitOnEnter === true && e.key === 'Enter' && options?.length) {
                                onChange(options[0].text);
                                setId(options[0].id);
                                toggleOpened();
                            }
                        }}
                        disabled={disabled}
                        className={disabled ? styles.disabled : ''}
                        placeholder={placeholder}
                        ref={inputRef}
                        value={customValue ?? value}
                        onChange={(e) => {
                            onChange(e.target.value);
                            if (id !== -1) {
                                setId(-1);
                            }
                        }}
                    />
                    {value && hasClose && (
                        <div style={{ marginRight: '12px' }} onClick={clearSelection}>
                            <img alt='close' src='/close_icon.svg' width='22px' height='22px' />
                        </div>
                    )}
                </div>
            </Dropdown>
            {/* <div
                className={`${styles.selectItems} ${
                    !opened ? styles.selectHide : styles.selectWhite
                }`}
            >
                {options?.map((o) => (
                    <div
                        onClick={() => {
                            onChange(o.text);
                            setId(o.id);
                            toggleOpened();
                        }}
                        key={o.id}
                        className={value === o ? styles.sameAsSelected : ''}
                        dangerouslySetInnerHTML={{ __html: o.text }}
                    />
                ))}
            </div> */}
        </div>
    );
};
