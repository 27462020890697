export const storageService = (prefix) => {
    // eslint-disable-next-line no-global-assign
    Storage = {
        set(key, data) {
            const _data = JSON.stringify(data);
            localStorage.setItem(`${prefix}_${key}`, _data);

            return data;
        },
        get(key, def) {
            const item = localStorage.getItem(`${prefix}_${key}`);

            if (item) {
                return JSON.parse(item);
            }

            return typeof def !== 'undefined' ? def : null;
        },
        remove(key) {
            localStorage.removeItem(`${prefix}_${key}`);
        },
        clear() {
            localStorage.clear();
        },
    };

    return Storage;
};
