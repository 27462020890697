import { Select } from '@components/Select';

export const CustomSelectWithStorage = ({
    localStorageService,
    updateData,
    optionName = 'select',
    width,
    ...props
}) => {
    const onChange = (value) => {
        const currentValue = !value ? null : value;
        localStorageService.set(optionName, currentValue);

        updateData?.();
    };

    return (
        <Select
            {...props}
            style={{ width }}
            onChange={onChange}
            defaultValue={localStorageService.get(optionName) || undefined}
        />
    );
};
