import { useDispatch } from 'react-redux';

import { Editable } from '@components/Editable';
import { updateContacts } from '@store/actions';

import styles from '../styles.module.scss';

export const MobileTable = ({ contactsList, updateContactsHandler }) => {
    const dispatch = useDispatch();
    const a = contactsList.map((c, i) => {
        const onContactsUpdate = (field) => (value) => {
            dispatch(updateContacts({ field, value, pk: c.pk }));
        };

        return (
            <tr key={i}>
                <td>
                    <div>{i + 1}</div>
                </td>
                <td>
                    <div>
                        <Editable
                            text={c.full_name}
                            onSuccess={updateContactsHandler}
                            submitFunction={onContactsUpdate('full_name')}
                        />
                    </div>
                </td>

                <td>
                    <div>
                        <Editable
                            text={c.email}
                            onSuccess={updateContactsHandler}
                            submitFunction={onContactsUpdate('email')}
                        />
                    </div>
                </td>
            </tr>
        );
    });

    const b = contactsList.map((c, i) => {
        const onContactsUpdate = (field) => (value) => {
            dispatch(updateContacts({ field, value, pk: c.pk }));
        };

        return (
            <tr key={i}>
                <td>
                    <div>{i + 1}</div>
                </td>
                <td>
                    <div>
                        <Editable
                            text={c.phone}
                            onSuccess={updateContactsHandler}
                            submitFunction={onContactsUpdate('phone')}
                        />
                    </div>
                </td>

                <td>
                    <div>
                        <Editable
                            text={c.position}
                            onSuccess={updateContactsHandler}
                            submitFunction='position'
                        />
                    </div>
                </td>
            </tr>
        );
    });

    const c = (
        <>
            <td>
                <div>№</div>
            </td>
            <td>
                <div>ФИО</div>
            </td>
            <td>
                <div>Почта</div>
            </td>
        </>
    );
    const d = (
        <>
            <td>
                <div>№</div>
            </td>
            <td>
                <div>Телефон</div>
            </td>
            <td>
                <div>Должность</div>
            </td>
        </>
    );

    return (
        <>
            <Table headTable={c} bodyTable={a} />
            <Table headTable={d} bodyTable={b} />
        </>
    );
};

const Table = ({ headTable, bodyTable }) => {
    return (
        <table className={styles.table}>
            <tbody>
                <tr>{headTable}</tr>
                <tr>
                    <td />
                </tr>
                {bodyTable}
            </tbody>
        </table>
    );
};
