import { useDispatch } from 'react-redux';

import { Editable } from '@components/Editable';
import { updateContacts } from '@store/actions';

import styles from '../styles.module.scss';

export const DesktopTable = ({ contactsList, updateContactsHandler }) => {
    const dispatch = useDispatch();

    return (
        <table className={styles.table}>
            <tbody>
                <tr>
                    <td>
                        <div>ФИО</div>
                    </td>
                    <td>
                        <div>Почта</div>
                    </td>
                    <td>
                        <div>Телефон</div>
                    </td>
                    <td>
                        <div>Должность</div>
                    </td>
                </tr>
                <tr>
                    <td />
                </tr>
                {contactsList.map((c, i) => {
                    const onContactsUpdate = (field) => (value) => {
                        dispatch(updateContacts({ field, value, pk: c.pk }));
                    };

                    return (
                        <tr key={i}>
                            <td>
                                <div>
                                    <Editable
                                        text={c.full_name}
                                        onSuccess={updateContactsHandler}
                                        submitFunction={onContactsUpdate('full_name')}
                                    />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <Editable
                                        text={c.email}
                                        onSuccess={updateContactsHandler}
                                        submitFunction={onContactsUpdate('email')}
                                    />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <Editable
                                        text={c.phone}
                                        onSuccess={updateContactsHandler}
                                        submitFunction={onContactsUpdate('phone')}
                                    />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <Editable
                                        text={c.position}
                                        onSuccess={updateContactsHandler}
                                        submitFunction='position'
                                    />
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
