import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Typography } from 'antd';

import { Button, Checkbox, FormItem, Input, Label } from '@components';
import { withAuthentication } from '@hocs';
import { accountInfo, obtainToken } from '@store/actions';
import { authorization } from '@utils';

import styles from './styles.module.scss';

export const LoginPage = withAuthentication(({ setPopup }) => {
    const dispatch = useDispatch();

    const onLogin = async ({ password, email }) => {
        try {
            const res = await dispatch(obtainToken({}, { email, password }));

            const columns = localStorage.getItem('columns');
            const columns2 = localStorage.getItem('columns2');
            localStorage.clear();
            localStorage.setItem('columns', columns);
            localStorage.setItem('columns2', columns2);

            authorization.setToken(res.payload.data.access_token, res.payload.data.refresh_token);
            dispatch(accountInfo({ token: res.payload.data.access_token }));
        } catch (err) {
            setPopup({
                open: true,
                content:
                    err?.error?.response?.data?.message ||
                    `${err?.error?.response?.status || 'Error'} ${
                        err?.error?.response?.statusText || 'fetch'
                    }`,
                title: 'Ошибка',
            });
        }
    };

    return (
        <>
            <Form style={{ width: 320 }} onFinish={onLogin}>
                <Typography.Title level={5} ellipsis style={{ fontSize: 24 }}>
                    Вход
                </Typography.Title>

                <FormItem isLabelVertical label={<Label>Email</Label>} name='email'>
                    <Input size='large' />
                </FormItem>

                <FormItem isLabelVertical label={<Label>Пароль</Label>} name='password'>
                    <Input type='password' size='large' />
                </FormItem>

                <FormItem className={styles.resetContainer}>
                    <Button type='link'>
                        <Link to='/reset_password' className={styles.resetPasswordLink}>
                            Забыли пароль?
                        </Link>
                    </Button>
                    <Button type='link'>
                        <Link to='/phone_login' className={styles.resetPasswordLink}>
                            Вход по номеру телефона
                        </Link>
                    </Button>
                </FormItem>

                <FormItem>
                    <Button
                        className={styles.login_button}
                        size='large'
                        type='primary'
                        htmlType='submit'
                    >
                        Войти
                    </Button>

                    <FormItem name='rememberMe' valuePropName='checked' noStyle>
                        <Checkbox style={{ marginLeft: 45 }}>Запомнить меня</Checkbox>
                    </FormItem>
                </FormItem>
            </Form>

            <div className={styles.register}>
                <div>Нет учетной записи?</div>
                <Link to='/signup'>Зарегистрируйтесь</Link>
            </div>
        </>
    );
});
