import moment from 'moment';

import { addAddressToRequest, createRequest } from '@store/actions';
import { REQUESTS_PREFIX, storageService } from '@utils';

const { useDispatch } = require('react-redux');

export const requestDataToFormData = (data) => {
    const formData = new FormData();
    formData.append('json', JSON.stringify(data));
    data.photos?.forEach((photo) => {
        formData.append('photos', photo.originFileObj);
    });
    return formData;
};

const localStorageService = storageService(REQUESTS_PREFIX);

export const useCreateAddresOrRequest = () => {
    const dispatch = useDispatch();

    const onCreateAddresOrRequest = async ({
        routeId,
        value,
        date,
        time: confirmed_timepoint,
        locationId,
        services,
    }) => {
        const {
            index,
            mass,
            places,
            shipment_type,
            address,
            has_elevator,
            driver,
            phones,
            time_interval,
            max_size = null,
            floor = null,
            carrying_distance = null,
            photos,
        } = value;

        const [intervalBegin, intervalEnd] = time_interval || [];
        const formattedVolume = value.volume ? Number(value.volume.replace(',', '.')) : undefined;
        const isExistRoute = routeId && routeId !== -1;

        const time =
            intervalEnd && !intervalEnd?.includes('_') && !intervalEnd?.includes('NaN')
                ? {
                      interval_begin: intervalBegin ?? undefined,
                      interval_end: intervalEnd ?? undefined,
                  }
                : {
                      confirmed_timepoint: confirmed_timepoint?.[0] || undefined,
                  };
        let response;
        if (isExistRoute) {
            response = await dispatch(
                addAddressToRequest(
                    {},
                    requestDataToFormData({
                        photos: photos?.fileList,
                        request: routeId,
                        code: index ?? undefined,
                        mass: mass || undefined,
                        volume: formattedVolume || undefined,
                        place_count: places || undefined,
                        shipment_type,
                        address: address.value,
                        has_elevator,
                        max_size,
                        floor,
                        carrying_distance,
                        workers_required: services,
                        ...time,
                    })
                )
            );
        } else {
            // TODO разобраться с зашитым request_id
            const data = requestDataToFormData({
                photos: photos?.fileList,
                location: Number(locationId),
                driver_name: driver,
                driver_phones: phones,
                date,
                request_id: 28,
                items: [
                    {
                        mass: mass || undefined,
                        shipment_type,
                        has_elevator,
                        workers_required: services,
                        code: index || undefined,
                        place_count: places || undefined,
                        address: address.value,
                        volume: formattedVolume || undefined,
                        carrying_distance,
                        floor,
                        max_size,
                        ...time,
                    },
                ],
            });
            response = await dispatch(createRequest({}, data));
        }
        const momentDate = moment(response?.payload?.data?.data?.date);
        if (
            response?.payload?.data?.data?.date &&
            momentDate.isValid() &&
            momentDate.isAfter(moment(localStorageService.get('endRanges', moment().toDate())))
        ) {
            localStorageService.set('endRanges', response?.payload?.data?.data?.date);
        }
    };

    return onCreateAddresOrRequest;
};
