import { Spinner } from '@components/Spinner';

import styles from './styles.module.scss';

export const TableWrapper = ({ head, body, isLoading, className }) => {
    return (
        <div className={styles.tableWrapper}>
            <table className={`${styles.table} ${className}`}>
                {head}
                <tr>
                    <td />
                </tr>
                {body}
            </table>

            {isLoading && <Spinner />}
        </div>
    );
};
