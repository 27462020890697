import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { useForm } from 'antd/lib/form/Form';
import styled from 'styled-components';

import { ContactsTable, PopupInfo, Wrapper } from '@components';
import { fetchOrganization } from '@store/actions';

import { NotLegalIngo } from './NotLegalInfo/NotLegalIngo';
import { UnfilledForm } from './UnfilledForm';
import { formatDataToFormData } from './utils';
import { Warning } from './Warning';

import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

export const Unfilled = withRouter(({ history, location }) => {
    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [isLegalDisplayed, setIsLegalDisplayed] = useState(false);
    const [form] = useForm();
    const dispatch = useDispatch();

    const { from } = (location && location.state) || { from: '/' };

    const openInfoPopup = useCallback(({ content, title }) => {
        setInfoPopup({ content, title, open: true });
    }, []);

    useEffect(() => {
        if (from === '/finish_registration') {
            history.push({ state: { from: '/' } });

            openInfoPopup({
                title: 'Информация',
                content: 'Регистрация успешно завершена',
            });
        }

        updateFormData();
    }, []);

    async function updateFormData() {
        const { payload } = await dispatch(fetchOrganization());
        if (payload.data) setIsLegalDisplayed(true);

        form.setFieldsValue(formatDataToFormData(payload.data));
    }

    return (
        <Wrapper title='Инфо'>
            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup.open}
                onClose={() => setInfoPopup({ ...infoPopup, open: false })}
            >
                {(close) => (
                    <PopupInfo title={infoPopup.title} close={close}>
                        {infoPopup.content}
                    </PopupInfo>
                )}
            </ShortPopup>

            <Warning />

            <div className={styles.container}>
                {isLegalDisplayed && <UnfilledForm form={form} openInfoPopup={openInfoPopup} />}
                <ContactsTable />
            </div>
            <div className={styles.container}>
                <NotLegalIngo form={form} />
            </div>
        </Wrapper>
    );
});
