const locations = (state = { list: [], isLoading: false, error: null }, action) => {
    switch (action.type) {
        case 'FETCH_LOCATIONS_REQUEST':
            return { ...state, isLoading: true };
        case 'FETCH_LOCATIONS_SUCCESS':
            return { ...state, isLoading: false, list: action.payload.data.results };
        case 'FETCH_LOCATIONS_FAILURE':
            return { ...state, isLoading: false, error: action.error.response.message };

        default:
            return state;
    }
};

export default locations;
