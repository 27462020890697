import moment from 'moment/moment';

export const formatDifDate = (startDate, endDate) => {
    if (!startDate || !endDate || startDate.includes('_') || endDate.includes('_')) return '';
    const [startHour, startMinutes] = startDate.split(':');
    const [endHour, endMinutes] = endDate.split(':');
    const startSeconds = +startHour * 3600 + +startMinutes * 60;
    const endSeconds = +endHour * 3600 + +endMinutes * 60;
    const dif =
        endSeconds > startSeconds
            ? endSeconds - startSeconds
            : 24 * 3600 - Math.abs(endSeconds - startSeconds);
    const result = [];
    result[0] = String(Math.floor(dif / 3600));
    const resultSec = String(dif / 60 - Math.floor(dif / 3600) * 60);
    result[1] = resultSec.length === 1 ? `0${resultSec}` : resultSec;

    return result.join(':');
};

export const formatDate = (date) =>
    new Date(date) instanceof Date ? moment(date).format('DD.MM.YYYY') : date || '';

export const statuses = {
    '-100': 'Неопределен',
    '-4': 'Удален',
    '-3': 'Срыв',
    '-2': 'Брак',
    '-1': 'Отмена',
    0: 'Новая',
    1: 'Селфи отклонено',
    2: 'Подтвержден на заявке',
    3: 'Подозрительный адрес',
    4: 'Проверка адреса',
    5: 'Подтверждён на адресе',
    6: 'Накладная отклонена',
    7: 'Накладная',
    8: 'Работа выполнена',
};

export const statusesFull = {
    '-100': { title: 'Неопределен', class: 'status-removed', show: true },
    '-4': { title: 'Удалена', class: 'status-removed', show: true },
    '-3': { title: 'Срыв заявки', class: 'status-failed', show: true },
    '-2': { title: 'Брак', class: 'status-failed', show: true },
    '-1': { title: 'Отмена', class: 'status-cancelled' },
    0: { title: 'Новая', class: 'status-new', show: true },
    1: { title: 'Селфи отклонено', class: 'status-failed', show: true },
    2: { title: 'Подтвержден на заявке', class: 'status-driver_callback' },
    3: { title: 'Подозрительный адрес', class: 'status-failed', show: true },
    4: { title: 'Проверка адреса', class: 'status-new', show: true },
    5: { title: 'Подтверждён на адресе', class: 'status-driver_callback' },
    6: { title: 'Накладная отклонена', class: 'status-declined' },
    7: { title: 'Накладная', class: 'status-driver_callback' },
    8: { title: 'Выполнена', class: 'status-finished' },
};

export const WorkerItemStatus = {
    Undefined: -100,
    Removed: -4,
    Failed: -3,
    Defect: -2,
    Cancelled: -1,
    New: 0,
    StartPhotoRejected: 1,
    RequestConfirmedByWorker: 2,
    SuspiciousAddress: 3,
    StartChecking: 4,
    StartConfirmed: 5,
    FinishPhotoRejected: 6,
    FinishChecking: 7,
    Completed: 8,
};
