import { Checkbox as UICheckbox } from 'antd';
import styled from 'styled-components';

const getColor = (currentColor, defaultColor = '#b3b3b3') => {
    if (currentColor) {
        return currentColor;
    }

    return defaultColor;
};

export const Checkbox = styled(UICheckbox)`
    ${({ bgColor }) => `
        & span:last-child{
            color: #b3b3b3;
            margin-bottom: -3px;
        }
        & .ant-checkbox-input{
            width: 22px !important;
            height: 22px !important;
        }

        & .ant-checkbox-inner::after{
            left:30%;
        }

        & .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-inner {
            background-color:  ${getColor(bgColor, 'var(--color-gray)')};
            border-radius:5px;
            width: 22px;
            height: 22px;
            border:none;
            
            }
        & .ant-checkbox-checked .ant-checkbox-inner{
            background-color: ${getColor(bgColor, 'var(--color-primary)')}
        }

        & .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
            border:none;
        }
            
        & .ant-checkbox-checked::after{
            border:none;
        }
            `}
`;
