import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';
import _ from 'underscore';

import { downloadFile } from '@utils';

import { AddItemPhoto } from '../../store/actions';

import { downloadImg } from './utils';

import styles from './styles.module.scss';

export const PhotoTooltip = ({ className, urls, title, itemToEdit }) => {
    const prevUrls = useRef(urls);
    const dispatch = useDispatch();
    const [img, setImg] = useState(null);
    const [i, setI] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        if (!_.isEqual(prevUrls.current, urls)) {
            prevUrls.current = urls;

            setImg(null);
            setI(0);

            if (urls) {
                setImg(await downloadImg(urls?.[0]?.url, setLoading));
            }
        }
    }, [urls, prevUrls]);

    const onPhotoChange = async (currentI) => {
        setI(currentI);
        setImg(await downloadImg(urls[currentI].url, setLoading));
    };

    if (!urls?.length) {
        return null;
    }

    const onNewPhotosUpload = (event) => {
        const newPhotos = event.target.files;
        if (newPhotos.length) {
            const formData = new FormData();
            [...newPhotos].forEach((photo) => formData.append('images', photo));
            dispatch(AddItemPhoto(itemToEdit)({}, formData));
        }
    };
    return (
        <div className={`${styles.wrapper} ${className} ${loading ? styles.loading : ''}`}>
            <div className={styles.topLine}>
                <div>{title}</div>
                <div>
                    <img
                        onClick={() => {
                            if (i) {
                                setLoading(true);
                                onPhotoChange(Math.max(i - 1, 0));
                            }
                        }}
                        alt='icon'
                        src='/prev_icon.svg'
                    />
                    <div>{i + 1}</div>
                    <img
                        onClick={() => {
                            if (i < urls.length - 1) {
                                setLoading(true);
                                onPhotoChange(Math.min(i + 1, urls.length - 1));
                            }
                        }}
                        alt='icon'
                        src='/next_icon.svg'
                    />
                </div>
                <div className={styles.btnImgContainer}>
                    <label htmlFor='uploadItemPhoto'>
                        <img alt='icon' src='/import_icon.svg' />
                        Добавить
                    </label>
                    <Input type='file' multiple id='uploadItemPhoto' onChange={onNewPhotosUpload} />
                </div>
                <div
                    className={styles.btnImgContainer}
                    onClick={downloadFile({ url: urls[i]?.url, filename: 'scan.jpg' })}
                >
                    <img alt='icon' src='/save_icon.svg' />
                    Скачать
                </div>
            </div>
            {img && <img src={window.URL.createObjectURL(img)} alt='img' />}
            {!img && <div className={styles.img}>Фотография не найдена</div>}
        </div>
    );
};
