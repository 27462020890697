import { memo } from 'react';
import Viewer from 'react-viewer';
import moment from 'moment';

export function filteringByStatusList(list, status) {
    if (!status?.length) return list;
    const result = list.filter((i) => {
        // if (Number(status) === -100) return ![0, 2, 5, 8].includes(i.statusItem);
        if (Number(status) === -100) return i.statusItem === undefined;
        return Number(i.statusItem) === Number(status);
    });

    return result;
}

export const assetsRights = {
    manager: ['personnel_number', 'commentKA', 'name'],
    shop: [
        'personnel_number',
        'fact_start',
        'fact_end',
        'name',
        'estimation',
        'commentTT',
        'workerNoWork',
        'status',
    ],
    analytic: [],
    supermanager: [
        'createDate',
        'locationCode',
        'workers_required_for_service',
        'start_time',
        'end_time',
        'possible_workers',
        'personnel_number',
        'fact_start',
        'fact_end',
        'hours_worked',
        'name',
        'estimation',
        'commentTT',
        'workerNoWork',
        'status',
    ],
};

export function filteringList(list, search) {
    if (!search?.trim()) return list;
    const result = list.filter((i) => {
        const text = search.trim().toLocaleLowerCase();

        const oneEquel = (item) => {
            return String(item || '')
                .trim()
                .toLocaleLowerCase()
                .includes(text);
        };

        return (
            oneEquel(i.pk) ||
            oneEquel(i.service_name) ||
            oneEquel(i.addresses?.[0]?.text) ||
            oneEquel(`${i.personnel_number ? i.personnel_number : `ID${i.workerId}`}`) ||
            oneEquel(i.name)
        );
    });

    return result;
}

export const filterList = (list, filterColumns) => {
    if (list?.length === 0) return list;

    const arrKeys = [];

    for (const key in filterColumns) {
        const element = filterColumns[key];
        if (element?.length) {
            arrKeys.push(key);
        }
    }

    if (arrKeys?.length)
        return list.filter((i) => {
            let check = true;

            for (let index = 0; index < arrKeys.length; index++) {
                const key = arrKeys[index];
                const arr = filterColumns[key];

                if (key === 'pk') {
                    if (!arr.filter((x) => x.text === i.pk)?.length) {
                        check = false;
                        break;
                    }
                }

                if (key === 'date') {
                    if (
                        !arr.filter(
                            (x) => x.text === (i.date ? moment(i.date)?.format('DD.MM.YYYY') : null)
                        )?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'createDate') {
                    if (
                        !arr.filter(
                            (x) => x.text === moment(i.timestamp).format('DD.MM.YYYY HH:mm')
                        )?.length
                    ) {
                        check = false;
                        break;
                    }
                }
                if (key === 'percentKA') {
                    if (
                        !arr.filter(
                            (x) =>
                                x.text ===
                                (i?.avg_workers_score
                                    ? Number(i?.avg_workers_score)?.toFixed(2)
                                    : '')
                        )?.length
                    ) {
                        check = false;
                        break;
                    }
                }
                if (key === 'percentWorker') {
                    if (
                        !arr.filter((x) => x.text === Number(i?.score?.worker_score)?.toFixed(2))
                            ?.length
                    ) {
                        check = false;
                        break;
                    }
                }
                if (key === 'estimation') {
                    if (!arr.filter((x) => x.text === i?.score?.score)?.length) {
                        check = false;
                        break;
                    }
                }

                if (key === 'locationCode') {
                    if (!arr.filter((x) => x.text === i.location?.name)?.length) {
                        check = false;
                        break;
                    }
                }

                if (key === 'locationFormat') {
                    if (!arr.filter((x) => x.text === i.location?.type)?.length) {
                        check = false;
                        break;
                    }
                }

                if (key === 'locationAddress') {
                    if (!arr.filter((x) => x.text === i.location?.address)?.length) {
                        check = false;
                        break;
                    }
                }

                if (key === 'workers_required_for_service') {
                    if (!arr.filter((x) => x.text === i.service_name)?.length) {
                        check = false;
                        break;
                    }
                }
                if (key === 'start_time' || key === 'end_time') {
                    if (
                        !arr.filter((x) => x.text === moment(i[key], 'HH:mm:ss').format('HH:mm'))
                            ?.length
                    ) {
                        check = false;
                        break;
                    }
                }
                if (key === 'hours_worked_plan') {
                    if (
                        !arr.filter((x) => {
                            const startMinutes = moment(i.start_time, 'HH:mm:ss').minutes();
                            const startHours = moment(i.start_time, 'HH:mm:ss').hours();
                            const endMinutes = moment(i.end_time, 'HH:mm:ss').minutes();
                            const endHours = moment(i.end_time, 'HH:mm:ss').hours();

                            const startM = startHours * 60 + startMinutes;
                            const endM = endHours * 60 + endMinutes;

                            const f = endM - startM;
                            const s = endM + 1440 - startM;
                            let subtractM = endM - startM > 0 ? f : s;

                            if (subtractM > 390 && subtractM < 540) subtractM -= 30;
                            if (subtractM >= 540 && subtractM < 750) subtractM -= 60;
                            if (subtractM >= 750) subtractM -= 90;

                            const m = subtractM % 60;
                            const h = Math.floor(subtractM / 60);
                            const v = Number(h + m / 60).toFixed(1);

                            return String(x.text) === String(Number(v)?.toFixed(1));
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'possible_workers') {
                    if (
                        !arr.filter((x) => {
                            const possible_workers =
                                i.personnel_number ?? i.workerId
                                    ? `${
                                          i.personnel_number
                                              ? i.personnel_number
                                              : `ID${i.workerId}`
                                      }`
                                    : null;

                            const possible_workers_list = i.possible_workers?.filter(
                                (j) => j.worker_id !== i.workerId
                            );

                            const possible_worker =
                                possible_workers_list?.[possible_workers_list.length - 1];

                            const cansel = possible_worker
                                ? possible_worker?.personnel_number ||
                                  `ID${possible_worker?.worker_id}`
                                : possible_workers;

                            return x.text === cansel;
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'personnel_number') {
                    if (
                        !arr.filter((x) => {
                            return (
                                x.text ===
                                `${i.personnel_number ? i.personnel_number : `ID${i.workerId}`}`
                            );
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'fact_start') {
                    if (
                        !arr.filter((x) => {
                            return x.text === moment(i.start).format('HH:mm');
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'fact_end') {
                    if (
                        !arr.filter((x) => {
                            const hour = i?.hours_worked || 0;
                            const minutes = getMinutesWithDinner(
                                moment(i.start).format('HH:mm'),
                                moment(i.start).add('h', hour).format('HH:mm')
                            );

                            const end = moment(i.start)?.add('minutes', minutes);
                            const v = i.end ? moment(i.end) : end;

                            return x.text === v.format('HH:mm');
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'hours_worked') {
                    if (
                        !arr.filter((x) => {
                            return String(x.text) === String(Number(i.hours_worked)?.toFixed(1));
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'name') {
                    if (
                        !arr.filter((x) => {
                            return x.text === i.name;
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'cansel') {
                    if (
                        !arr.filter((x) => {
                            const cansel = typeof i.statusItem === 'number';
                            const v = `${cansel ? 'Нет' : 'Да'}`;
                            return x.text === v;
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }

                if (key === 'workerNoWork') {
                    if (
                        !arr.filter((x) => {
                            const cansel = i.possible_workers?.length
                                ? i.possible_workers.filter((j) => j.worker_id !== i.workerId)
                                      ?.length
                                : 0;
                            const v = `${cansel ? 'Да' : 'Нет'}`;
                            return x.text === v;
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }
                if (key === 'status') {
                    if (
                        !arr.filter((x) => {
                            return x.text === statuses?.[i.statusItem];
                        })?.length
                    ) {
                        check = false;
                        break;
                    }
                }
            }

            return check;
        });
    return list;
};

const getIndex = (item) => {
    if (item.status.id === 'finished' || item.status.id === 'cancelled_with_payment') {
        if (item.customer_resolution === 'confirmed') {
            return 2;
        }
        return 1;
    }
    return -1;
};

const getIndexApproveTitle = (item) => {
    if (item.status.id === 'finished' || item.status.id === 'cancelled_with_payment') {
        if (item.customer_resolution === 'confirmed') {
            return 2;
        }
        return 1;
    }
    if (item.customer_resolution === 'confirmed') {
        return 0;
    }

    return -1;
};

export function sortingList({ list, key, direction }) {
    if (list?.length === 0) return list;
    return list.sort((prev, next) => {
        let prevKey;
        let nextKey;

        switch (key) {
            case 'date':
                prevKey = prev[key].split('.').reverse().join('-');
                nextKey = next[key].split('.').reverse().join('-');
                break;

            case 'approve':
                prevKey = getIndex(prev);
                nextKey = getIndex(next);

                break;

            case 'approveTitle':
                prevKey = getIndexApproveTitle(prev);
                nextKey = getIndexApproveTitle(next);
                break;

            case 'createDate':
                prevKey = prev.timestamp ? moment(prev.timestamp).format('DD.MM.YYYY HH:mm') : null;
                nextKey = next.timestamp ? moment(next.timestamp).format('DD.MM.YYYY HH:mm') : null;
                break;

            case 'interval':
                prevKey = prev.addresses[0].interval;
                nextKey = next.addresses[0].interval;
                break;
            case 'route':
                prevKey = prev.addresses.length;
                nextKey = next.addresses.length;
                break;
            case 'locationAddress':
                prevKey = prev.location?.address;
                nextKey = next.location?.address;
                break;
            case 'locationCode':
                prevKey = prev.location?.name;
                nextKey = next.location?.name;
                break;
            case 'locationFormat':
                prevKey = prev.location?.type;
                nextKey = next.location?.type;
                break;

            case 'status':
                prevKey = prev.statusItem ?? -999;
                nextKey = next.statusItem ?? -999;
                break;
            case 'possible_workers': {
                const prev_possible_workers =
                    prev.personnel_number ?? prev.workerId
                        ? `${prev.personnel_number ? prev.personnel_number : prev.workerId - 9999}`
                        : null;

                const prev_possible_workers_list = prev.possible_workers?.filter(
                    (i) => i.worker_id !== prev.workerId
                );

                const prev_possible_worker =
                    prev_possible_workers_list?.[prev_possible_workers_list.length - 1];

                const prev_cansel = prev_possible_worker
                    ? prev_possible_worker?.personnel_number ||
                      prev_possible_worker.worker_id - 9999
                    : prev_possible_workers;

                const next_possible_workers =
                    next.personnel_number ?? next.workerId
                        ? `${next.personnel_number ? next.personnel_number : next.workerId - 9999}`
                        : null;

                const next_possible_workers_list = next.possible_workers?.filter(
                    (i) => i.worker_id !== next.workerId
                );

                const next_possible_worker =
                    next_possible_workers_list?.[next_possible_workers_list.length - 1];

                const next_cansel = next_possible_worker
                    ? next_possible_worker?.personnel_number ||
                      next_possible_worker.worker_id - 9999
                    : next_possible_workers;

                prevKey = prev_cansel ? Number(prev_cansel) : -9999;
                nextKey = next_cansel ? Number(next_cansel) : -9999;
                break;
            }
            case 'personnel_number': {
                prevKey =
                    prev.personnel_number ?? prev.workerId
                        ? Number(
                              `${
                                  prev.personnel_number
                                      ? prev.personnel_number
                                      : prev.workerId - 9999
                              }`
                          )
                        : -9999;
                nextKey =
                    next.personnel_number ?? next.workerId
                        ? Number(
                              `${
                                  next.personnel_number
                                      ? next.personnel_number
                                      : next.workerId - 9999
                              }`
                          )
                        : -9999;
                break;
            }
            case 'start_time':
            case 'end_time':
                prevKey = prev?.[key] ? moment(prev?.[key], 'HH:mm:ss').format('x') : -1;
                nextKey = next?.[key] ? moment(next?.[key], 'HH:mm:ss').format('x') : -1;
                break;

            case 'fact_start':
                prevKey = prev?.start
                    ? moment(prev.start.split('T')[1], 'HH:mm:ss').format('x')
                    : -1;
                nextKey = next?.start
                    ? moment(next.start.split('T')[1], 'HH:mm:ss').format('x')
                    : -1;
                break;

            case 'fact_end':
                prevKey =
                    prev?.start && prev?.hours_worked
                        ? moment(prev.end.split('T')[1], 'HH:mm:ss').format('x')
                        : -1;
                nextKey =
                    next?.start && next?.hours_worked
                        ? moment(next.end.split('T')[1], 'HH:mm:ss').format('x')
                        : -1;
                break;

            case 'hours_worked':
                prevKey = prev?.[key] ? Number(parseFloat(prev?.[key])?.toFixed(1)) : -1;
                nextKey = next?.[key] ? Number(parseFloat(next?.[key])?.toFixed(1)) : -1;
                break;
            case 'comment':
                prevKey = prev[key].length;
                nextKey = next[key].length;
                break;

            case 'name':
                prevKey = prev?.[key] || 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ';
                nextKey = next?.[key] || 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ';
                break;
            case 'cansel':
                prevKey = typeof prev.statusItem === 'number' ? -1 : 1;
                nextKey = typeof next.statusItem === 'number' ? -1 : 1;
                break;
            case 'workerNoWork': {
                const canselPrev = prev.possible_workers?.length
                    ? !!prev.possible_workers.filter((j) => j.worker_id !== prev.workerId)?.length
                    : 0;
                const canselNext = next.possible_workers?.length
                    ? !!next.possible_workers.filter((j) => j.worker_id !== next.workerId)?.length
                    : 0;
                prevKey = canselPrev ? 1 : -1;
                nextKey = canselNext ? 1 : -1;
                break;
            }

            case 'hours_worked_plan': {
                const vPrev = getHoursWithDinner(
                    moment(prev.start_time, 'HH:mm:ss'),
                    moment(prev.end_time, 'HH:mm:ss')
                );
                const vNext = getHoursWithDinner(
                    moment(next.start_time, 'HH:mm:ss'),
                    moment(next.end_time, 'HH:mm:ss')
                );
                prevKey =
                    prev.start_time && prev.end_time ? Number(parseFloat(vPrev)?.toFixed(1)) : -1;
                nextKey =
                    next.start_time && next.end_time ? Number(parseFloat(vNext)?.toFixed(1)) : -1;
                break;
            }

            case 'percentWorker': {
                prevKey = prev?.score?.worker_score
                    ? Number(Number(prev?.score?.worker_score).toFixed(2))
                    : -1;
                nextKey = next?.score?.worker_score
                    ? Number(Number(next?.score?.worker_score).toFixed(2))
                    : -1;
                break;
            }

            case 'percentKA': {
                prevKey = 0;
                nextKey = 0;

                prevKey = prev?.avg_workers_score
                    ? Number(Number(prev?.avg_workers_score).toFixed(2))
                    : -1;
                nextKey = next?.avg_workers_score
                    ? Number(Number(next?.avg_workers_score).toFixed(2))
                    : -1;
                break;
            }

            default:
                prevKey = prev[key];
                nextKey = next[key];
        }

        const value = direction === 'up' ? 1 : -1;
        return prevKey < nextKey ? value : -value;
    });
}

export const initColumns = {
    pk: { text: '№', isVisible: true, style: { maxWidth: '120px' } },
    approve: { text: 'ЛУВР подтвержден', isVisible: true, style: { maxWidth: '240px' } },
    // approveTitle: { text: 'ЛУВР подтвержден', isVisible: true, style: { maxWidth: '90px' } },

    createDate: { text: 'Время создания заявки', isVisible: true, style: { minWidth: '130px' } },
    // route: { text: 'Маршрут', isVisible: false, style: { maxWidth: 'none' } },
    date: { text: 'дата заявки', isVisible: true, style: { maxWidth: '120px' } },
    locationCode: {
        text: 'код ТТ',
        isVisible: true,
        style: { minWidth: '120px' },
    },
    locationFormat: {
        text: 'формат ТТ',
        isVisible: true,
        style: { minWidth: '90px', maxWidth: '120px' },
    },
    locationAddress: {
        text: 'адрес ТТ',
        isVisible: true,
        style: { minWidth: '80px', maxWidth: '120px' },
    },
    // location: { text: 'Объект', isVisible: true, style: { maxWidth: '120px' } },

    workers_required_for_service: {
        text: 'вид работы',
        isVisible: true,
        style: { maxWidth: '120px' },
    },
    comment: { text: 'комментарий', isVisible: true, style: { maxWidth: '140px' } },
    // addresses: { text: 'Адрес', isVisible: true, style: { maxWidth: '300px' } },
    // executants: { text: 'Исп-лей', isVisible: true, style: { maxWidth: '120px' } },
    start_time: { text: 'начало, план', isVisible: true, style: { width: '50px' } },
    end_time: { text: 'конец, план', isVisible: true, style: { width: '200px' } },
    hours_worked_plan: { text: 'часы, план', isVisible: true, style: { width: '45px' } },

    possible_workers: { text: 'ТБ из заявки', isVisible: true, style: { maxWidth: '120px' } },
    personnel_number: { text: 'ТБ по факту', isVisible: true, style: { maxWidth: '120px' } },

    fact_start: { text: 'начало, факт', isVisible: true, style: { width: '50px' } },
    fact_end: { text: 'конец, факт', isVisible: true, style: { width: '200px' } },
    hours_worked: { text: 'часы, факт', isVisible: true, style: { width: '45px' } },
    name: { text: 'Ф.И.О.', isVisible: true, style: { minWidth: '220px' } },

    estimation: {
        text: 'оценка оказанных услуг',
        isVisible: true,
        style: { minWidth: '140px', maxWidth: '140px' },
    },
    commentTT: {
        text: 'примечание от ТТ',
        isVisible: true,
        style: { maxWidth: '140px' },
    },
    commentKA: { text: 'примечание от КА', isVisible: true, style: { maxWidth: '140px' } },
    percentWorker: {
        text: '% уровня сервиса сотрудника',
        isVisible: true,
        style: { minWidth: '140px' },
    },
    cansel: { text: 'отмена заявки', isVisible: true, style: { width: '120px' } },
    percentKA: { text: '% уровня сервиса КА', isVisible: true, style: { minWidth: '100px' } },
    workerNoWork: { text: 'cотрудник не вышел', isVisible: true, style: { maxWidth: 'none' } },

    status: { text: 'статус', isVisible: true, style: { maxWidth: 'none' } },

    // index: { text: 'Индекс', isVisible: false, style: { maxWidth: '120px' } },
    // expected_hours_max: {
    //     text: 'Время вып-ния',
    //     isVisible: true,
    //     style: { maxWidth: 'none' },
    // },
    // expected_total_hours: {
    //     text: 'Сум-ное кол-во ч.',
    //     isVisible: true,
    //     style: { maxWidth: 'none' },
    // },
    // shipment_type: { text: 'Груз', isVisible: false, style: { maxWidth: '120px' } },
    // mass: { text: 'Масса', isVisible: false, style: { maxWidth: 'none' } },
    // volume: { text: 'Объем', isVisible: false, style: { maxWidth: 'none' } },
    // max_size: { text: 'Г-т', isVisible: false, style: { maxWidth: 'none' } },
    // has_elevator: { text: 'Лифт', isVisible: false, style: { maxWidth: 'none' } },
    // floor: { text: 'Этаж', isVisible: false, style: { maxWidth: 'none' } },
    // carrying_distance: { text: 'Пронос', isVisible: false, style: { maxWidth: 'none' } },
    // places: { text: 'Мест', isVisible: false, style: { maxWidth: 'none' } },
    // interval: { text: 'Интервал', isVisible: true, style: { maxWidth: '120px' } },
    // time: { text: 'Подача', isVisible: true, style: { maxWidth: '120px' } },
    // confirmed_timepoint: { text: 'Сог-е время', isVisible: true, style: { maxWidth: '120px' } },
    // contact: { text: 'Контактное лицо', isVisible: false, style: { maxWidth: 'none' } },
    // phone: { text: 'Телефон', isVisible: false, style: { maxWidth: '150px' } },
    // price: { text: 'Стоимость', isVisible: true, style: { maxWidth: '120px' } },
};

export const WorkerItemStatus = {
    Undefined: -100,
    Removed: -4,
    Failed: -3,
    Defect: -2,
    Cancelled: -1,
    New: 0,
    StartPhotoRejected: 1,
    RequestConfirmedByWorker: 2,
    SuspiciousAddress: 3,
    StartChecking: 4,
    StartConfirmed: 5,
    FinishPhotoRejected: 6,
    FinishChecking: 7,
    Completed: 8,
};

export const changedFields = ['status', 'comment', 'hours_worked'];

export const filterMap = (filter) => {
    switch (filter) {
        case 'Несогласованные заявки':
            return 'active';
        case 'Заявки в оплате':
            return 'in_payment';
        case 'Оплаченные заявки':
            return 'paid';
        default:
            return null;
    }
};

// todo
export const statusAllowCancel = [
    'driver_callback',
    'no_response',
    'partly_confirmed',
    'partly_arrived',
    'partly_photo_attached',
    'photo_attached',
];

export const customerResolution = [
    { title: 'Согласована', value: 'confirmed' },
    { title: 'На рассмотрении', value: 'normal' },
    { title: 'Подозрительная', value: 'suspicious' },
];

export const currencyFormat = (num) => {
    return num?.toFixed(1)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

export const statusInfo = {
    autotarification_attempt: {
        title: 'Тарифицируется',
        class: 'status-autotarification_attempt',
    },
    new: { title: 'Новая', class: 'status-new', show: true },
    timepoint_confirmed: { title: 'Поиск исполнителя', class: 'status-timepoint_confirmed' },
    partly_confirmed: { title: 'Назначен', class: 'status-partly_confirmed' },

    partly_arrived: { title: 'В пути', class: 'status-partly_arrived' },
    partly_photo_attached: { title: 'На месте', class: 'status-partly_photo_attached' },
    photo_attached: { title: 'Проверка табеля', class: 'status-photo_attached' },
    finished: { title: 'Выполнена', class: 'status-finished' },

    no_response: { title: 'Нет ответа', class: 'status-no_response' },
    driver_callback: { title: 'Перезвонит сам', class: 'status-driver_callback' },

    declined: { title: 'Не принята', class: 'status-declined' },
    cancelled: { title: 'Отмена', class: 'status-cancelled' },
    removed: { title: 'Удалена', class: 'status-removed', show: true },
    failed: { title: 'Срыв заявки', class: 'status-failed', show: true },
    cancelled_with_payment: {
        title: 'Отмена с оплатой',
        class: 'status-cancelled_with_payment',
    },
};

export const statusesFullForSelect = {
    '-100': { title: 'Неопределен', class: 'status-removed', show: true },
    0: { title: 'Новая', class: 'status-new', show: true },
    2: { title: 'Подтвержден на заявке', class: 'status-driver_callback' },
    5: { title: 'Подтверждён на адресе', class: 'status-driver_callback' },
    8: { title: 'Выполнена', class: 'status-finished' },
};

export const statusesFull = {
    '-100': { title: 'Неопределен', class: 'status-removed', show: true },
    '-4': { title: 'Удалена', class: 'status-removed', show: true },
    '-3': { title: 'Срыв заявки', class: 'status-failed', show: true },
    '-2': { title: 'Брак', class: 'status-failed', show: true },
    '-1': { title: 'Отмена', class: 'status-cancelled' },
    0: { title: 'Новая', class: 'status-new', show: true },
    1: { title: 'Селфи отклонено', class: 'status-failed', show: true },
    2: { title: 'Подтверждён', class: 'status-driver_ok' },
    3: { title: 'Подозрительный адрес', class: 'status-failed', show: true },
    4: { title: 'Проверка адреса', class: 'status-new', show: true },
    5: { title: 'Пришёл', class: 'status-driver_callback' },
    6: { title: 'Накладная отклонена', class: 'status-declined' },
    7: { title: 'Накладная', class: 'status-driver_callback' },
    8: { title: 'Выполнена', class: 'status-finished' },
};

export const statuses = {
    '-100': 'Неопределен',
    '-4': 'Удален',
    '-3': 'Срыв',
    '-2': 'Брак',
    '-1': 'Отмена',
    0: 'Новая',
    1: 'Селфи отклонено',
    2: 'Подтверждён',
    3: 'Подозрительный адрес',
    4: 'Проверка адреса',
    5: 'Пришёл',
    6: 'Накладная отклонена',
    7: 'Накладная',
    8: 'Выполнена',
};

export const ImageViewer = memo((props) => {
    return (
        <Viewer
            images={props.images.map((src) => ({ src }))}
            activeIndex={props.activeImageIndex}
            container={props.container}
            noNavbar
            noClose={false}
            attribute={false}
            drag
            noImgDetails
            zoomSpeed={0.15}
            visible
            defaultScale={1}
            changeable={false}
            className={props.className}
            disableKeyboardSupport
            noResetZoomAfterChange
            onClose={props.onClose}
        />
    );
});

export const getMinutesWithDinner = (start, end) => {
    const startMinutes = moment(start, 'HH:mm').minutes();
    const startHours = moment(start, 'HH:mm').hours();
    const endMinutes = moment(end, 'HH:mm').minutes();
    const endHours = moment(end, 'HH:mm').hours();

    const startM = startHours * 60 + startMinutes;
    const endM = endHours * 60 + endMinutes;

    const f = endM - startM;
    const s = endM + 1440 - startM;
    let subtractM = endM - startM > 0 ? f : s;

    if (subtractM > 390 && subtractM < 540) subtractM -= 30;
    if (subtractM >= 540 && subtractM < 750) subtractM -= 60;
    if (subtractM >= 750) subtractM -= 90;
    return subtractM;
};

export const getHoursWithDinner = (start_time, end_time) => {
    const startMinutes = moment(start_time, 'HH:mm').minutes();
    const startHours = moment(start_time, 'HH:mm').hours();
    const endMinutes = moment(end_time, 'HH:mm').minutes();
    const endHours = moment(end_time, 'HH:mm').hours();

    const startM = startHours * 60 + startMinutes;
    const endM = endHours * 60 + endMinutes;

    const f = endM - startM;
    const s = endM + 1440 - startM;
    let subtractM = f > 0 ? f : s;

    if (subtractM > 390 && subtractM < 540) subtractM -= 30;
    if (subtractM >= 540 && subtractM < 750) subtractM -= 60;
    if (subtractM >= 750) subtractM -= 90;

    const m = subtractM % 60;
    const h = Math.floor(subtractM / 60);
    const v = h + m / 60;

    return Number(v).toFixed(1);
};

export const getHoursWithoutDinner = (start_time, end_time) => {
    const startMinutes = moment(start_time, 'HH:mm').minutes();
    const startHours = moment(start_time, 'HH:mm').hours();
    const endMinutes = moment(end_time, 'HH:mm').minutes();
    const endHours = moment(end_time, 'HH:mm').hours();

    const startM = startHours * 60 + startMinutes;
    const endM = endHours * 60 + endMinutes;

    const f = endM - startM;
    const s = endM + 1440 - startM;
    const subtractM = endM - startM > 0 ? f : s;

    const m = subtractM % 60;
    const h = Math.floor(subtractM / 60);
    const v = h + m / 60;

    return Number(v).toFixed(1);
};

export const getDataFilter = (value = '', data, c) => {
    let result = [];

    if (c === 'pk') {
        result = data
            .map((i) => ({
                text: i.pk,
                id: i.pk,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'date') {
        result = data
            .filter((i) => !!i[c])
            .map((i) => ({
                text: moment(i[c])?.format('DD.MM.YYYY'),
                id: moment(i[c])?.format('DD.MM.YYYY'),
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'percentKA') {
        result = data
            .map((i) => ({
                text: i?.avg_workers_score ? Number(i?.avg_workers_score).toFixed(2) : undefined,
                id: i?.avg_workers_score ? Number(i?.avg_workers_score).toFixed(2) : undefined,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'percentWorker') {
        result = data
            .map((i) => ({
                text: i?.score?.worker_score
                    ? Number(i?.score?.worker_score)?.toFixed(2)
                    : undefined,
                id: i?.score?.worker_score,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'estimation') {
        result = data
            .map((i) => ({
                text: i?.score?.score,
                id: i?.score?.score,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'createDate') {
        result = data
            .map((i) => ({
                text: moment(i.timestamp).format('DD.MM.YYYY HH:mm'),
                id: moment(i.timestamp).format('DD.MM.YYYY HH:mm'),
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'addresses') {
        result = data
            .map((i) => ({
                text: i.addresses?.[0]?.text,
                id: i.addresses?.[0]?.id,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'status') {
        result = data
            .map((i) => ({
                text: statuses?.[i.statusItem],
                id: statuses?.[i.statusItem],
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }
    if (c === 'location') {
        result = data
            .map((i) => ({
                text: i.location?.name,
                id: i.location?.id,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'locationAddress') {
        result = data
            .map((i) => ({
                text: i.location?.address,
                id: i.location?.id,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }
    if (c === 'locationCode') {
        result = data
            .map((i) => ({
                text: i.location?.name,
                id: i.location?.id,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }
    if (c === 'locationFormat') {
        result = data
            .map((i) => ({
                text: i.location?.type,
                id: i.location?.id,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'workers_required_for_service') {
        result = data
            .map((i) => ({
                text: i?.service_name,
                id: i?.service_id,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'name') {
        result = data
            .map((i) => ({
                text: i?.name,
                id: i?.workerId,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'personnel_number') {
        result = data
            .filter((i) => !!i.personnel_number || !!i.workerId)
            .map((i) => ({
                text: `${i.personnel_number ? i.personnel_number : `ID${i.workerId}`}`,
                id: i?.workerId,
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text)) return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'start_time' || c === 'end_time') {
        result = data
            .filter((i) => !!i[c])
            .map((i) => ({
                text: moment(i[c], 'HH:mm:ss').format('HH:mm'),
                id: moment(i[c], 'HH:mm:ss').format('HH:mm'),
            }))
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text)) return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'hours_worked_plan') {
        result = data
            .filter((i) => !!i.start_time && i.end_time)
            .map((i) => {
                const startMinutes = moment(i.start_time, 'HH:mm:ss').minutes();
                const startHours = moment(i.start_time, 'HH:mm:ss').hours();
                const endMinutes = moment(i.end_time, 'HH:mm:ss').minutes();
                const endHours = moment(i.end_time, 'HH:mm:ss').hours();

                const startM = startHours * 60 + startMinutes;
                const endM = endHours * 60 + endMinutes;

                const f = endM - startM;
                const s = endM + 1440 - startM;
                let subtractM = endM - startM > 0 ? f : s;

                if (subtractM > 390 && subtractM < 540) subtractM -= 30;
                if (subtractM >= 540 && subtractM < 750) subtractM -= 60;
                if (subtractM >= 750) subtractM -= 90;

                const m = subtractM % 60;
                const h = Math.floor(subtractM / 60);
                const v = Number(h + m / 60).toFixed(1);

                return {
                    text: v,
                    id: v,
                };
            })
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text)) return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'possible_workers') {
        result = data
            .filter((i) => !!i[c]?.length)
            .map((i) => {
                const possible_workers =
                    i.personnel_number ?? i.workerId
                        ? `${i.personnel_number ? i.personnel_number : `ID${i.workerId}`}`
                        : null;

                const possible_workers_list = i.possible_workers?.filter(
                    (j) => j.worker_id !== i.workerId
                );

                const possible_worker = possible_workers_list?.[possible_workers_list.length - 1];

                const cansel = possible_worker
                    ? possible_worker?.personnel_number || `ID${possible_worker?.worker_id}`
                    : possible_workers;

                return {
                    text: cansel,
                    id: cansel,
                };
            })
            .filter((i) => !!i.text)
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text)) return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'fact_start') {
        result = data
            .filter((i) => !!i.start)
            .map((i) => {
                return {
                    text: moment(i.start).format('HH:mm'),
                    id: moment(i.start).format('HH:mm'),
                };
            })
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text)) return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'fact_end') {
        result = data
            .filter((i) => !!i.hours_worked && i.itemworker_id && i.start)
            .map((i) => {
                const hour = i?.hours_worked || 0;
                const minutes = getMinutesWithDinner(
                    moment(i.start).format('HH:mm'),
                    moment(i.start).add('h', hour).format('HH:mm')
                );

                const end = moment(i.start)?.add('minutes', minutes);
                const v = i.end ? moment(i.end) : end;
                return {
                    text: v.format('HH:mm'),
                    id: v.format('HH:mm'),
                };
            })
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text)) return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'start_time' || c === 'end_time') {
        const _value = value?.trim()?.match(/\d/g)?.join('') ?? '';

        return result?.filter((i) =>
            _value?.trim()
                ? String(i.text)
                      ?.trim()
                      ?.toLocaleLowerCase()
                      .includes(_value?.trim()?.toLocaleLowerCase())
                : i
        );
    }
    if (c === 'hours_worked') {
        result = data
            .filter((i) => !!i[c])
            .map((i) => {
                return {
                    text: i[c] ? Number(i[c]).toFixed(1) : i[c],
                    id: i[c] ? Number(i[c]).toFixed(1) : i[c],
                };
            })
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'cansel') {
        result = data
            .map((i) => {
                const cansel = typeof i.statusItem === 'number';
                const v = `${cansel ? 'Нет' : 'Да'}`;
                return {
                    text: v,
                    id: v,
                };
            })
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'workerNoWork') {
        result = data
            .map((i) => {
                const cansel = i.possible_workers?.length
                    ? i.possible_workers.filter((j) => j.worker_id !== i.workerId)?.length
                    : 0;
                const v = `${cansel ? 'Да' : 'Нет'}`;
                return {
                    text: v,
                    id: v,
                };
            })
            .reduce((prev, i) => {
                if (!prev.find((j) => i.text === j.text) && !!i.text && i.text !== '-')
                    return [...prev, i];
                return prev;
            }, []);
    }

    if (c === 'date' || c === 'fact_start' || c === 'fact_end') {
        const _value = value?.trim()?.match(/\d/g)?.join('') ?? '';

        return result?.filter((i) =>
            _value?.trim()
                ? String(i.text)
                      ?.trim()
                      ?.toLocaleLowerCase()
                      .includes(_value?.trim()?.toLocaleLowerCase())
                : i
        );
    }
    return result?.filter((i) =>
        value?.trim()
            ? String(i.text)
                  ?.trim()
                  ?.toLocaleLowerCase()
                  .includes(value?.trim()?.toLocaleLowerCase())
            : i
    );
};
