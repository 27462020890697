import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, message, Select, Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import { createCard, fetchExistingCards } from '@store/actions';

import { CardDataInput } from './CardDataInput';

import styles from './CreateRequest.module.scss';

export const Requisites = ({ onFinish, onBack }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { data: cardList } = useSelector((state) => state.cards);
    const [showAddCard, setShowAddCard] = useState(true);

    const onAddCard = async (onFinishValues) => {
        try {
            await dispatch(
                createCard(
                    {},
                    {
                        cardholder_name: onFinishValues.cardholderName,
                        number: onFinishValues.cardNumber.replaceAll(' ', ''),
                        expiration_date: onFinishValues.expiryDate,
                        cvv: onFinishValues.cvv,
                    }
                )
            );
            await dispatch(fetchExistingCards({}, {}));
            return true;
        } catch (e) {
            if (e.error?.response?.data?.detail) message.error(e.error.response.data.detail);
            else message.error('Ошибка при добавлении карты');
            return false;
        }
    };

    useEffect(async () => {
        if (cardList.length) setShowAddCard(false);
        else setShowAddCard(true);
    }, [cardList]);

    return (
        <Form form={form} layout='vertical' className={styles.requisitesForm}>
            <div className={styles.requisites}>
                <Space direction='vertical'>
                    <Text className={styles.requisitesTitle}>Реквизиты</Text>
                    <Text className={styles.requisitesTitleSecondary} hidden={cardList.length}>
                        Для создания заявки вам необходимо пополнить баланс либо добавить данные
                        банковской карты. Они будут сохранены в базе данных и будут использоваться
                        для взаиморасчётов.
                    </Text>
                    <Text className={styles.requisitesTitleSecondary} hidden={!cardList.length}>
                        Для создания заявки вам необходимо пополнить баланс либо выбрать карту из
                        списка. Она будет использоваться для взаиморасчётов.
                    </Text>
                </Space>
                <Space direction='vertical' className={styles.cardData}>
                    <Form.Item label='Выберите карту' name='card' rules={[{ required: true }]}>
                        <Select
                            className={styles.cardInput}
                            placeholder='Выбрать'
                            dropdownClassName={styles.cardDropdown}
                            dropdownStyle={{ width: '100%' }}
                            onChange={(value) => {
                                if (value === 'new') setShowAddCard(true);
                                else {
                                    setShowAddCard(false);
                                }
                            }}
                        >
                            {cardList.map((card) => (
                                <Select.Option
                                    key={card.id}
                                    value={card.id}
                                    className={styles.cardOption}
                                >
                                    <div className={styles.cardOptionContainer}>
                                        <div className={styles.cardOptionNumber}>{card.number}</div>
                                        <div className={styles.cardOptionDate}>
                                            {card.expiration_date}
                                        </div>
                                    </div>
                                </Select.Option>
                            ))}
                            <Select.Option key='new' value='new' className={styles.cardOption}>
                                <div className={styles.cardOptionContainer}>
                                    <div className={styles.cardOptionNumber}>
                                        Добавить новую карту
                                    </div>
                                </div>
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Space>
                {showAddCard && <CardDataInput styles={styles} />}
                <Space direction='vertical' className={styles.infoData}>
                    <span className={`${styles.cardInfo} ${styles.listTitle}`}>
                        Правила и условия
                    </span>
                    <ol className={styles.cardInfo}>
                        <li>
                            Привязывая карту вы соглашаетесь с условиями пользования сервисом и
                            публичной офертой
                        </li>
                        <li>С вашей карты будет списан 1₽ для проверки</li>
                    </ol>
                </Space>
                <Space direction='horizontal' className={styles.footer}>
                    <Button className={styles.skipButton} type='ghost' onClick={onFinish}>
                        Пропустить
                    </Button>
                    <Button className={styles.backButton} type='default' onClick={onBack}>
                        Назад
                    </Button>
                    <Button
                        className={styles.nextButton}
                        onClick={() => {
                            if (showAddCard) {
                                onAddCard(form.getFieldsValue()).then(
                                    (result) => result && onFinish()
                                );
                            } else onFinish();
                        }}
                    >
                        Далее
                    </Button>
                </Space>
            </div>
        </Form>
    );
};
