import { REQUEST_GROUP_NAMES } from '@utils';

import styles from './styles.module.scss';

const getPercent = (count, total) => (total ? Math.round((count / total) * 100) : 0);

export const DashboardGroup = ({ title, total, itemTotal }) => {
    const scrollBarWidthStyle = { width: `${getPercent(total, itemTotal)}%` };

    return (
        <div className={`${styles.element} ${styles[title]}`}>
            <div className={styles.label}>
                <span>{REQUEST_GROUP_NAMES[title].title}</span>
                <span>
                    <b>{total}</b>
                </span>
            </div>

            <div className={styles.loader}>
                <div className={styles.persent} style={scrollBarWidthStyle} />
            </div>
        </div>
    );
};
