import { BACKEND_URL } from '@utils';

import styles from './styles.module.scss';

export const DownloadExcelButton = ({ disabled }) => {
    return (
        <div
            onClick={() => {
                if (!disabled)
                    window.open(`${BACKEND_URL}static/files/delivery/delivery_template.xlsx?v=5`);
            }}
            className={styles.buttonsContainerItem}
        >
            <img alt='icon' src='/save_xls_icon.svg' className={styles.buttonsContainerIcon3} />
            <div />
        </div>
    );
};
