import { memo } from 'react';
import Viewer from 'react-viewer';

export const initColumns = {
    pk: { text: '№', isVisible: true, style: { maxWidth: '120px' } },
    date: { text: 'Дата', isVisible: true, style: { maxWidth: '120px' } },
    workers_required_for_service: {
        text: 'Услуги',
        isVisible: true,
        style: { maxWidth: '120px' },
    },
    index: { text: 'Индекс', isVisible: false, style: { maxWidth: '120px' } },
    route: { text: 'Маршрут', isVisible: false, style: { maxWidth: 'none' } },
    expected_hours_max: {
        text: 'Время вып-ния',
        isVisible: true,
        style: { maxWidth: 'none' },
    },
    expected_total_hours: {
        text: 'Сум-ное кол-во ч.',
        isVisible: true,
        style: { maxWidth: 'none' },
    },
    addresses: { text: 'Адрес', isVisible: true, style: { maxWidth: '300px' } },
    shipment_type: { text: 'Груз', isVisible: false, style: { maxWidth: '120px' } },
    mass: { text: 'Масса', isVisible: false, style: { maxWidth: 'none' } },
    volume: { text: 'Объем', isVisible: false, style: { maxWidth: 'none' } },
    max_size: { text: 'Г-т', isVisible: false, style: { maxWidth: 'none' } },
    has_elevator: { text: 'Лифт', isVisible: false, style: { maxWidth: 'none' } },
    floor: { text: 'Этаж', isVisible: false, style: { maxWidth: 'none' } },
    carrying_distance: { text: 'Пронос', isVisible: false, style: { maxWidth: 'none' } },
    places: { text: 'Мест', isVisible: false, style: { maxWidth: 'none' } },
    interval: { text: 'Интервал', isVisible: true, style: { maxWidth: '120px' } },
    time: { text: 'Подача', isVisible: true, style: { maxWidth: '120px' } },
    confirmed_timepoint: { text: 'Сог-е время', isVisible: true, style: { maxWidth: '120px' } },
    arrival_time: { text: 'Время начала', isVisible: true, style: { maxWidth: '120px' } },
    executants: { text: 'Исп-лей', isVisible: true, style: { maxWidth: '120px' } },
    contact: { text: 'Контактное лицо', isVisible: false, style: { maxWidth: 'none' } },
    phone: { text: 'Телефон', isVisible: false, style: { maxWidth: '150px' } },
    price: { text: 'Стоимость', isVisible: true, style: { maxWidth: '120px' } },
    hours: { text: 'Часы', isVisible: true, style: { maxWidth: '120px' } },
    approve: { text: 'Согласовать', isVisible: true, style: { maxWidth: '240px' } },
    status: { text: 'Статус', isVisible: true, style: { maxWidth: 'none' } },
    comment: { text: 'Комментарий', isVisible: true, style: { maxWidth: '240px' } },
    customer_comment: {
        text: 'Комментарий клиента',
        isVisible: true,
        style: { maxWidth: '240px' },
    },
};

export const ImageViewer = memo((props) => {
    return (
        <Viewer
            images={props.images.map((src) => ({ src }))}
            activeIndex={props.activeImageIndex}
            container={props.container}
            noNavbar
            noClose={false}
            attribute={false}
            drag
            noImgDetails
            zoomSpeed={0.15}
            visible
            defaultScale={1}
            changeable={false}
            className={props.className}
            disableKeyboardSupport
            noResetZoomAfterChange
            onClose={props.onClose}
        />
    );
});
