import {
    Fragment,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Button, Dropdown, Input, Menu, message, Modal, Space } from 'antd';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'underscore';

import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import {
    ButtonsContainer,
    // CustomSelectWithStorage,
    DatePickerApply,
    DownloadExcelButton,
    ImportsButton,
    Pagination,
    PopupInfo,
    PopupModal,
    SearchWrapper,
    TableRequestsWrapper,
    ViewButton,
    Wrapper,
} from '@components';
import {
    // fetchLocation,
    fetchRequests,
    setLastStateSearch,
    updateAddressOptions,
    updateSlotRequest,
} from '@store/actions';
import {
    authorization,
    BACKEND_URL,
    ITEMS_ON_PAGE,
    REQUESTS2_PREFIX,
    storageService,
} from '@utils';

import { TableCell } from './TableCell';
import {
    changedFields,
    filteringList,
    filterList,
    filterMap,
    getDataFilter,
    initColumns,
    sortingList,
    WorkerItemStatus,
} from './utils';

import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

const localStorage2Service = storageService(REQUESTS2_PREFIX);

const initFilterColumns = {
    pk: [],
    createDate: [],
    date: [],
    locationCode: [],
    locationFormat: [],
    locationAddress: [],
    workers_required_for_service: [],
    comment: [],
    start_time: [],
    end_time: [],
    hours_worked_plan: [],
    possible_workers: [],
    personnel_number: [],
    fact_start: [],
    fact_end: [],
    hours_worked: [],
    name: [],
    estimation: [],
    commentTT: [],
    commentKA: [],
    percentWorker: [],
    cansel: [],
    percentKA: [],
    workerNoWork: [],
    status: [],
};

export const Requests2Page = () => {
    const {
        filterStatus,
        requestsList,
        allow_requests_creation,
        // show_locations_filter,
    } = useSelector((state) => ({
        requestsList: state.requests.list,
        dashboard: state.requests.dashboard,
        filterStatus: state.requests.filterStatus,
        allow_requests_creation: state.user.info.allow_requests_creation,
        // show_locations_filter: state.user.info.show_locations_filter,
    }));

    const [rowDataTable, setRowDataTable] = useState(undefined);
    const [rowDataFilter, setRowDataFilter] = useState(undefined);

    const [sorting, setSorting] = useState(
        localStorage2Service.get('sorting', { key: 'date', direction: 'up' })
    );

    const [filterColumns, setFilterColumns] = useState(
        localStorage2Service.get('filterColumns', initFilterColumns)
    );

    useEffect(() => {
        localStorage2Service.set('filterColumns', filterColumns);
    }, [filterColumns]);

    const dispatch = useDispatch();
    const history = useHistory();

    const [itemsOnPage, setItemsOnPage] = useState(
        localStorage2Service.get('itemsOnPage', ITEMS_ON_PAGE)
    );
    // const [branch, setBranch] = useState([]);

    const [columns, setColumns] = useState({
        ...initColumns,
        ...(JSON.parse(localStorage.getItem('columns2')) || {}),
    });

    const [startId, setStartId] = useState(undefined);
    const [endId, setEndId] = useState(undefined);
    const [keyName, setKeyName] = useState(undefined);

    const [addressPopupOpenedId] = useState(-1);
    const [addressPopupInput, setAddressPopupInput] = useState('');
    const [_addressId, setAddressId] = useState(-1);
    const [_addressOptions, setAddressOptions] = useState([]);

    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [modalPopup, setModalPopup] = useState({ open: false, content: '' });

    const [mouseDown, setMouseDown] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [clearSelectedRowTriger, clearSelectedRow] = useReducer((c) => c + 1, 1);

    const [search, setSearch] = useState(localStorage2Service.get('search') ?? '');
    // const [status, setStatus] = useState(localStorage2Service.get('status') ?? '');

    const goSearch = () => {
        setSearch(localStorage2Service.get('search') ?? '');
    };

    // const goSelectStatus = () => {
    //     setStatus(localStorage2Service.get('status') ?? '');
    // };

    useEffect(() => {
        const data = requestsList
            ?.reduce((prev, i) => {
                if (
                    i?.items?.some(
                        (j) =>
                            j?.workers_required_for_service?.length &&
                            j?.workers_required_for_service?.some((a) => a?.slots?.length)
                    )
                ) {
                    return [
                        ...prev,
                        ...(i?.items?.map((j) => ({
                            ...i,
                            ...j,
                            pk: i.pk,
                            status: i.status,
                            location: i.location,
                        })) || []),
                    ];
                }
                return prev;
            }, [])
            ?.filter(
                (i) =>
                    i?.workers_required_for_service?.length &&
                    i?.workers_required_for_service?.some((j) => j?.slots?.length)
            );
        if (data?.length) {
            const list = data
                ?.map((m) =>
                    m.workers_required_for_service.map((row) => ({
                        ...row,
                        children:
                            row.slots?.map((slot) => {
                                const assigned_worker = m?.assigned_workers?.find(
                                    ({ id }) => slot.worker === id
                                );
                                return {
                                    ...m,
                                    ...slot,
                                    parentId: row.service_id,
                                    worker: assigned_worker?.name,
                                    comments: assigned_worker?.comments,
                                    checked: false,
                                    hours_worked: assigned_worker?.hours_worked,
                                    workerId: assigned_worker?.id,
                                    personnel_number: assigned_worker?.personnel_number,
                                    item_id: m.id,
                                    comment: slot?.description,
                                    start_time: slot?.start_time,
                                    end_time: slot?.end_time,
                                    start: assigned_worker?.start,
                                    itemworker_id: assigned_worker?.itemworker_id,
                                    requestworker_id: assigned_worker?.requestworker_id,
                                    score: assigned_worker?.score,

                                    end: assigned_worker?.end,
                                    statusItem: assigned_worker?.status,
                                    name: assigned_worker?.name,
                                    workers_required: row?.workers_required,
                                    service_name: row?.service_name,
                                    service_id: row?.service_id,
                                    min_hours: row?.min_hours,
                                    expected_hours: row?.expected_hours,
                                };
                            }) || [],
                        slots: row.slots || [],
                        checked: false,
                        pk: m?.pk,
                    }))
                )
                ?.reduce((prev, i) => {
                    return [...prev, ...(Array.isArray(i) ? i : [i])];
                }, [])
                ?.reduce((prev, i) => {
                    return [...prev, ...(Array.isArray(i.children) ? i.children : [i.children])];
                }, []);

            setRowDataFilter(list);
            setRowDataTable(
                filterList(
                    sortingList({
                        list: filteringList(list, search),
                        ...sorting,
                    }),
                    filterColumns
                )
            );
        }
    }, [requestsList, sorting, search, filterColumns]);

    async function _updateAddressOptions() {
        const res = await dispatch(
            updateAddressOptions({
                q: addressPopupInput,
                forward: JSON.stringify({
                    request: addressPopupOpenedId,
                }),
            })
        );
        setAddressOptions(res.payload.data.results.map((r) => ({ text: r.text, id: +r.id })));
    }

    const updateRequests = useCallback(async () => {
        // const res = await dispatch(
        //     fetchLocation({
        //         forward: JSON.stringify({
        //             first_day: moment(localStorage2Service.get('startRanges')).format('DD.MM.YYYY'),
        //             last_day: moment(localStorage2Service.get('endRanges')).format('DD.MM.YYYY'),
        //         }),
        //     })
        // );

        // setBranch(res.payload.data.results.map((r) => ({ id: +r.id, text: r.text })));

        return dispatch(
            fetchRequests({
                payment_status: filterMap(localStorage2Service.get('filter')),
                // status: localStorage2Service.get('status'),
                customer_resolution: localStorage2Service.get('customer_resolution'),
                location: localStorage2Service.get('branch'),
                first_day:
                    localStorage2Service.get('startRanges') &&
                    moment(localStorage2Service.get('startRanges')).format('DD.MM.YYYY'),
                last_day:
                    localStorage2Service.get('startRanges') &&
                    moment(localStorage2Service.get('endRanges')).format('DD.MM.YYYY'),
            })
        );
    }, []);

    const updateData = _.throttle(updateRequests, 500);
    const updateAddressOptions_t = _.throttle(_updateAddressOptions, 500);

    const requestPageProps = {
        payment_status: filterMap(localStorage2Service.get('filter')),
        // status: localStorage2Service.get('status'),
        customer_resolution: localStorage2Service.get('customer_resolution'),
        location: localStorage2Service.get('branch'),
        first_day:
            localStorage2Service.get('startRanges') &&
            moment(localStorage2Service.get('startRanges')).format('DD.MM.YYYY'),
        last_day:
            localStorage2Service.get('startRanges') &&
            moment(localStorage2Service.get('endRanges')).format('DD.MM.YYYY'),
    };

    const showError = (error) => {
        if (error) {
            message.error({ content: error });
        }
    };

    const onFetchError = (error) => {
        let err = '';

        if (error?.response?.data?.messages) {
            for (const key in error?.response?.data?.messages) {
                const element = error?.response?.data?.messages[key];
                if (element.length) {
                    err += ' ';
                    if (Array.isArray(element) && element) {
                        err += `${key}: `;
                        err += element.join(' ');
                    } else {
                        err += element;
                    }
                }
            }
        }

        showError(err || error?.response?.data?.detail || error.message);
    };

    const actionMassConfirm = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_confirm/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    clearSelectedRow();
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassStart = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_start/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    clearSelectedRow();
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };

    const actionMassRemove = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_remove/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    clearSelectedRow();
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassAdd = (data, withSuccess) => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_add/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                if (withSuccess) {
                    dispatch(fetchRequests(requestPageProps));
                    clearSelectedRow();
                }
            })
            .catch((e) => {
                onFetchError(e);
            });
    };

    const menuItems = [
        {
            key: '1',
            text: 'Вернуть на адрес',
            action: actionMassAdd,
            disabled: !selectedRow?.every(
                (item) =>
                    [
                        WorkerItemStatus.Failed,
                        WorkerItemStatus.Defect,
                        WorkerItemStatus.Cancelled,
                    ].includes(item.status) && item.status !== undefined
            ),
        },
        {
            key: '2',
            text: 'Подтвердить',
            action: actionMassConfirm,
            disabled: !selectedRow?.every(
                (item) => [WorkerItemStatus.New].includes(item.status) && item.status !== undefined
            ),
        },
        {
            key: '3',
            text: 'Пришёл',
            action: actionMassStart,
            disabled: !selectedRow?.every(
                (item) =>
                    [
                        WorkerItemStatus.StartPhotoRejected,
                        WorkerItemStatus.RequestConfirmedByWorker,
                        WorkerItemStatus.SuspiciousAddress,
                    ].includes(item.status) && item.status !== undefined
            ),
        },
        {
            key: '4',
            text: 'Снять',
            action: actionMassRemove,
            disabled: !selectedRow?.every(
                (item) =>
                    ![
                        WorkerItemStatus.Failed,
                        WorkerItemStatus.Defect,
                        WorkerItemStatus.Cancelled,
                    ].includes(item.status) && item.status !== undefined
            ),
        },
    ];

    useEffect(() => {
        setAddressPopupInput('');
        setAddressId(-1);
    }, [addressPopupOpenedId]);

    useEffect(() => {
        if (addressPopupOpenedId !== -1) {
            updateAddressOptions_t();
        }
    }, [addressPopupInput, addressPopupOpenedId]);

    useEffect(() => {
        localStorage2Service.set('itemsOnPage', itemsOnPage);
    }, [itemsOnPage]);
    useEffect(() => {
        updateRequests();
        dispatch(
            setLastStateSearch({
                curReq: '',
                location: '',
                createdRequestDate: '',
            })
        );
    }, [filterStatus]);

    const selectsActions = () => {
        const key = selectedRow?.[0]?.key;
        const ids = selectedRow?.map((i) => i.id);

        const workerIdsByPk = selectedRow?.reduce((acc, i) => {
            const idx = acc.findIndex((j) => j.pk === i.pk);
            const item = acc[idx];

            // eslint-disable-next-line no-bitwise
            if (~idx) {
                return [
                    ...acc.slice(0, idx),
                    {
                        pk: i?.pk,
                        workerIds: [...(item?.workerIds || []), i?.workerId],
                        item_id: i?.item_id,
                    },
                    ...acc.slice(idx + 1),
                ];
            }
            return [
                ...acc,
                {
                    pk: i.pk,
                    workerIds: [i.workerId],
                    item_id: i.item_id,
                },
            ];
        }, []);

        const onClickHandler = (action) => {
            workerIdsByPk.forEach((i, idx) => {
                const dataForStatusAction = {
                    worker_ids: i.workerIds,
                    request_id: i?.pk,
                    item_id: i?.item_id,
                };

                action(dataForStatusAction, idx === workerIdsByPk.length - 1);
            });
        };

        if (!mouseDown) {
            if (key === 'status')
                return (
                    <div className={styles.selectedActions}>
                        {menuItems
                            .filter((i) => !i.disabled)
                            .map((i) => (
                                <Button
                                    key={i.key}
                                    onClick={() => onClickHandler(i.action)}
                                    data-id-type='select'
                                >
                                    {i.text}
                                </Button>
                            ))}
                    </div>
                );
            if (key === 'comment')
                return (
                    <div className={styles.selectedActions}>
                        <CommentAction
                            callback={clearSelectedRow}
                            ids={ids}
                            requestPageProps={{
                                payment_status: filterMap(localStorage2Service.get('filter')),
                                // status: localStorage2Service.get('status'),
                                customer_resolution:
                                    localStorage2Service.get('customer_resolution'),
                                location: localStorage2Service.get('branch'),
                                first_day:
                                    localStorage2Service.get('startRanges') &&
                                    moment(localStorage2Service.get('startRanges')).format(
                                        'DD.MM.YYYY'
                                    ),
                                last_day:
                                    localStorage2Service.get('startRanges') &&
                                    moment(localStorage2Service.get('endRanges')).format(
                                        'DD.MM.YYYY'
                                    ),
                            }}
                        />
                    </div>
                );
            if (key === 'hours_worked') {
                const disabled = selectedRow.some(
                    (i) =>
                        ![
                            WorkerItemStatus.FinishPhotoRejected,
                            WorkerItemStatus.StartConfirmed,
                            WorkerItemStatus.Completed,
                        ].includes(i.status)
                );

                return (
                    <div className={styles.selectedActions}>
                        <HoursAction
                            callback={clearSelectedRow}
                            disabled={disabled}
                            workerIdsByPk={workerIdsByPk}
                            requestPageProps={{
                                payment_status: filterMap(localStorage2Service.get('filter')),
                                // status: localStorage2Service.get('status'),
                                customer_resolution:
                                    localStorage2Service.get('customer_resolution'),
                                location: localStorage2Service.get('branch'),
                                first_day:
                                    localStorage2Service.get('startRanges') &&
                                    moment(localStorage2Service.get('startRanges')).format(
                                        'DD.MM.YYYY'
                                    ),
                                last_day:
                                    localStorage2Service.get('startRanges') &&
                                    moment(localStorage2Service.get('endRanges')).format(
                                        'DD.MM.YYYY'
                                    ),
                            }}
                        />
                    </div>
                );
            }
        }
        return <div className={styles.selectedActions} />;
    };

    const _columns = useMemo(
        () => Object.keys(columns).filter((c) => columns[c].isVisible),
        [columns]
    );

    return (
        <Wrapper title='Заявки'>
            <SearchWrapper
                placeHolder='Найти'
                localStorageService={localStorage2Service}
                updateData={goSearch}
                itemsOnPage={itemsOnPage}
                setItemsOnPage={setItemsOnPage}
                className={styles.SearchWrapper}
            >
                <ViewButton
                    columns={columns}
                    setColumns={setColumns}
                    localStorageField='columns2'
                    initColumns={initColumns}
                />
            </SearchWrapper>
            <ButtonsContainer
                classes={styles.mediaStyle}
                left={
                    <Space size='middle'>
                        <DatePickerApply
                            localStorageService={localStorage2Service}
                            defaultRangeStart={moment().startOf('month').toDate()}
                            defaultRangeEnd={moment().toDate()}
                            updateData={updateData}
                        />

                        {selectsActions()}
                    </Space>
                }
                right={
                    <>
                        <div
                            onClick={() => {
                                setFilterColumns(initFilterColumns);
                            }}
                            className={styles.buttonsContainerItem}
                        >
                            Сбросить фильтрацию
                        </div>
                        <div
                            onClick={() => {
                                history.push('/home?create=true');
                            }}
                            className={styles.buttonsContainerItem}
                        >
                            <img
                                style={{
                                    marginRight: '5px',
                                }}
                                alt='icon'
                                src='/add_icon.svg'
                                className={styles.buttonsContainerIcon1}
                            />
                            Создать заявку
                        </div>
                        <ImportsButton
                            allow_requests_creation={allow_requests_creation}
                            updateData={updateData}
                            disabled
                        />
                        <DownloadExcelButton disabled />
                    </>
                }
            />
            <TableWrapper
                setSelectedRow={setSelectedRow}
                clearSelectedRowTriger={clearSelectedRowTriger}
                itemsOnPage={itemsOnPage}
                sorting={sorting}
                _columns={_columns}
                setMouseDown={setMouseDown}
                mouseDown={mouseDown}
                updateData={updateData}
                columns={columns}
                setFilterColumns={setFilterColumns}
                filterColumns={filterColumns}
                rowDataTable={rowDataTable}
                rowDataFilter={rowDataFilter}
                setSorting={setSorting}
                setStartId={setStartId}
                setEndId={setEndId}
                startId={startId}
                endId={endId}
                setKeyName={setKeyName}
                keyName={keyName}
            />
            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup.open}
                onClose={() => setInfoPopup({ ...infoPopup, open: false })}
            >
                {(close) => (
                    <PopupInfo title={infoPopup.title} close={close}>
                        <div dangerouslySetInnerHTML={{ __html: infoPopup.content }} />
                    </PopupInfo>
                )}
            </ShortPopup>
            <ShortPopup
                modal
                closeOnDocumentClick
                open={modalPopup.open}
                onClose={() => setModalPopup({ ...modalPopup, open: false })}
            >
                {(close) => (
                    <PopupModal
                        title={modalPopup.title}
                        close={close}
                        onOk={modalPopup.onOk}
                        onCancel={modalPopup.onCancel}
                    >
                        {modalPopup.content}
                    </PopupModal>
                )}
            </ShortPopup>
        </Wrapper>
    );
};

const CommentAction = ({ ids, requestPageProps, callback }) => {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const updateRequestComment = (value) => {
        ids.forEach((id, idx) => {
            dispatch(
                updateSlotRequest(id, () => {
                    if (idx === ids.length - 1) {
                        dispatch(fetchRequests(requestPageProps));
                        setValue('');
                        callback();
                    }
                })(
                    {},
                    {
                        description: value,
                    }
                )
            );
        });
    };

    return (
        <Input
            value={value}
            onChange={(e) => setValue(e.target?.value)}
            onKeyDown={({ key }) => key === 'Enter' && updateRequestComment(value)}
            placeholder='Введите комментрий...'
            data-id-type='select'
            autoFocus
        />
    );
};

const HoursAction = ({ workerIdsByPk, requestPageProps, disabled, callback }) => {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const updateRequestComment = (value) => {
        workerIdsByPk.forEach((item, idx) => {
            const data = {
                worker_ids: item.workerIds,
                request_id: item.pk,
                item_id: item.item_id,
                hours: value,
            };
            axios({
                method: 'POST',
                url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                data,
                headers: {
                    Authorization: `Bearer ${authorization.getToken()}`,
                },
            }).then(() => {
                if (idx === workerIdsByPk.length - 1) {
                    dispatch(fetchRequests(requestPageProps));
                    setValue('');
                    callback();
                }
            });
        });
    };

    return (
        <Input
            value={value}
            onChange={(e) => setValue(e.target?.value)}
            onKeyDown={({ key }) => key === 'Enter' && updateRequestComment(value)}
            placeholder='Введите кол-во часов...'
            data-id-type='select'
            disabled={disabled}
            autoFocus
        />
    );
};

const hasInputMaskTime = ['start_time', 'end_time', 'fact_start', 'fact_end'];
const hasInputMaskDate = ['date'];
const notFilter = ['comment', 'commentTT', 'commentKA', 'approve', 'approveTitle'];

const DropdownHeader = ({ children, c, data = [], setFilterColumns, filterColumns }) => {
    const _value = filterColumns[c];
    const [value, setValue] = useState('');
    const [selected, setSelected] = useState(_value ?? []);
    const [visible, setVisible] = useState(false);
    const [isError, setIsError] = useState(false);

    const _input = useRef(null);

    useEffect(() => {
        if (visible) {
            setValue('');
            setSelected(_value);
        }
    }, [visible, _value]);

    useEffect(() => {
        setTimeout(() => {
            _input?.current?.focus();
        }, 10);
    }, [visible]);

    const onChangeHandler = (e) => {
        setValue(e.target?.value ?? '');
    };

    const onInputChange = (value) => {
        setValue(value);
    };

    const onInputChangeDate = (value) => {
        setValue(value);

        if (value.includes('_')) {
            setIsError(false);
        } else if (!moment(value, 'DD.MM.YYYY').isValid()) {
            setIsError(true);
        }
    };

    // eslint-disable-next-line consistent-return
    const onKeyDownInputTimeStop = (e) => {
        const key = +e.key;
        const value = e.target.value || '';
        const numbers = value.match(/[_0-9]/g);

        const selectionStart = e.target?.selectionStart;
        const selectionEnd = e.target?.selectionEnd;

        const numberIndex = {
            0: 0,
            1: 1,
            2: 2,
            3: 2,
            4: 3,
        };

        const index = numberIndex?.[e.target?.selectionStart];
        if (!Number.isNaN(key) && typeof index === 'number') {
            if (index === 0) {
                if (
                    key > 2 ||
                    (!Number.isNaN(+numbers[1]) &&
                        +numbers[1] > 3 &&
                        key > 1 &&
                        !(selectionStart === 0 && selectionEnd >= 2))
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            }
            if (index === 1) {
                if (!Number.isNaN(+numbers[0]) && +numbers[0] === 2 && key > 3) e.preventDefault();
                e.stopPropagation();
                return false;
            }
            if (index === 2) {
                if (key > 5) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            }
        }
    };

    const onClickItemHandler = (i) => {
        setSelected((prev) => {
            const idx = prev.findIndex((j) => j.id === i.id);

            // eslint-disable-next-line no-bitwise
            if (~idx) {
                return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
            }
            return [...prev, i];
        });
    };

    const onClickReset = () => {
        setSelected([]);
        setValue('');
    };

    const onClickSubmit = () => {
        setFilterColumns((prev) => ({
            ...prev,
            [c]: selected,
        }));
        setVisible(false);
    };

    const menuOptions = useMemo(() => {
        const result = getDataFilter(value, data, c);

        for (let index = 0; index < selected.length; index++) {
            const element = selected[index];

            if (!result.find((i) => i.id === element.id)) {
                result.unshift(element);
            }
        }

        return result;
    }, [selected, value, data, c]);

    const menu = (
        <div className={styles.dropdown_Menu}>
            {hasInputMaskDate.includes(c) && (
                <ReactInputMask
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    mask='99.99.9999'
                    alwaysShowMask
                    className={clsx(styles.ReactInputMaskDate, {
                        [styles.isError]: isError,
                    })}
                    ref={_input}
                    onChange={(e) => onInputChangeDate(e.target.value)}
                    value={value}
                />
            )}
            {hasInputMaskTime.includes(c) && (
                <ReactInputMask
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    mask='99:99'
                    alwaysShowMask
                    className={styles.ReactInputMask}
                    ref={_input}
                    onKeyDown={onKeyDownInputTimeStop}
                    onChange={(e) => onInputChange(e.target.value)}
                    value={value}
                />
            )}
            {!hasInputMaskDate.includes(c) && !hasInputMaskTime.includes(c) && (
                <Input
                    className={styles.Dropdown_Menu_Input}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    autoFocus
                    value={value}
                    ref={_input}
                    onChange={onChangeHandler}
                    allowClear
                />
            )}
            <Menu className={styles.Dropdown_Menu}>
                {menuOptions.map((i) => (
                    <Menu.Item
                        className={clsx(styles.Dropdown_Menu_Item, {
                            [styles.antMenuItemSelected]: !!selected?.find(
                                (j) => i.text === j.text
                            ),
                        })}
                        key={`${i.id}`}
                        onClick={() => {
                            onClickItemHandler(i);
                        }}
                    >
                        {i.text}
                    </Menu.Item>
                ))}
            </Menu>
            <div className={styles.Dropdown_Menu_Buttons}>
                <Button onClick={onClickSubmit}>Применить</Button>
                <Button onClick={onClickReset}>Сбросить</Button>
            </div>
        </div>
    );

    return (
        <Dropdown
            onVisibleChange={(visible) => {
                setVisible(visible);
            }}
            visible={visible}
            overlay={menu}
            overlayClassName={styles.dropdown}
            arrow
            placement='bottomLeft'
            // placement='bottomRight'
            // placement='bottomCenter'
            trigger={['click']}
        >
            {children}
        </Dropdown>
    );
};

const TableRow = memo(
    ({
        elem,
        columns,
        setMouseDown,
        selectedRow,
        setSelectedRow,
        mouseDown,
        openD,
        updateData,
        setOpenD,
        rowDataTable,
        sorting,
        itemsOnPage,
        page,
        setStartId,
        setEndId,
        idx,
        startId,
        endId,
        setKeyName,
        keyName,
        setRestart,
        setSelectedApprove,
        selectedApprove,
    }) => {
        const cell = columns.map((c) => {
            if (changedFields.includes(c))
                return (
                    <TableCell
                        elem={elem}
                        c={c}
                        columns={columns}
                        setMouseDown={setMouseDown}
                        hasSelectedRow={changedFields?.includes(c) && !!selectedRow?.length}
                        selectedRow={changedFields?.includes(c) ? selectedRow : []}
                        setSelectedRow={setSelectedRow}
                        mouseDown={mouseDown}
                        openD={openD}
                        setOpenD={setOpenD}
                        key={`${c}_${elem.id}_${elem.pk}`}
                        setStartId={setStartId}
                        setEndId={setEndId}
                        idx={idx}
                        startId={startId}
                        endId={endId}
                        setKeyName={setKeyName}
                        keyName={keyName}
                        setRestart={setRestart}
                    />
                );
            if (['personnel_number', 'name'].includes(c))
                return (
                    <TableCell
                        elem={elem}
                        c={c}
                        columns={columns}
                        rowDataTable={rowDataTable}
                        key={`${c}_${elem.id}_${elem.pk}`}
                        setRestart={setRestart}
                        setMouseDown={setMouseDown}
                        mouseDown={mouseDown}
                        setEndId={setEndId}
                        idx={idx}
                    />
                );
            if (['has_elevator'].includes(c))
                return (
                    <TableCell
                        elem={elem}
                        c={c}
                        columns={columns}
                        updateData={updateData}
                        key={`${c}_${elem.id}_${elem.pk}`}
                        setRestart={setRestart}
                        setMouseDown={setMouseDown}
                        mouseDown={mouseDown}
                        setEndId={setEndId}
                        idx={idx}
                    />
                );

            if (['approve'].includes(c)) {
                const elemsData = rowDataTable
                    .filter((i) => i.pk === elem.pk)
                    .map((i) => ({
                        pk: i.pk,
                        worked_hours: i.worked_hours,
                        start: i.start,
                        start_time: i.start_time,
                        end: i.end,
                        end_time: i.end_time,
                        score: i.score,
                        id: i.id,
                        name: i.name,
                        timestamp: i.timestamp,
                    }));
                return (
                    <TableCell
                        elem={elem}
                        c={c}
                        columns={columns}
                        sorting={sorting}
                        key={`${c}_${elem.id}_${elem.pk}`}
                        setRestart={setRestart}
                        setMouseDown={setMouseDown}
                        mouseDown={mouseDown}
                        setEndId={setEndId}
                        idx={idx}
                        elemsData={elemsData}
                        setSelectedApprove={setSelectedApprove}
                        selectedApprove={selectedApprove}
                    />
                );
            }
            return (
                <TableCell
                    elem={elem}
                    c={c}
                    columns={columns}
                    key={`${c}_${elem.id}_${elem.pk}`}
                    setRestart={setRestart}
                    setMouseDown={setMouseDown}
                    mouseDown={mouseDown}
                    setEndId={setEndId}
                    idx={idx}
                />
            );
        });

        const item =
            selectedApprove?.[0]?.pk === elem.pk
                ? selectedApprove.find((i) => i.id === elem.id)
                : null;

        const needApprove = item
            ? !item.name ||
              !item.worked_hours ||
              !item.start ||
              !item.end ||
              !item?.score?.percentage
            : null;

        return (
            <tr
                key={`${itemsOnPage}_${page}_${elem.code}_${elem.pk}_${elem.id}_${elem.parentId}_${sorting.key}_${sorting.direction}`}
                className={[
                    styles.requestTable,
                    styles.tableBody_tr,
                    elem.statusItem === WorkerItemStatus.New ? styles.trNew : '',
                    elem.statusItem === WorkerItemStatus.RequestConfirmedByWorker
                        ? styles.trRequestConfirmedByWorker
                        : '',
                    elem.statusItem === WorkerItemStatus.StartConfirmed
                        ? styles.trStartConfirmed
                        : '',
                    elem.statusItem === WorkerItemStatus.Completed ? styles.trCompleted : '',
                    needApprove ? styles.needApprove : '',
                ].join(' ')}
            >
                <td
                    className={`${styles.photoPopup} ${styles.tableBody_td} ${styles.tableBody_td_img}`}
                    key={`button1_${elem.index}`}
                />
                {cell}
            </tr>
        );
    }
);

const TableWrapper = ({
    setSelectedRow: setSelectedRowMain,
    itemsOnPage,
    sorting,
    _columns,
    setMouseDown,
    mouseDown,
    updateData,
    columns,
    setFilterColumns,
    filterColumns,
    rowDataTable,
    rowDataFilter,
    setSorting,
    setStartId,
    setEndId,
    startId,
    endId,
    setKeyName,
    keyName,
    clearSelectedRowTriger,
}) => {
    const [selectedRow, setSelectedRow] = useState([]);
    const [restart, setRestart] = useState(false);
    const [selectedApprove, setSelectedApprove] = useState([]);
    const [modalPopup, setModalPopup] = useState({ open: false, content: '', title: '' });

    useEffect(() => {
        setSelectedRow([]);
        setSelectedRowMain([]);
        setStartId(undefined);
        setEndId(undefined);
    }, [clearSelectedRowTriger]);

    useEffect(() => {
        const onEsc = (e) => {
            if (e.key === 'Escape') {
                setSelectedRow([]);
                setSelectedRowMain([]);

                setStartId(undefined);
                setEndId(undefined);
            }
        };

        document.addEventListener('keydown', onEsc);
        return () => {
            document.removeEventListener('keydown', onEsc);
        };
    }, []);

    useEffect(() => {
        if (restart) {
            setMouseDown(false);

            let _startId = startId;
            let _endId = endId;

            if (typeof startId !== 'number' && typeof endId !== 'number') {
                setSelectedRow([]);
                setSelectedRowMain([]);

                setStartId(undefined);
                setEndId(undefined);
            } else {
                if (_startId > _endId) {
                    _startId = endId;
                    _endId = startId;
                }

                const data = rowDataTable
                    ?.slice(itemsOnPage * (page - 1), itemsOnPage * page)
                    ?.slice(_startId, _endId + 1)
                    .map((i) => ({
                        pk: i.pk,
                        id: i.id,
                        key: keyName,
                        workerId: i.workerId,
                        item_id: i.item_id,
                        status: i.statusItem,
                    }));

                setSelectedRow(data);
                setSelectedRowMain(data);

                setStartId(undefined);
                setEndId(undefined);
            }

            setRestart(false);
        }
    }, [restart, startId, endId, rowDataTable, keyName, itemsOnPage, page]);

    useEffect(() => {
        const mouseDown = (e) => {
            let check = true;
            // eslint-disable-next-line prefer-destructuring
            let target = e.target;
            let go = true;
            while (go) {
                if (target) {
                    if (
                        target.tagName === 'TABLE' ||
                        target.classList?.value?.includes('ant-dropdown') ||
                        target.classList?.value?.includes('ant-modal-root') ||
                        target?.dataset?.idType === 'scrollWrapper' ||
                        target?.dataset?.idType === 'select'
                        // target?.dataset?.idType === 'CustomAutocompleteDropdown'
                    ) {
                        go = false;
                        check = false;
                    } else {
                        target = target.parentNode;
                    }
                } else {
                    go = false;
                }
            }

            if (check) {
                setSelectedApprove([]);
            }
        };

        document.addEventListener('mousedown', mouseDown);

        return () => {
            document.removeEventListener('mousedown', mouseDown);
        };
    }, []);

    useEffect(() => {
        const click = (e) => {
            let check = true;
            // eslint-disable-next-line prefer-destructuring
            let target = e.target;
            let go = true;
            while (go) {
                if (target) {
                    if (target.tagName === 'TBODY' || target?.dataset?.idType === 'select') {
                        go = false;
                        check = false;
                    } else {
                        target = target.parentNode;
                    }
                } else {
                    go = false;
                }
            }

            if (check) {
                setSelectedRow([]);
                setSelectedRowMain([]);

                setStartId(undefined);
                setEndId(undefined);
                setMouseDown(false);
            }
        };

        document.addEventListener('click', click);

        return () => {
            document.removeEventListener('click', click);
        };
    }, []);

    const { isRequestsLoading } = useSelector((state) => ({
        isRequestsLoading: state.requests.isLoading,
    }));

    const [page, setPage] = useState(1);

    const [openD, setOpenD] = useState(false);

    const setSelectedApproveHandler = (selectedApprove = []) => {
        setSelectedApprove(selectedApprove);

        if (selectedApprove?.length) {
            const item = selectedApprove?.[0];

            const title = `Для подтверждения ЛУВР №${item?.pk} от ${
                item.timestamp ? moment(item.timestamp).format('DD.MM.YYYY') : null
            } необходимо:`;

            /* <div>{i?.name ? i?.name : `Заявка id ${i?.id}`}</div> */
            // <div>Проставьте оценки:</div>
            // <div>Проставьте время:</div>

            // const namePeople = selectedApprove?.filter((i) => !i?.name);
            // const percentagePeople = selectedApprove?.filter((i) => !i?.score?.percentage);
            // const workedHoursPeople = selectedApprove?.filter(
            //     (i) => !i.worked_hours || !i.start || !i.end
            // );

            const content = (
                <div className={styles.flex}>
                    {selectedApprove.map((i, idx) =>
                        !i.name ||
                        !i.worked_hours ||
                        !i.start ||
                        !i.end ||
                        !i?.score?.percentage ? (
                            <div className={styles.rowApprove}>
                                <div className={styles.colApprove}>{`${idx + 1}. ${
                                    i.name ?? 'Назначьте исполнителя'
                                }`}</div>
                                {i.name && (!i.worked_hours || !i.start || !i.end) ? (
                                    <div className={styles.colApprove2}>
                                        Поставить время окончания
                                    </div>
                                ) : (
                                    <div className={styles.colApprove2} />
                                )}
                                {i.name && !i?.score?.percentage ? (
                                    <div className={styles.colApprove3}>Проставить оценку</div>
                                ) : (
                                    <div className={styles.colApprove3} />
                                )}
                            </div>
                        ) : null
                    )}
                </div>
            );

            setModalPopup({ open: true, content, title });
        }
    };

    function changeSorting(key) {
        let { direction } = sorting;

        if (sorting.key === key) {
            direction = direction === 'down' ? 'up' : 'down';
        }

        setSorting({ key, direction });
        localStorage2Service.set('sorting', { key, direction });
    }

    const handleClose = () => {
        setModalPopup({ open: false, content: '', title: '' });
    };

    function tableBody() {
        return (
            <>
                {rowDataTable
                    ?.slice(itemsOnPage * (page - 1), itemsOnPage * page)
                    ?.map((elem, idx) => (
                        <Fragment
                            key={`${itemsOnPage}_${page}_${elem.code}_${elem.pk}_${elem.id}_${elem.parentId}_${sorting.key}_${sorting.direction}`}
                        >
                            <TableRow
                                elem={elem}
                                columns={_columns}
                                setMouseDown={setMouseDown}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                mouseDown={mouseDown}
                                openD={openD}
                                updateData={updateData}
                                setOpenD={setOpenD}
                                rowDataTable={rowDataTable}
                                sorting={sorting}
                                itemsOnPage={itemsOnPage}
                                page={page}
                                setStartId={setStartId}
                                setEndId={setEndId}
                                idx={idx}
                                startId={startId}
                                endId={endId}
                                setKeyName={setKeyName}
                                keyName={keyName}
                                setRestart={setRestart}
                                setSelectedApprove={setSelectedApproveHandler}
                                selectedApprove={selectedApprove}
                            />
                        </Fragment>
                    ))}

                <Modal
                    destroyOnClose
                    visible={modalPopup.open}
                    title={modalPopup.title}
                    footer={null}
                    onCancel={handleClose}
                    className={styles.modalApprove}
                >
                    {modalPopup.content}
                </Modal>
            </>
        );
    }

    return (
        <TableRequestsWrapper
            isLoading={isRequestsLoading}
            head={
                <tr className={styles.tableHead}>
                    <td />
                    {Object.keys(columns)
                        .filter((c) => columns[c].isVisible)
                        .map((c) => {
                            const img_link = `/sort_${sorting.direction}_icon.svg`;
                            return (
                                <td
                                    key={c}
                                    style={{
                                        ...columns[c].style,
                                    }}
                                >
                                    <div
                                        style={{ display: 'flex', alignItems: 'flex-start' }}
                                        className={styles.tableHeadTd}
                                    >
                                        {!notFilter.includes(c) && (
                                            <DropdownHeader
                                                c={c}
                                                data={rowDataFilter}
                                                setFilterColumns={setFilterColumns}
                                                filterColumns={filterColumns}
                                            >
                                                {filterColumns?.[c]?.length ? (
                                                    <FilterFilled className={styles.iconFilter} />
                                                ) : (
                                                    <FilterOutlined className={styles.iconFilter} />
                                                )}
                                            </DropdownHeader>
                                        )}
                                        <div onClick={() => changeSorting(c)}>
                                            <div
                                                style={{
                                                    overflow: 'hidden',
                                                    width: '100%',
                                                    textOverflow: 'ellipsis',
                                                    textWrap: 'wrap',
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                                key={columns[c].text}
                                            >
                                                {columns[c].text}
                                            </div>
                                            {sorting.key === c && <img alt='img' src={img_link} />}
                                        </div>
                                    </div>
                                </td>
                            );
                        })}
                    <td />
                </tr>
            }
            classNameTableWrapper={styles.tableWrapper}
            className={styles.table}
            body={tableBody()}
            pagination={
                <Pagination
                    stylesRef={{
                        wrap: { margin: 0, width: 'auto' },
                        item: { fontSize: '12px', width: '28px', height: '28px' },
                    }}
                    pages={Math.ceil((rowDataTable?.length || 0) / itemsOnPage)}
                    onPageChange={(p) => setPage(p)}
                />
            }
        />
    );
};
