import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Select } from '@components';

import { setUpdateRequests } from '../../../store/actions';

import styles from '../styles.module.scss';

export const CreateServiceModal = ({ isOpen, setIsOpen, onSubmit }) => {
    const { list: servicesList } = useSelector((state) => state.services);
    const [form] = useForm();
    const dispatch = useDispatch();
    return (
        <Modal
            width={900}
            title='Новые услуги'
            footer={null}
            visible={isOpen}
            onCancel={() => setIsOpen(false)}
        >
            <Form
                form={form}
                onFinish={(v) => {
                    onSubmit({
                        services: v.services.map((service) => {
                            return servicesList.find(({ id }) => id === service);
                        }),
                    });
                    dispatch(
                        setUpdateRequests(
                            servicesList.filter(({ id }) => !v?.services?.includes(id))
                        )
                    );
                    setIsOpen(false);
                    form.resetFields();
                }}
            >
                {!!servicesList?.length && (
                    <Form.Item name='services' shouldUpdate isLabelVertical label='Выберите услуги'>
                        <Select
                            mode='multiple'
                            options={servicesList.map(({ text, id }) => ({
                                value: id,
                                label: text,
                            }))}
                        />
                    </Form.Item>
                )}
                <Button
                    size='large'
                    htmlType='submit'
                    className={styles.button_create_service}
                    onClick={() => setIsOpen(false)}
                >
                    Добавить
                </Button>
            </Form>
        </Modal>
    );
};
