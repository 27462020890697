import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Form } from 'antd';
import styled from 'styled-components';

import { Button } from '@components/Button';
import { FormItem } from '@components/FormItem';
import { Input } from '@components/Input';
import { Label } from '@components/Label';
import { PopupInfo } from '@components/PopupInfo';
import { downloadFile } from '@utils';

import 'react-dadata/dist/react-dadata.css';
import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

export const NewInvoicePopup = ({ close, onSuccess }) => {
    const [amount, setAmount] = useState('');

    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [isRequiredFilled, setIsRequiredFilled] = useState(false);

    useEffect(() => {
        setIsRequiredFilled(checkRequired());
    }, [amount]);

    function checkRequired() {
        const result = !!amount;
        return result;
    }

    const onAmountChange = (e) => {
        setAmount(e.target.value);
    };

    function submitForm() {
        downloadFile({
            method: 'post',
            url: 'gt/customer/create_invoice',
            params: { amount },
            filename: 'invoice.pdf',
        })().then(() => onSuccess());
    }

    return (
        <div className={styles.viewPopup}>
            <div onClick={close} className={styles.viewPopupClose}>
                <img alt='icon' src='/close_icon.svg' />
            </div>
            <div className={styles.viewPopupTitle}>Пополнить баланс</div>
            <div className={styles.container}>
                <Form>
                    <FormItem isLabelVertical label={<Label>Сумма</Label>}>
                        <Input className={styles.input} value={amount} onChange={onAmountChange} />
                    </FormItem>

                    <Button
                        size='large'
                        onClick={() => (isRequiredFilled ? submitForm() : null)}
                        className={`${styles.button} ${!isRequiredFilled ? styles.disabled : ''}`}
                    >
                        Скачать счет
                    </Button>
                </Form>
            </div>

            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup.open}
                onClose={() => {
                    if (infoPopup.title === 'Результат') {
                        close();
                    } else {
                        setInfoPopup({ ...infoPopup, open: false });
                    }
                }}
            >
                {(close) => (
                    <PopupInfo title={infoPopup.title} close={close}>
                        {infoPopup.content}
                    </PopupInfo>
                )}
            </ShortPopup>
        </div>
    );
};
