import { useEffect, useMemo, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Menu, message, Table } from 'antd';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment';

import { CustomAutocomplete, Editable } from '@components';
import { getHoursWithDinner, getMinutesWithDinner } from '@pages/Requests2Page/utils';
import { fetchRequests, updateSlotRequest } from '@store/actions';
import { authorization, BACKEND_URL } from '@utils';

import { statuses, statusesFull, WorkerItemStatus } from '../utils';

import styles from './styles.module.scss';

const changedFields = ['hours_worked', 'description', 'status'];
function getStatusClass(status) {
    const statusClass = statusesFull[status]?.class ?? 'status-default';

    return `${styles.status} ${styles[statusClass]}`;
}
export const ViewTable = ({
    rowData,
    setRowData,
    pk,
    idsWorkers,
    createdRequestDate,
    worker_ids,
    item_id,
    location,
    selectedRow,
    setSelectedRow,
    mouseDown,
    setMouseDown,
    requestPopup,
}) => {
    const [openD, setOpenD] = useState(false);
    const filteredByKey = (arr, key) => {
        return arr.filter((i) => i.key === key);
    };

    const getBackgroundColor = (elem, key) => {
        const item = selectedRow.find((i) => i.id === elem.id && i.key === key);
        if (item && !item?.removeNow && key === item.key) return '#d6f4ff';
        return undefined;
    };

    const createActionsOnCell = (elem, key) => {
        return {
            onMouseDown: (e) => {
                if (!changedFields.includes(key)) return;
                setMouseDown(true);
                if (
                    !selectedRow.length &&
                    (e.target.classList.value.includes('ant-dropdown-trigger') ||
                        e.target.classList.value.includes('actionClass') ||
                        e.target.tagName === 'SPAN' ||
                        openD)
                )
                    return;
                setSelectedRow((prev) => {
                    const idx = prev.findIndex((i) => i.id === elem.id && i.key === key);
                    // eslint-disable-next-line no-bitwise
                    if (!~idx)
                        return filteredByKey(
                            [
                                ...prev,
                                {
                                    addNow: true,
                                    removeNow: false,
                                    pk,
                                    id: elem.id,
                                    key,
                                    workerId: elem.workerId,
                                    personnel_number: elem?.personnel_number,
                                    item_id,
                                    status: elem.status,
                                },
                            ],
                            key
                        );
                    return filteredByKey(
                        [
                            ...prev.slice(0, idx),
                            {
                                addNow: false,
                                removeNow: true,
                                pk,
                                id: elem.id,
                                key,
                                workerId: elem.workerId,
                                personnel_number: elem?.personnel_number,
                                item_id,
                                status: elem.status,
                            },
                            ...prev.slice(idx + 1),
                        ],
                        key
                    );
                });
            },
            onMouseMove: () => {
                if (!changedFields.includes(key)) return;
                setSelectedRow((prev) => {
                    if (mouseDown) {
                        const idx = prev.findIndex((i) => i.id === elem.id && i.key === key);
                        const addNow = prev?.[idx]?.addNow;
                        const removeNow = prev?.[idx]?.removeNow;
                        let result = prev;
                        // eslint-disable-next-line no-bitwise
                        if (!~idx) {
                            result = [
                                ...prev,
                                {
                                    addNow: true,
                                    removeNow: false,
                                    pk,
                                    id: elem.id,
                                    key,
                                    workerId: elem.workerId,
                                    personnel_number: elem?.personnel_number,
                                    item_id,
                                    status: elem.status,
                                },
                            ];
                        }

                        // eslint-disable-next-line no-bitwise
                        if (~idx) {
                            if (!removeNow && !addNow) {
                                result = [
                                    ...prev.slice(0, idx),
                                    {
                                        addNow: false,
                                        removeNow: true,
                                        pk,
                                        id: elem.id,
                                        key,
                                        workerId: elem.workerId,
                                        personnel_number: elem?.personnel_number,
                                        item_id,
                                        status: elem.status,
                                    },
                                    ...prev.slice(idx + 1),
                                ];
                            }
                        }

                        return filteredByKey(result, key).reduce((acc, j) => {
                            if (acc?.find((i) => i.id === j.id && i.pk === j.pk && i.key === j.key))
                                return acc;
                            return [...acc, j];
                        }, []);
                    }
                    return prev;
                });
            },
            style: {
                backgroundColor: getBackgroundColor(elem, key),
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            },
        };
    };

    useEffect(() => {
        const mouseup = () => {
            setMouseDown(false);
            setSelectedRow((prev) => {
                if (prev.length === 0) return prev;
                return prev.filter((i) => !i.removeNow).map((i) => ({ ...i, addNow: false }));
            });
        };

        const click = (e) => {
            let check = true;
            // eslint-disable-next-line prefer-destructuring
            let target = e.target;
            let go = true;
            while (go) {
                if (target) {
                    if (target.tagName === 'TABLE' || target?.dataset?.idType === 'select') {
                        go = false;
                        check = false;
                    } else {
                        target = target.parentNode;
                    }
                } else {
                    go = false;
                }
            }

            if (check) setSelectedRow([]);
        };

        if (!requestPopup) {
            document.addEventListener('mouseup', mouseup);
            document.addEventListener('click', click);
        }

        return () => {
            document.removeEventListener('mouseup', mouseup);
            document.removeEventListener('click', click);
        };
    }, [requestPopup]);

    const dispatch = useDispatch();
    const onChecked = (e, record) => {
        if (record.slots) {
            setRowData((prev) =>
                prev.map((item) =>
                    item.service_id === record.service_id
                        ? {
                              ...item,
                              checked: e.target.checked,
                              children:
                                  item.children?.map((i) => ({
                                      ...i,
                                      checked: e.target.checked,
                                  })) || [],
                          }
                        : item
                )
            );
        } else {
            setRowData((prev) =>
                prev.map((item) => {
                    const children = item.children?.map((i) => ({
                        ...i,
                        checked: i.id === record.id ? e.target.checked : i.checked,
                    }));
                    return item.service_id === record.parentId
                        ? {
                              ...item,
                              children,
                              checked: children.every((i) => i.checked),
                          }
                        : item;
                })
            );
        }
    };

    const onCheckedAll = (e) => {
        setRowData((prev) =>
            prev.map((item) => ({
                ...item,
                checked: e.target.checked,
                children:
                    item.children?.map((i) => ({
                        ...i,
                        checked: e.target.checked,
                    })) || [],
            }))
        );
    };

    const columns = useMemo(
        () => [
            {
                title: 'Услуги',
                dataIndex: 'service_name',
                key: 'service_name',
                width: '150px',
                render: (a, row) => {
                    return (
                        <span title={row?.service_name} className={styles.service_name}>
                            {row?.service_name}
                        </span>
                    );
                },
            },

            {
                title: 'Время начала',
                dataIndex: 'start_time',
                width: '70px',
                key: 'start_time',
                render: (a, row) => {
                    return <span>{row.parentId ? row.start_time.slice(0, -3) : null}</span>;
                },
            },
            {
                title: 'Время окончания',
                dataIndex: 'end_time',
                width: '70px',
                key: 'end_time',
                render: (a, row) => {
                    return <span>{row.parentId ? row.end_time.slice(0, -3) : null}</span>;
                },
            },
            {
                title: 'Кол-во часов',
                dataIndex: 'difTime',
                width: '80px',
                key: 'difTime',
                render: (a, row) => {
                    const startMinutes = moment(row.start_time, 'HH:mm:ss').minutes();
                    const startHours = moment(row.start_time, 'HH:mm:ss').hours();
                    const endMinutes = moment(row.end_time, 'HH:mm:ss').minutes();
                    const endHours = moment(row.end_time, 'HH:mm:ss').hours();

                    const startM = startHours * 60 + startMinutes;
                    const endM = endHours * 60 + endMinutes;

                    const f = endM - startM;
                    const s = endM + 1440 - startM;
                    let subtractM = endM - startM > 0 ? f : s;

                    if (subtractM > 390 && subtractM < 540) subtractM -= 30;
                    if (subtractM >= 540 && subtractM < 750) subtractM -= 60;
                    if (subtractM >= 750) subtractM -= 90;

                    const m = subtractM % 60;
                    const h = Math.floor(subtractM / 60);
                    const v = Number(h + m / 60).toFixed(1);

                    return (
                        <span
                            style={{
                                display: 'inline-block',
                                width: 60,
                            }}
                        >
                            {v}
                        </span>
                    );
                },
            },
            {
                title: 'Примечание',
                dataIndex: 'description',
                key: 'description',
                render: (a, row) => {
                    const submitFunction = (value) => {
                        dispatch(
                            updateSlotRequest(row.id)(
                                {},
                                {
                                    description: value,
                                }
                            )
                        );
                    };

                    return (
                        <div {...createActionsOnCell(row, 'description')}>
                            {row.parentId ? (
                                <Editable
                                    submitFunction={submitFunction}
                                    field='customer_comment'
                                    pk={row.pk}
                                    item_pk={null}
                                    text={row.description || '-'}
                                    editable
                                    isEditable={!selectedRow.length}
                                />
                            ) : null}
                        </div>
                    );
                },
            },
            {
                title: 'ТБ',
                width: '50px',
                dataIndex: 'personnel_number',
                key: 'personnel_number',
                render: (a, row) => {
                    const v =
                        row.personnel_number ?? row.workerId
                            ? `${row.personnel_number ? row.personnel_number : `ID${row.workerId}`}`
                            : null;
                    return (
                        <span
                            style={{
                                display: 'block',
                                textAlign: 'center',
                            }}
                        >
                            {row.parentId ? v : null}
                        </span>
                    );
                },
            },
            {
                title: 'Табельный номер',
                width: '250px',
                dataIndex: 'num',
                key: 'num',
                className: styles.wideCell,
                render: (a, row) => {
                    return (
                        <span>
                            {row.parentId ? (
                                <Num
                                    pk={pk}
                                    worker={row.worker}
                                    slot={row.id}
                                    service={row.parentId}
                                    request={pk}
                                    idsWorkers={idsWorkers}
                                    createdRequestDate={createdRequestDate}
                                    location={location}
                                />
                            ) : null}
                        </span>
                    );
                },
            },
            {
                title: 'Время начала, факт',
                dataIndex: 'start',
                width: '50px',
                key: 'start',
                render: (start, row) => {
                    const updateRequestComment = (value) => {
                        const hour = row?.hours_worked || 0;
                        if (!hour) {
                            if (row.itemworker_id && row.start) {
                                requestWithoutHour(value);
                            } else if (row.status === 2) {
                                actionMassStart(value);
                            }
                        } else {
                            requestWithHour(value);
                        }
                    };

                    const actionMassStart = (value) => {
                        const data = {
                            worker_ids: [row.workerId],
                            request_id: pk,
                            item_id,
                        };

                        axios({
                            method: 'POST',
                            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_start/`,
                            data,
                            headers: {
                                Authorization: `Bearer ${authorization.getToken()}`,
                            },
                        })
                            .then(() => {
                                requestWithoutHour(value);
                            })
                            .catch((e) => {
                                onFetchError(e);
                            });
                    };

                    const requestPageProps = {
                        first_day: createdRequestDate,
                        last_day: createdRequestDate,
                        location,
                    };

                    const requestWithoutHour = (value) => {
                        const data = {
                            start_at: moment(value, 'HH:mm').utc(false).format(),
                        };
                        axios({
                            method: 'POST',
                            url: `${BACKEND_URL}gt/customer/v2/delivery/update_itemworker_start_finish/${row.itemworker_id}`,
                            data,
                            headers: {
                                Authorization: `Bearer ${authorization.getToken()}`,
                            },
                        })
                            .then(() => {
                                dispatch(fetchRequests(requestPageProps));
                            })
                            .catch((e) => {
                                onFetchError(e);
                            });
                    };

                    const requestWithHour = (value) => {
                        const hour = row?.hours_worked || 0;
                        const minutes = getMinutesWithDinner(
                            moment(row.start).format('HH:mm'),
                            moment(row.start).add('h', hour).format('HH:mm')
                        );

                        const end = moment(row.start)?.add('minutes', minutes);
                        const _end = row.end ? moment(row.end) : end;

                        const v = getHoursWithDinner(value, _end.format('HH:mm'));

                        const data = {
                            worker_ids: [row.workerId],
                            request_id: pk,
                            item_id,
                            hours: v,
                        };

                        axios({
                            method: 'POST',
                            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                            data,
                            headers: {
                                Authorization: `Bearer ${authorization.getToken()}`,
                            },
                        }).then(() => {
                            requestWithoutHour(value);
                        });
                    };

                    return (row.itemworker_id && row.start) || row.status === 2 ? (
                        <TimeAction
                            updateRequestComment={updateRequestComment}
                            time={row.start ? moment(row.start) : null}
                            // disabled={!elem?.hours_worked && !elem.end}
                        />
                    ) : null;
                },
            },
            {
                title: 'Время окончания, факт',
                dataIndex: 'start',
                width: '50px',
                key: 'end',
                render: (start, row) => {
                    const requestPageProps = {
                        first_day: createdRequestDate,
                        last_day: createdRequestDate,
                        location,
                    };
                    const hour = row?.hours_worked || 0;
                    const minutes = getMinutesWithDinner(
                        moment(row.start).format('HH:mm'),
                        moment(row.start).add('h', hour).format('HH:mm')
                    );

                    const end = moment(row.start)?.add('minutes', minutes);

                    const updateRequestComment = (value) => {
                        requestWithoutHour(value);
                    };

                    const requestWithHour = (value) => {
                        const data = {
                            finish_at: moment(value, 'HH:mm').utc(false).format(),
                        };
                        axios({
                            method: 'POST',
                            url: `${BACKEND_URL}gt/customer/v2/delivery/update_itemworker_start_finish/${row.itemworker_id}`,
                            data,
                            headers: {
                                Authorization: `Bearer ${authorization.getToken()}`,
                            },
                        })
                            .then(() => {
                                dispatch(fetchRequests(requestPageProps));
                            })
                            .catch((e) => {
                                onFetchError(e);
                            });
                    };
                    const requestWithoutHour = (value) => {
                        const v = getHoursWithDinner(moment(row.start).format('HH:mm'), value);

                        const data = {
                            worker_ids: [row.workerId],
                            request_id: pk,
                            item_id,
                            hours: v,
                        };

                        axios({
                            method: 'POST',
                            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                            data,
                            headers: {
                                Authorization: `Bearer ${authorization.getToken()}`,
                            },
                        }).then(() => {
                            requestWithHour(value);
                        });
                    };

                    return row.itemworker_id && row.start && row.parentId ? (
                        <TimeAction
                            updateRequestComment={updateRequestComment}
                            time={row.end ? moment(row.end) : end}
                            notShow={!hour && !row.end}
                        />
                    ) : null;
                },
            },
            {
                title: (
                    <div
                        style={{
                            minWidth: '80px',
                        }}
                    >
                        <div>Кол-во</div>
                        <div>часов,</div>
                        <div>факт</div>
                    </div>
                ),
                dataIndex: 'hours_worked',
                width: '100px',
                key: 'hours_worked',
                render: (item, row) => {
                    const disabled = [
                        WorkerItemStatus.FinishPhotoRejected,
                        WorkerItemStatus.StartConfirmed,
                        WorkerItemStatus.Completed,
                    ].includes(row.status);
                    const submitFunction = async (value) => {
                        const data = {
                            worker_ids: [row.workerId],
                            request_id: pk,
                            item_id,
                            hours: value,
                        };
                        axios({
                            method: 'POST',
                            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/set_hours/`,
                            data,
                            headers: {
                                Authorization: `Bearer ${authorization.getToken()}`,
                            },
                        }).then(() => {
                            dispatch(
                                fetchRequests({
                                    first_day: createdRequestDate,
                                    last_day: createdRequestDate,
                                    location,
                                })
                            );
                        });
                    };

                    return (
                        <div
                            {...createActionsOnCell(row, 'hours_worked')}
                            style={{
                                ...createActionsOnCell(row, 'hours_worked').style,
                                justifyContent: 'center',
                            }}
                        >
                            {row.parentId && row.workerId ? (
                                <Editable
                                    submitFunction={submitFunction}
                                    field='customer_comment'
                                    pk={row.pk}
                                    item_pk={null}
                                    text={row.hours_worked}
                                    editable
                                    isEditable={!selectedRow.length && disabled}
                                    classNameInput={styles.hours_worked}
                                    className={styles.hours_workedWrapper}
                                />
                            ) : null}
                        </div>
                    );
                },
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                width: '100px',
                key: 'status',
                render: (status, row) => {
                    if (selectedRow.length)
                        return (
                            <span {...createActionsOnCell(row, 'status')}>
                                <span className={clsx(styles.status, getStatusClass(status))}>
                                    {row.parentId ? statuses?.[status] ?? statuses[-100] : null}
                                </span>
                            </span>
                        );
                    return (
                        <div {...createActionsOnCell(row, 'status')}>
                            <Status
                                row={row}
                                status={status}
                                createdRequestDate={createdRequestDate}
                                location={location}
                                pk={pk}
                                item_id={item_id}
                                setOpenD={setOpenD}
                            />
                        </div>
                    );
                },
            },
            {
                title: '',
                dataIndex: 'id',
                width: '68px',
                key: 'id',
                render: (status, row) => {
                    return row.parentId ? (
                        <RemoveButton
                            row={row}
                            pk={pk}
                            item_id={item_id}
                            createdRequestDate={createdRequestDate}
                            location={location}
                        />
                    ) : null;
                },
            },
        ],
        [
            rowData,
            onChecked,
            onCheckedAll,
            pk,
            createdRequestDate,
            worker_ids,
            item_id,
            location,
            mouseDown,
            selectedRow,
        ]
    );

    return (
        !!rowData?.length && (
            <Table
                className={styles.root}
                columns={columns}
                dataSource={rowData?.reduce((acc, i) => {
                    return [
                        ...acc,
                        ...(i?.children?.map((j) => ({ ...j, service_name: i.service_name })) ||
                            []),
                    ];
                }, [])}
                pagination={false}
                expandable={{
                    rowExpandable: () => false,
                    defaultExpandAllRows: true,
                    expandIcon: () => null,
                }}
            />
        )
    );
};

const Num = ({ pk, worker, slot, service, idsWorkers, createdRequestDate, location }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [page, setPage] = useState(1);

    const [workersOptions, setWorkersOptions] = useState({
        load: true,
        err: false,
        data: undefined,
    });

    const updateRouteOptions = async (value, page) => {
        setWorkersOptions((prev) => ({
            ...prev,
            load: true,
        }));

        const params = {
            q: value,
            forward: {
                delivery_request_pk: pk,
            },
            page,
        };

        const { data } = await axios({
            method: 'get',
            url: `${BACKEND_URL}bo/delivery/worker_autocomplete/`,
            params,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        });

        setWorkersOptions((prev) => ({
            ...prev,
            load: false,
            data: data.results.map((r) => ({
                text: `${r.personnel_number ? r.personnel_number : `ID${r.id}`} ${r.text}`,
                id: +r.id,
            })),
        }));
    };

    useEffect(() => {
        updateRouteOptions(value, page);
    }, [value, page]);

    const options = useMemo(() => {
        if (workersOptions.data) {
            return workersOptions.data.filter((i) => !idsWorkers?.includes(i.id) ?? true);
        }
        return workersOptions.data;
    }, [workersOptions.data, idsWorkers]);

    const onScroll = () => {
        setPage(1);
    };

    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setValue('');
        setFocus(false);
    }, [pk]);

    if (worker)
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    setFocus(true);
                }}
                className={styles.worker}
            >
                {worker ?? '-'}
            </div>
        );

    const saveWorker = async (id) => {
        if (id === -1 || !id) return;
        const data = {
            request: pk,
            worker: id,
            slot,
            service,
        };

        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/add/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        }).then(() => {
            setValue('');
            dispatch(
                fetchRequests({
                    first_day: createdRequestDate,
                    last_day: createdRequestDate,
                    location,
                })
            );
        });
    };

    return (
        <CustomAutocomplete
            key={pk}
            style={{ width: '200px' }}
            onIdChange={(id) => {
                setFocus(false);
                saveWorker(id);
            }}
            onChange={(value) => {
                setValue(value);
            }}
            onScroll={onScroll}
            className={styles.search_field}
            size='large'
            value={value}
            options={options}
            focus={focus}
            submitOnEnter
        />
    );
};

const RemoveButton = ({ row, pk, item_id, createdRequestDate, location }) => {
    const dispatch = useDispatch();
    const removeAction = () => {
        if (disabled) return;
        const data = {
            request: pk,
            item: item_id,
            worker: row.workerId,
        };

        axios({
            method: 'POST',
            url: `${BACKEND_URL}bo/delivery/request/item/worker/delete/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        }).then(() => {
            dispatch(
                fetchRequests({
                    first_day: createdRequestDate,
                    last_day: createdRequestDate,
                    location,
                })
            );
        });
    };
    const disabled = row?.status === WorkerItemStatus.Completed;

    return (
        <Button
            variant='outline-danger'
            size='sm'
            onClick={removeAction}
            className={styles.photoButton}
            disabled={disabled}
        >
            <img alt='icon' src='/delete_icon.svg' className={styles.buttonsContainerIcon1} />
        </Button>
    );
};

const Status = ({ row, status, createdRequestDate, location, pk, item_id, setOpenD }) => {
    const statusMemo = useMemo(() => {
        return row.parentId ? statuses?.[status] ?? statuses[-100] : null;
    }, [row.parentId, status]);

    const dispatch = useDispatch();

    const data = {
        worker_ids: [row.workerId],
        request_id: pk,
        item_id,
    };

    const actionMassAdd = () => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_add/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                dispatch(
                    fetchRequests({
                        first_day: createdRequestDate,
                        last_day: createdRequestDate,
                        location,
                    })
                );
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassConfirm = () => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_confirm/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                dispatch(
                    fetchRequests({
                        first_day: createdRequestDate,
                        last_day: createdRequestDate,
                        location,
                    })
                );
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassStart = () => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_start/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                dispatch(
                    fetchRequests({
                        first_day: createdRequestDate,
                        last_day: createdRequestDate,
                        location,
                    })
                );
            })
            .catch((e) => {
                onFetchError(e);
            });
    };
    const actionMassRemove = () => {
        axios({
            method: 'POST',
            url: `${BACKEND_URL}gt/customer/v2/delivery/request/worker/mass_remove/`,
            data,
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        })
            .then(() => {
                dispatch(
                    fetchRequests({
                        first_day: createdRequestDate,
                        last_day: createdRequestDate,
                        location,
                    })
                );
            })
            .catch((e) => {
                onFetchError(e);
            });
    };

    const menuItems = [
        {
            key: '1',
            text: 'Вернуть на адрес',
            action: actionMassAdd,
            disabled: ![
                WorkerItemStatus.Failed,
                WorkerItemStatus.Defect,
                WorkerItemStatus.Cancelled,
            ].includes(row.status),
        },
        {
            key: '2',
            text: 'Подтвердить',
            action: actionMassConfirm,
            disabled: ![WorkerItemStatus.New].includes(row.status),
        },
        {
            key: '3',
            text: 'Пришёл',
            action: actionMassStart,
            disabled: ![
                WorkerItemStatus.StartPhotoRejected,
                WorkerItemStatus.RequestConfirmedByWorker,
                WorkerItemStatus.SuspiciousAddress,
            ].includes(row.status),
        },
        {
            key: '4',
            text: 'Снять',
            action: actionMassRemove,
            disabled:
                [
                    WorkerItemStatus.Failed,
                    WorkerItemStatus.Defect,
                    WorkerItemStatus.Cancelled,
                ].includes(row.status) || row.status === undefined,
        },
    ];

    const menu = (
        <Menu>
            {menuItems.map((i) => (
                <Menu.Item key={`${i.key} ${i.disabled}`} disabled={i.disabled} onClick={i.action}>
                    {i.text}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown
            onVisibleChange={(v) => {
                setOpenD(v);
            }}
            overlay={menu}
            placement='left'
            trigger={['click']}
        >
            <span>
                <span className={clsx(styles.status, getStatusClass(status))}>{statusMemo}</span>
            </span>
        </Dropdown>
    );
};

const onFetchError = (error) => {
    let err = '';

    if (error?.response?.data?.messages) {
        for (const key in error?.response?.data?.messages) {
            const element = error?.response?.data?.messages[key];
            if (element.length) {
                err += ' ';
                if (Array.isArray(element) && element) {
                    err += `${key}: `;
                    err += element.join(' ');
                } else {
                    err += element;
                }
            }
        }
    }

    showError(err || error?.response?.data?.detail || error.message);
};

const showError = (error) => {
    if (error) {
        message.error({ content: error });
    }
};

const TimeAction = ({
    time: _time,
    // requestPageProps, disabled,
    updateRequestComment,
    notShow = false,
    disabled,
}) => {
    const _input = useRef(null);

    const [time, setTime] = useState(_time);
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTime(_time);
    }, [_time]);

    useEffect(() => {
        if (open) {
            _input?.current?.focus();
        }
    }, [open]);

    useEffect(() => {
        if (!open) {
            setValue('');
        }
    }, [open]);

    const onInputChange = (value) => {
        setValue(value);
    };

    // eslint-disable-next-line consistent-return
    const onKeyDownInputTimeStop = (e) => {
        if (e.key === 'Enter') {
            if (/\d\d:\d\d/.test(value)) {
                setValue('');
                setOpen(false);
                updateRequestComment(value);
            }
        } else {
            const key = +e.key;
            const value = e.target.value || '';
            const numbers = value.match(/[_0-9]/g);

            const selectionStart = e.target?.selectionStart;
            const selectionEnd = e.target?.selectionEnd;

            const numberIndex = {
                0: 0,
                1: 1,
                2: 2,
                3: 2,
                4: 3,
            };

            const index = numberIndex?.[e.target?.selectionStart];
            if (!Number.isNaN(key) && typeof index === 'number') {
                if (index === 0) {
                    if (
                        key > 2 ||
                        (!Number.isNaN(+numbers[1]) &&
                            +numbers[1] > 3 &&
                            key > 1 &&
                            !(selectionStart === 0 && selectionEnd >= 2))
                    ) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                }
                if (index === 1) {
                    if (!Number.isNaN(+numbers[0]) && +numbers[0] === 2 && key > 3)
                        e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
                if (index === 2) {
                    if (key > 5) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                }
            }
        }
    };

    const _show = time?.isValid() ? time.format('HH:mm') : '-';
    const show = !notShow ? _show : '-';

    if (!open || disabled)
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                title={show}
            >
                {show}
            </div>
        );

    return (
        <ReactInputMask
            onClick={(e) => {
                e.stopPropagation();
            }}
            mask='99:99'
            alwaysShowMask
            className={styles.ReactInputMaskTime}
            ref={_input}
            onKeyDown={onKeyDownInputTimeStop}
            onChange={(e) => onInputChange(e.target.value)}
            value={value}
            onBlur={() => {
                setOpen(false);
            }}
        />
    );
};
