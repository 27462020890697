import { Input } from '@components/Input';

import styles from './styles.module.scss';

const formatValue = ([intervalBegin, intervalEnd]) => {
    if (intervalBegin && intervalEnd) {
        if (intervalEnd?.includes('_')) {
            return `${intervalBegin}`;
        }
        return `${intervalBegin}-${intervalEnd}`;
    }

    return null;
};

const formatPlaceholder = (onlyOneValue) => {
    if (onlyOneValue) {
        return '00:00';
    }
    return `00:00 - 00:00`;
};

export const TimePopup = ({ setPopupOpen, value, onlyOneValue }) => {
    const onPopupOpen = () => {
        setPopupOpen(true);
    };

    return (
        <Input
            placeholder={formatPlaceholder(onlyOneValue)}
            onClick={onPopupOpen}
            value={formatValue(value || [])}
            className={styles.datePicker}
        />
    );
};
