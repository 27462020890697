import styled from 'styled-components';

import { FormItem as UIFormItem } from '@components';

export const ContainerFormItem = styled(UIFormItem)`
    & .ant-form-item-label {
        padding: 0;
    }

    & .ant-form-item-no-colon {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #b3b3b3;
    }

    &.ant-form-item {
        border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        padding: 7px 25px;
    }
`;
