import { useEffect, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { Button, Col, Collapse, Form, Input, Row, Space, Spin, Upload } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import styled from 'styled-components';

import { CameraOutlined } from '@ant-design/icons';
import { CustomAutocomplete, FormItem, NumberInput } from '@components';
import { DADATA_API_KEY } from '@utils';

import { DateViewer } from '../../../components/NewRequestPopup/DateViewer';
import { serviceRequiredRule } from '../../../components/NewRequestPopup/NewRequestMobilePopup/Controls';
import { getBase64, nameItemsList } from '../../../components/NewRequestPopup/utils';
import { calculateServicesCost, fetchServices } from '../../../store/actions';
import { transformToPossibleDisabledItem } from '../../HomePage/CreateReqModal/utils';

import { RequestOtherInfo } from './RequestOtherInfo';
import { TotalAmount } from './TotalAmount';

import styles from './CreateRequest.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        flex: 0 0 0 !important;
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

export const RequestGeneralInfo = ({ onFinish, onBack, form, initial }) => {
    const dispatch = useDispatch();
    const [dtPopupOpen, setDtPopupOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState('');
    const [initialValues, setInitialValues] = useState({ time: moment().format('HH:mm') });
    const LocationDisableCustomAutocomplete = transformToPossibleDisabledItem(
        CustomAutocomplete,
        'location',
        'disabled'
    );
    const [locationId, setLocationId] = useState(
        initial?.locationId || form.getFieldValue('locationId') || form.getFieldValue('location')
    );
    const [uploadedImages, setUploadedImages] = useState([]);
    const { list: locationsList } = useSelector((state) => state.locations);
    const { list: servicesList, isLoading: servicesLoading } = useSelector(
        (state) => state.services
    );
    const { data: calculatedServices } = useSelector((state) => state.calculate_services);
    const [searchLocationList, setSearchLocationList] = useState(locationsList);
    const [timeInputActive, setTimeInputActive] = useState(null);
    const requiredRule = [{ required: true, message: 'Это поле обязательно к заполнению' }];
    const [selectedServices, setSelectedServices] = useState([]);
    const date = form.getFieldValue('date1');
    const isDeliveryItem = !!initial?.routeId;

    const handlePreviewPhoto = async (file) => {
        if (!file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        file.status = 'success';
    };

    const handleChangePhoto = (data) => setUploadedImages(data.fileList);

    const renderServices = () => {
        return (
            <Form.List name='services'>
                {(fields, { add, remove }) => (
                    <>
                        <div className={styles.services}>
                            {(fields.length
                                ? fields
                                : [{ name: 0, key: 0, isListField: true, fieldKey: 0 }]
                            ).map((field, service_index) => (
                                <Row key={field.key}>
                                    <Col>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) =>
                                                prevValues.services !== curValues.services
                                            }
                                            rules={serviceRequiredRule}
                                        >
                                            {() => (
                                                <FormItem
                                                    label={nameItemsList.service}
                                                    name={[field.name, 'service']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            type: 'string',
                                                            message: '2',
                                                        },
                                                        (form) => {
                                                            const service =
                                                                form.getFieldsValue()?.services?.[
                                                                    field.name
                                                                ];
                                                            return {
                                                                message: '1',
                                                                type: 'string',
                                                                validator() {
                                                                    if (!service?.service) {
                                                                        return Promise.reject();
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            };
                                                        },
                                                    ]}
                                                >
                                                    <CustomAutocomplete
                                                        disabled={
                                                            !form.getFieldsValue().location &&
                                                            !isDeliveryItem
                                                        }
                                                        options={servicesList}
                                                        onChange={(value) => {
                                                            const selectedService =
                                                                selectedServices?.find(
                                                                    (j) =>
                                                                        j?.index === service_index
                                                                );
                                                            if (selectedService) {
                                                                setSelectedServices(
                                                                    selectedServices.map((item) => {
                                                                        if (
                                                                            item.index ===
                                                                            service_index
                                                                        ) {
                                                                            return {
                                                                                ...item,
                                                                                id: Number(
                                                                                    servicesList?.find(
                                                                                        (j) =>
                                                                                            j?.text ===
                                                                                            value
                                                                                    )?.id
                                                                                ),
                                                                            };
                                                                        }
                                                                        return item;
                                                                    })
                                                                );
                                                            } else
                                                                setSelectedServices([
                                                                    ...selectedServices,
                                                                    {
                                                                        id: Number(
                                                                            servicesList?.find(
                                                                                (j) =>
                                                                                    j?.text ===
                                                                                    value
                                                                            )?.id
                                                                        ),
                                                                        index: service_index,
                                                                    },
                                                                ]);
                                                        }}
                                                    />
                                                </FormItem>
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col>
                                        <FormItem
                                            label={nameItemsList.workers}
                                            name={[field.name, 'workers']}
                                            rules={requiredRule}
                                        >
                                            <NumberInput
                                                disabled={
                                                    !form.getFieldsValue().location &&
                                                    !isDeliveryItem
                                                }
                                                className={styles.form_input}
                                                onChange={(workers_required) => {
                                                    setSelectedServices(
                                                        selectedServices.map((item) => {
                                                            if (item.index === service_index) {
                                                                return {
                                                                    ...item,
                                                                    workers_required,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    );
                                                }}
                                            />
                                        </FormItem>
                                    </Col>

                                    <Col>
                                        <div
                                            className={styles.buttonsContainerIconDelete}
                                            onClick={() => {
                                                remove(field.name);
                                                setSelectedServices(
                                                    selectedServices.filter(
                                                        (item) => item.index !== service_index
                                                    )
                                                );
                                            }}
                                        >
                                            <img
                                                alt='icon'
                                                src='/delete_icon.svg'
                                                className={styles.buttonsContainerIcon1}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </div>

                        <div
                            onClick={() => {
                                if (locationId === -1) return;
                                add();
                            }}
                            className={styles.buttonsContainerItem}
                        >
                            <img
                                alt='icon'
                                src='/add_icon.svg'
                                className={styles.buttonsContainerIcon1}
                            />
                            <div>Добавить услугу</div>
                        </div>
                    </>
                )}
            </Form.List>
        );
    };

    const getInitialValues = () => {
        let location = initial?.location;
        if (!location && locationsList.length) {
            [location] = locationsList;
            setLocationId(locationsList[0].id);
        }

        return {
            ...initial,
            location: location?.text || undefined,
            address: initial?.address?.value || location?.address || '',
            date1: initial?.date ? moment(initial?.date) : undefined,
        };
    };

    const onTimeChanged = () => {
        if (form.getFieldValue('time')) {
            setTimeInputActive('time');
        } else if (form.getFieldValue('time_interval')) {
            setTimeInputActive('time_interval');
        } else setTimeInputActive(null);
    };

    useEffect(() => {
        if (locationsList.length && isLoaded === '') setIsLoaded('location');
        if (servicesList.length) {
            if (servicesList.length === 1) {
                setInitialValues({
                    ...initialValues,
                    services: [{ ...servicesList[0], service: servicesList[0].text }],
                });
                setSelectedServices([{ id: servicesList[0].id, index: 0 }]);
            }
            setIsLoaded('final');
        } else if (servicesList.length === 0 && !servicesLoading && isLoaded === 'location')
            setIsLoaded('final');
    }, [servicesList, locationsList]);

    useEffect(() => {
        if (isLoaded === 'location') setInitialValues(getInitialValues());
    }, [isLoaded]);

    useEffect(async () => {
        if (locationId) {
            const updateServicesOptions = async () => {
                if (locationId === -1) return;
                await dispatch(
                    fetchServices({
                        locationId,
                    })
                );
            };
            form.setFieldsValue({ locationId });
            await updateServicesOptions();
        }
    }, [locationId]);

    useEffect(() => {
        setSearchLocationList(locationsList);
    }, [locationsList]);

    useEffect(async () => {
        const servicesToCheck = selectedServices.filter((item) => item.workers_required);
        await dispatch(
            calculateServicesCost(
                {},
                {
                    services: servicesToCheck,
                    location: locationId,
                    date: moment(date).format('YYYY-MM-DD'),
                }
            )
        );
    }, [selectedServices, locationId, date]);

    useEffect(() => {
        if (isLoaded === 'final') {
            const selectedServicesRestored = [];
            form.getFieldValue('services')?.forEach((service_item, index) => {
                selectedServicesRestored.push({
                    id: Number(servicesList?.find((j) => j?.text === service_item?.service)?.id),
                    workers_required: service_item?.workers,
                    index,
                });
            });
            setSelectedServices(selectedServicesRestored);
        }
    }, [isLoaded]);

    useEffect(() => {
        if (initial?.locationId) setLocationId(initial.locationId);
        if (initial?.photos) setUploadedImages(initial.photos.fileList);
    }, [initial]);

    return isLoaded === 'final' ? (
        <Form
            form={form}
            layout='vertical'
            className={styles.requisitesForm}
            initialValues={initialValues}
        >
            <div className={styles.requisites}>
                <Space direction='vertical'>
                    <Text className={styles.requisitesTitle}>
                        {!isDeliveryItem ? 'Данные по заявке' : 'Добавить адрес'}
                    </Text>
                </Space>
                <Space direction='vertical'>
                    {!isDeliveryItem && (
                        <FormItem
                            name='date1'
                            label={nameItemsList.date}
                            rules={requiredRule}
                            isLabelVertical
                            disabled={false}
                        >
                            <DateViewer
                                date={form.getFieldValue('date1')}
                                setPopupOpen={() => setDtPopupOpen(true)}
                                routeId={-1}
                            />
                            <ShortPopup
                                modal
                                open={dtPopupOpen}
                                onClose={() => setDtPopupOpen(false)}
                                closeOnDocumentClick
                            >
                                {(close) => {
                                    return (
                                        <Form.Item name='date2' valuePropName='date'>
                                            <Calendar
                                                onChange={(v) => {
                                                    form.setFieldsValue({ date1: v });
                                                    close();
                                                }}
                                                locale={locales.ru}
                                                color='#000000'
                                                rangeColors={['#000000']}
                                            />
                                        </Form.Item>
                                    );
                                }}
                            </ShortPopup>
                        </FormItem>
                    )}
                    <Form.Item noStyle>
                        <Row className={styles.dateRow}>
                            <Col>
                                <FormItem label='Время' name='time'>
                                    <InputMask
                                        disabled={timeInputActive === 'time_interval'}
                                        onChange={onTimeChanged}
                                        mask='99:99'
                                        placeholder='00:00'
                                        className={`ant-input ant-input-lg ${styles.formInput}`}
                                    />
                                </FormItem>
                            </Col>
                            <Col className={styles.labelOr}>
                                <div>ИЛИ</div>
                            </Col>
                            <Col>
                                <FormItem
                                    label='Интервал'
                                    name='time_interval'
                                    // rules={requiredRule}
                                >
                                    <InputMask
                                        disabled={timeInputActive === 'time'}
                                        onChange={onTimeChanged}
                                        mask='99:99-99:99'
                                        placeholder='00:00-00:00'
                                        className={`ant-input ant-input-lg ${styles.formInput}`}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item noStyle>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name='locationId' hidden />
                                {!isDeliveryItem && (
                                    <FormItem
                                        label={nameItemsList.location}
                                        rules={requiredRule}
                                        name='location'
                                        hidden={isDeliveryItem}
                                    >
                                        <LocationDisableCustomAutocomplete
                                            options={searchLocationList}
                                            onIdChange={setLocationId}
                                            defaultId={locationId ?? -1}
                                            onChange={(value) =>
                                                setTimeout(
                                                    () =>
                                                        setSearchLocationList(
                                                            locationsList?.filter((location) =>
                                                                location.text.includes(value)
                                                            )
                                                        ),
                                                    50
                                                )
                                            }
                                        />
                                    </FormItem>
                                )}
                            </Col>
                        </Row>
                    </Form.Item>
                </Space>
                <Space direction='vertical'>
                    {renderServices()}
                    <FormItem
                        label={nameItemsList.shipment_type}
                        name='shipment_type'
                        rules={requiredRule}
                        className={styles.addressSuggestions}
                    >
                        <Input />
                    </FormItem>
                    <FormItem label={nameItemsList.address} name='address' rules={requiredRule}>
                        <AddressSuggestions
                            defaultQuery={initial?.address?.value || initialValues.address || ''}
                            token={DADATA_API_KEY}
                            containerClassName={styles.addressSuggestions}
                            customInput={Input}
                            inputProps={{
                                className: styles.form_input,
                            }}
                        />
                    </FormItem>
                    <div className={styles.uploadPhotoContainer}>
                        <Form.Item name='photos'>
                            <Upload
                                fileList={uploadedImages}
                                name='file'
                                listType='picture-card'
                                onPreview={handlePreviewPhoto}
                                onChange={handleChangePhoto}
                                multiple
                            >
                                <CameraOutlined className={styles.cameraIcon} />
                                Добавить
                            </Upload>
                        </Form.Item>
                    </div>
                </Space>
                <Space direction='vertical'>
                    {calculatedServices && <TotalAmount calculatedAmount={calculatedServices} />}
                    <Collapse>
                        <Collapse.Panel key='other' header='Необязательные данные'>
                            <RequestOtherInfo form={form} initial={initial} />
                        </Collapse.Panel>
                    </Collapse>
                </Space>
                <Space direction='horizontal' className={styles.footer}>
                    <Button className={styles.backButton} type='ghost' onClick={onBack}>
                        Назад
                    </Button>
                    <Button
                        className={styles.nextButton}
                        type='primary'
                        onClick={async () => {
                            await form.validateFields();
                            onFinish();
                        }}
                    >
                        Далее
                    </Button>
                </Space>
            </div>
        </Form>
    ) : (
        <Spin spinning={!isLoaded} className={styles.loader} />
    );
};
