import { useLayoutEffect, useState } from 'react';
import { ResizeSensor } from 'css-element-queries';

export const useElementSize = (element) => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([element.clientWidth, window.clientHeight]);
        }

        if (element) {
            const resize = new ResizeSensor(element, updateSize);
            updateSize();

            return () => {
                resize.detach(updateSize);
            };
        }

        return () => {};
    }, [element]);

    return size;
};
