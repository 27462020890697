import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { accountInfo, finishRegistration } from '@store/actions';
import { authorization } from '@utils';

export const FinishRegistration = ({ match, location }) => {
    const dispatch = useDispatch();
    const token = authorization.getToken();
    const { from } = (location && location.state) || { from: { pathname: '/info' } };
    const { uid } = match.params;
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        (async () => {
            if (!token) {
                const res = await dispatch(finishRegistration({ uid }));

                localStorage.setItem('token', res.payload.data.access_token);
                await dispatch(accountInfo({ token: res.payload.data.access_token }));
            }

            setRedirect(true);
        })();
    }, []);

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: from.pathname,
                    state: { from: '/finish_registration' },
                }}
            />
        );
    }

    return null;
};
