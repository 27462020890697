const { useEffect, useCallback, useState } = require('react');

export const useItemStatus = () => {
    const [itemStatus, setItemStatus] = useState(null);

    useEffect(() => {
        if (itemStatus && itemStatus !== 'loading') {
            const timer = setTimeout(() => {
                setItemStatus(null);
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        }

        return () => {};
    }, [itemStatus]);

    const onError = useCallback(() => {
        setItemStatus('error');
    }, []);
    const onSuccess = useCallback(() => {
        setItemStatus('success');
    }, []);

    const onLoading = useCallback(() => {
        setItemStatus('loading');
    }, []);

    return { itemStatus, onError, onSuccess, onLoading };
};
