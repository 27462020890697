import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';

import {
    ButtonsContainer,
    CreateInvoiceButton,
    DatePickerApply,
    Pagination,
    SearchWrapper,
    TableWrapper,
    Wrapper,
} from '@components';
import { fetchInvoices } from '@store/actions';
import { authorization, BALANCE_PREFIX, downloadFile, ITEMS_ON_PAGE, storageService } from '@utils';

import styles from './styles.module.scss';

const localStorageService = storageService(BALANCE_PREFIX);

export const BalancePage = memo(() => {
    const dispatch = useDispatch();
    const [itemsOnPage, setItemsOnPage] = useState(
        localStorageService.get('itemsOnPage', ITEMS_ON_PAGE)
    );
    const { invoicesList, isInvoicesLoading } = useSelector((state) => ({
        invoicesList: state.invoices.list,
        isInvoicesLoading: state.invoices.isLoading,
    }));
    const [page, setPage] = useState(1);
    const pages = Math.ceil(invoicesList.length / itemsOnPage);

    const updateData = _.throttle(updateIvoices, 500);

    function updateIvoices() {
        dispatch(
            fetchInvoices({
                token: authorization.getToken(),
                search: localStorageService.get('search'),
                firstDay: moment(localStorageService.get('startRanges')).format('DD.MM.YYYY'),
                lastDay: moment(localStorageService.get('endRanges')).format('DD.MM.YYYY'),
            })
        );
    }

    useEffect(() => {
        updateData();
    }, []);

    useEffect(() => {
        localStorageService.set('itemsOnPage', itemsOnPage);
    }, [itemsOnPage]);

    return (
        <Wrapper title='Баланс'>
            <SearchWrapper
                placeHolder='Найти счет'
                localStorageService={localStorageService}
                updateData={updateData}
                itemsOnPage={itemsOnPage}
                setItemsOnPage={setItemsOnPage}
            />

            <ButtonsContainer
                left={
                    <DatePickerApply
                        localStorageService={localStorageService}
                        updateData={updateData}
                    />
                }
                right={<CreateInvoiceButton updateData={updateData} />}
            />

            <TableWrapper
                isLoading={isInvoicesLoading}
                head={
                    <tr>
                        <td>
                            <div>Дата</div>
                        </td>
                        <td>
                            <div>Номер</div>
                        </td>
                        <td>
                            <div>Сумма</div>
                        </td>
                        <td>
                            <div>Скачать</div>
                        </td>
                    </tr>
                }
                body={invoicesList
                    .slice(itemsOnPage * (page - 1), itemsOnPage * (page - 1) + itemsOnPage)
                    .map((elem, i) => (
                        <tr key={i}>
                            <td>
                                <div>{moment(elem.timestamp).format('DD.MM.YYYY')}</div>
                            </td>
                            <td>
                                <div>{elem.number}</div>
                            </td>
                            <td>
                                <div>{elem.amount}&nbsp;&#8381;</div>
                            </td>
                            <td>
                                <div>
                                    <div>
                                        <div
                                            style={{ display: 'inline-block' }}
                                            className={styles.link}
                                            onClick={downloadFile({
                                                url: elem.file.url,
                                                filename: elem.file.name,
                                            })}
                                        >
                                            {elem.file.name}
                                        </div>{' '}
                                        <span>({elem.file.size})</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
            />

            <Pagination pages={pages} onPageChange={(p) => setPage(p)} />
        </Wrapper>
    );
});
