import { useLayoutEffect, useState } from 'react';

export const useElementScroll = (element) => {
    const [scroll, setScroll] = useState(0);

    useLayoutEffect(() => {
        function updateScroll() {
            setScroll(element.scrollLeft);
        }

        if (element) {
            element.addEventListener('scroll', updateScroll);
            updateScroll();

            return () => element.removeEventListener('scroll', updateScroll);
        }
        return () => {};
    }, [element]);

    return scroll;
};
