import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

export const store = createStore(
    reducers,
    process.env.NODE_ENV === 'production' || !window?.__REDUX_DEVTOOLS_EXTENSION__
        ? compose(applyMiddleware(thunk))
        : compose(
              applyMiddleware(thunk),
              window?.__REDUX_DEVTOOLS_EXTENSION__ && window?.__REDUX_DEVTOOLS_EXTENSION__?.()
          )
);
