import axios from 'axios';

import { authorization, BACKEND_URL } from '@utils';

export async function downloadImg(url, setLoading) {
    if (!url) return '';
    const token = authorization.getToken();
    return axios({
        method: 'get',
        url: `${BACKEND_URL}${url[0] === '/' ? url.replace('/', '') : url}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
    })
        .then((response) => {
            if (response.data !== null && response.data.size > 2) {
                const picture = new Blob([response.data]);
                setLoading?.(false);
                return picture;
            }
            return null;
        })
        .catch((error) => {
            console.error(error);
            setLoading?.(false);
            return error;
        });
}
