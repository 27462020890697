import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import moment from 'moment';
import _ from 'underscore';

import {
    ButtonsContainer,
    CustomSelectWithStorage,
    DatePickerApply,
    DownloadExcelButton,
    ImportsButton,
    Pagination,
    SearchWrapper,
    TableWrapper,
    Wrapper,
} from '@components';
import { fetchImports } from '@store/actions';
import { downloadFile, IMPORTS_PREFIX, ITEMS_ON_PAGE, storageService } from '@utils';

import styles from './styles.module.scss';

const IMPORTS_STATUSES = [
    { id: 'processing', title: 'Формируется', class: 'import-status-processing' },
    {
        id: 'finished_with_errors',
        title: 'С ошибками',
        class: 'import-status-finished_with_errors',
    },
    { id: 'finished', title: 'Готово', class: 'import-status-finished' },
];

const localStorageService = storageService(IMPORTS_PREFIX);

export const ImportHistoryPage = () => {
    const dispatch = useDispatch();
    const { importsList, isImportsLoading, userAllowRequestCreation } = useSelector((state) => ({
        importsList: state.imports.list,
        isImportsLoading: state.imports.isLoading,
        userAllowRequestCreation: state.user.info.allow_requests_creation,
    }));
    const [itemsOnPage, setItemsOnPage] = useState(
        localStorageService.get('itemsOnPage', ITEMS_ON_PAGE)
    );
    const [page, setPage] = useState(1);
    const pages = Math.ceil(importsList.length / itemsOnPage);

    function getStatusClass(status) {
        const findStatus = IMPORTS_STATUSES.find((item) => item.id === status.id);
        let statusClass = 'import-status-default';
        if (findStatus && 'class' in findStatus) {
            statusClass = findStatus.class;
        }

        return `${styles['import-status']} ${styles[statusClass]}`;
    }

    const updateData = _.throttle(updateImports, 500);
    function updateImports() {
        dispatch(
            fetchImports({
                status: localStorageService.get('status'),
                search_text: localStorageService.get('search'),
                first_ay: moment(localStorageService.get('startRanges')).format('DD.MM.YYYY'),
                last_ay: moment(localStorageService.get('endRanges')).format('DD.MM.YYYY'),
            })
        );
    }

    useEffect(() => {
        updateData();
    }, []);

    useEffect(() => {
        localStorageService.set('itemsOnPage', itemsOnPage);
    }, [itemsOnPage]);

    return (
        <Wrapper title='История импорта'>
            <SearchWrapper
                placeHolder='Найти импорт заявок'
                localStorageService={localStorageService}
                updateData={updateData}
                itemsOnPage={itemsOnPage}
                setItemsOnPage={setItemsOnPage}
            />

            <ButtonsContainer
                left={
                    <Space size='middle'>
                        <DatePickerApply
                            localStorageService={localStorageService}
                            updateData={updateData}
                        />

                        <CustomSelectWithStorage
                            options={IMPORTS_STATUSES.map(({ title, id }) => ({
                                label: title,
                                value: id,
                            }))}
                            placeholder='Статус импорта'
                            localStorageService={localStorageService}
                            updateData={updateData}
                            optionName='status'
                            size='large'
                            width={180}
                            allowClear
                        />
                    </Space>
                }
                right={
                    <>
                        <ImportsButton
                            allow_requests_creation={userAllowRequestCreation}
                            updateData={updateData}
                        />
                        <DownloadExcelButton />
                    </>
                }
            />

            <TableWrapper
                isLoading={isImportsLoading}
                head={
                    <tr>
                        <td>
                            <div>Дата</div>
                        </td>
                        <td>
                            <div>Файл с заявками</div>
                        </td>
                        <td>
                            <div>Отчет</div>
                        </td>
                        <td>
                            <div>Статус</div>
                        </td>
                    </tr>
                }
                body={importsList
                    .slice(itemsOnPage * (page - 1), itemsOnPage * (page - 1) + itemsOnPage)
                    .map((elem, i) => (
                        <tr key={i}>
                            <td>
                                <div>{elem.date}</div>
                            </td>
                            <td>
                                <div>
                                    <div>
                                        <div
                                            style={{ display: 'inline-block' }}
                                            className={styles.link}
                                            onClick={downloadFile({
                                                url: elem.file.url,
                                                filename: elem.file.name,
                                            })}
                                        >
                                            {elem.file.name}
                                        </div>{' '}
                                        <span>({elem.file.size})</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    {elem.report ? (
                                        <div>
                                            <div
                                                style={{ display: 'inline-block' }}
                                                className={styles.link}
                                                onClick={downloadFile({
                                                    url: elem.report.url,
                                                    filename: elem.report.name,
                                                })}
                                            >
                                                {elem.report.name}
                                            </div>{' '}
                                            <span>({elem.report.size})</span>
                                        </div>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className={getStatusClass(elem.status)}>
                                    {elem.status.text}
                                </div>
                            </td>
                        </tr>
                    ))}
            />

            <Pagination pages={pages} onPageChange={(p) => setPage(p)} />
        </Wrapper>
    );
};
