import styles from './styles.module.scss';

export const FormItemHelpers = ({ status, children, isRenderChildren }) => {
    if (status === 'loading') {
        return (
            <div className={styles.wrapper}>
                <div className={styles.loading}>
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    }

    if (isRenderChildren) {
        return children;
    }

    if (status === 'success') {
        return (
            <div className={styles.wrapper}>
                <div className={styles.success}>
                    <img alt='icon' src='/success_icon.svg' />
                </div>
            </div>
        );
    }

    if (status === 'error') {
        return (
            <div className={styles.wrapper}>
                <div className={styles.error}>
                    <img alt='icon' src='/cross_icon.svg' />
                </div>
            </div>
        );
    }

    return null;
};
