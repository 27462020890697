import classnames from 'clsx';

import styles from './styles.module.scss';

export const Label = ({ children, required, className, noWrap }) => {
    return (
        <div
            required={required}
            className={classnames(styles.label, className, {
                [styles.noWrap]: noWrap,
            })}
        >
            {children}
        </div>
    );
};
