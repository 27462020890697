import { useRef } from 'react';
import clsx from 'clsx';

import { ScrollSlider } from '@components/ScrollSlider';
import { Spinner } from '@components/Spinner';

import styles from './styles.module.scss';

export const TableRequestsWrapper = ({
    head,
    body,
    isLoading,
    className,
    classNameTableWrapper,
    pagination,
    lead,
}) => {
    const wrapRef = useRef(null);
    const contentRef = useRef(null);

    return (
        <div className={clsx(styles.tableWrapper, classNameTableWrapper)} ref={wrapRef}>
            {lead}

            <table className={`${styles.table} ${className}`} ref={contentRef}>
                <thead>{head}</thead>
                <tbody>{body}</tbody>
            </table>

            {isLoading && <Spinner />}

            <ScrollSlider wrapRef={wrapRef} contentRef={contentRef}>
                {pagination}
            </ScrollSlider>
        </div>
    );
};
