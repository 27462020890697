import { useSelector } from 'react-redux';

import { Spinner, Wrapper } from '@components';

import { Filters, Map } from './components';

export const RequestsOnMapPage = () => {
    const isRequestOnMapLoading = useSelector((state) => state.requests_on_map.isLoading);

    return (
        <Wrapper title='Заявки на карте'>
            <Filters />
            <Map />
            {isRequestOnMapLoading && <Spinner />}
        </Wrapper>
    );
};
