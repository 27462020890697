import { memo, useEffect, useState } from 'react';
import clsx from 'clsx';

import { FormItemHelpers } from '@components/FormItemHelpers';
import { updateAdressRequest, updateRequest } from '@store/actions';

import styles from './styles.module.scss';

export const Editable = memo(
    ({
        text,
        onSuccess,
        onEnterSuccess,
        pk,
        field,
        item_pk,
        editable,
        submitFunction,
        inputComponent,
        focused,
        maxLength,
        notOpenedClassName,
        onlyNumber,
        sendRequestOnBlur,
        isRequiredStatus,
        classNameInput,
        isEditable = true,
        withUndefinedValue,
        className,
    }) => {
        const currentText = text === null ? '' : text;

        const [value, setValue] = useState(text === null ? '' : text);
        const [opened, setOpened] = useState(focused);
        const [status, setStatus] = useState(''); // '' (default) | loading | success | error
        const [warning, setWarning] = useState(false);
        const [warningNumber, setWarningNumber] = useState(false);

        useEffect(() => setOpened(focused), [focused]);

        useEffect(() => {
            if (currentText || withUndefinedValue) {
                setValue(currentText);
            }
        }, [currentText]);

        function showSuccess() {
            setStatus('success');
            setTimeout(() => {
                setStatus('');
            }, 1000);
        }

        function showError() {
            setStatus('error');
            setTimeout(() => {
                setStatus('');
            }, 1000);
        }
        async function handleSubmit(val, isEnter = false) {
            try {
                setOpened(false);
                setStatus('loading');

                if (submitFunction) {
                    await submitFunction(val !== undefined ? val : value, setValue);

                    if (isEnter) {
                        if (onEnterSuccess) {
                            onEnterSuccess(value);
                        }
                    }
                } else if (item_pk) {
                    await updateAdressRequest(
                        {},
                        {
                            [field]: val !== undefined ? `${val}` : value,
                            request: pk,
                            item: item_pk,
                        }
                    );
                } else {
                    await updateRequest()(
                        {},
                        {
                            [field]: val !== undefined ? `${val}` : value,
                            request: pk,
                        }
                    );
                }

                showSuccess();

                if (onSuccess) {
                    onSuccess(value);
                }
            } catch (e) {
                showError();
            }
        }

        if (status) {
            return (
                <FormItemHelpers status={status} isRenderChildren={editable}>
                    <div
                        style={{ overflow: 'hidden', currentTextOverflow: 'ellipsis' }}
                        title={currentText}
                    >
                        {currentText}
                    </div>
                </FormItemHelpers>
            );
        }

        if (!opened) {
            return (
                <span
                    onClick={(e) => {
                        e.stopPropagation();

                        setValue(currentText);
                        if (isEditable) setOpened(true);
                    }}
                    className={`${styles.notOpened} ${notOpenedClassName}`}
                    style={{
                        border: isRequiredStatus ? '2px solid #FD1B1BAA' : 'none',
                        borderRadius: 5,
                    }}
                    title={value}
                >
                    {value || '-'}
                </span>
            );
        }
        const onBlur = () => {
            setOpened(false);

            if (sendRequestOnBlur) {
                handleSubmit();
            }
        };
        return (
            <div className={clsx(styles.wrapper, className)}>
                {inputComponent ? (
                    inputComponent(value, setValue, handleSubmit, onBlur, setOpened)
                ) : (
                    <input
                        onBlur={onBlur}
                        className={classNameInput}
                        autoFocus
                        spellCheck={false}
                        value={value}
                        onChange={(e) => {
                            if (
                                e.target.value &&
                                onlyNumber &&
                                !/^[0-9]+$/gm.test(e.target.value)
                            ) {
                                setWarningNumber(true);
                                return;
                            }
                            setWarningNumber(false);

                            if (
                                e.target.value.length <= maxLength ||
                                maxLength === null ||
                                maxLength === undefined
                            ) {
                                setValue(e.target.value);
                                setWarning(false);
                            } else {
                                setWarning(true);
                            }
                        }}
                        onKeyDown={({ key }) => key === 'Enter' && handleSubmit(undefined, true)}
                        style={{
                            background: warning ? '#F44336' : '#F2F2F2',
                        }}
                    />
                )}
                <div className={styles.tooltip}>
                    {(() => {
                        if (warningNumber) {
                            return `Разрешены только цифры`;
                        }

                        if (warning) {
                            return `Максимальная длина: ${maxLength} знаков`;
                        }

                        return null;
                    })()}
                </div>
            </div>
        );
    }
);
