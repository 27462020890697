import { Input } from 'antd';
import styled from 'styled-components';

export const TextArea = styled(Input.TextArea)`
    &.ant-input-status-error {
        background: var(--color-gray) !important;
        border: 1px solid var(--color-warning) !important;
    }

    &.ant-input {
        background: var(--color-gray);
        border: none;
        border-radius: 5px;
    }

    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }

    &:hover {
        border: none;
    }
`;
