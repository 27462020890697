export function setSelectedMapRequestId(id) {
    return (dispatch) => {
        dispatch({ type: 'SET_SELECTED_MAP_REQUEST', id });
    };
}

export function setSelectedMapRequestType(request_type) {
    return (dispatch) => {
        dispatch({ type: 'SET_SELECTED_MAP_REQUEST_TYPE', request_type });
    };
}
