import { useCallback, useState } from 'react';
import {
    AddressSuggestions as UIAddressSuggestions,
    BankSuggestions as UIBankSuggestions,
} from 'react-dadata';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Space, Typography } from 'antd';
import clsx from 'clsx';

import { Select } from '@components';
import { withDadata } from '@hocs';
import { updateOrganization } from '@store/actions';
import { DADATA_API_KEY } from '@utils';

import { platformSelector } from '../../../store/reducers/platform';
import { isAllFieldsFilled, legalEntity, transformDadata } from '../utils';
import { ViewChangingFormItem } from '../ViewChangingFormItem';

import { Controls } from './Controls';

import styles from '../styles.module.scss';

const blockerRegistrationStatuses = ['confirmation_required', 'completed'];

const requiredRule = [
    {
        required: true,
        message: 'Это поле обязательно!',
    },
];

export const UnfilledForm = ({ openInfoPopup, form }) => {
    const registrationStatus = useSelector((state) => state.user.info.registrationStatus);
    const platform = useSelector(platformSelector);
    const [currentFocusName, setCurrentFocusName] = useState(null);
    const dispatch = useDispatch();

    const onFocusNextItem = useCallback(
        (currentName) => () => {
            setCurrentFocusName(currentName);
        },
        []
    );

    const updateFields = async ({ onLoading, onError, onSuccess }) => {
        try {
            const { entity, legal_address, ...formValues } = form.getFieldsValue();

            onLoading();

            await dispatch(
                updateOrganization({
                    ...formValues,
                    is_legal_entity: entity === legalEntity,
                    legal_address: legal_address?.value,
                })
            );

            onSuccess();

            if (isAllFieldsFilled(form.getFieldsValue())) {
                openInfoPopup({
                    content:
                        'Данные сохранены.\n' +
                        '\n' +
                        'Для начала работы скачайте заполненный договор, распечатайте и подпишите.\n' +
                        'Подписанный скан загрузите нам, чтобы мы могли активировать создание заявок.',
                    title: 'Результат',
                });
            }
        } catch ({ error }) {
            onError();
            openInfoPopup({
                open: true,
                content:
                    error.response.data.detail ||
                    `${error.response.status} ${error.response.statusText}`,
                title: 'Ошибка',
            });
        }
    };

    const getNextFocusTaxNumber = () => {
        const entity = form.getFieldValue('entity');

        if (entity === legalEntity) {
            return 'reason_code';
        }

        return 'bank_account';
    };

    const hideOnNonLegalEntity = () => {
        const entity = form.getFieldValue('entity');

        return entity !== legalEntity;
    };

    const formBlockerStyles = clsx({
        [styles.form_blocker]: blockerRegistrationStatuses.includes(registrationStatus),
    });

    const BankSuggestions = withDadata(UIBankSuggestions);
    const AddressSuggestions = withDadata(UIAddressSuggestions);

    return (
        <Form labelWrap form={form} className={styles.form}>
            <Space
                className={styles.form_container}
                direction='vertical'
                size={platform === 'mobile' ? 'small' : 'large'}
            >
                <Typography.Title className={styles.smallTitle} level={4}>
                    Данные об организации
                </Typography.Title>

                <Form.Item className={formBlockerStyles}>
                    <ViewChangingFormItem
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('full_name')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='entity'
                        label='ООО/ИП'
                        rules={requiredRule}
                        renderContent={({ onBlur, onFinish }) => {
                            return (
                                <Select
                                    autoFocus
                                    onSelect={onFinish}
                                    onBlur={onBlur}
                                    options={[{ value: 'ООО' }, { value: 'ИП' }]}
                                    size='middle'
                                    placeholder='Выбрать'
                                />
                            );
                        }}
                    />

                    <ViewChangingFormItem
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('ceo')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='full_name'
                        label='Наименование'
                        rules={requiredRule}
                    />

                    <ViewChangingFormItem
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('email')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='ceo'
                        label='Генеральный Директор'
                        rules={requiredRule}
                    />

                    <ViewChangingFormItem
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('phone')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='email'
                        label='Электронная почта'
                        rules={requiredRule}
                    />

                    <ViewChangingFormItem
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('tax_number')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='phone'
                        label='Телефон'
                        rules={requiredRule}
                    />

                    <ViewChangingFormItem
                        dependencies={['entity']}
                        type='number'
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem(getNextFocusTaxNumber())}
                        rules={({ entity }) => {
                            const currentMax = entity === legalEntity ? 10 : 12;

                            return [
                                ...requiredRule,
                                {
                                    max: currentMax,
                                    message: `Максимум ${currentMax} символов!`,
                                },
                            ];
                        }}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='tax_number'
                        label='Инн'
                    />

                    <ViewChangingFormItem
                        dependencies={['entity']}
                        type='number'
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('bank_account')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        rules={({ entity }) => {
                            const currentRule = {
                                max: 9,
                                message: 'Максимум 9 символов!',
                            };

                            if (entity === legalEntity) {
                                return [...requiredRule, currentRule];
                            }

                            return [currentRule];
                        }}
                        hidden={hideOnNonLegalEntity()}
                        name='reason_code'
                        label='Кпп'
                    />

                    <ViewChangingFormItem
                        dependencies={['bank_name']}
                        type='number'
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('bank_name')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='bank_account'
                        label='Р/C'
                        rules={[
                            ...requiredRule,
                            {
                                max: 20,
                                message: 'Максимум 20 символов!',
                            },
                        ]}
                    />
                    <ViewChangingFormItem
                        dependencies={[
                            'bank_account',
                            'bank_identification_code',
                            'correspondent_account',
                        ]}
                        onFinish={updateFields}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        transViewText={transformDadata}
                        form={form}
                        name='bank_name'
                        label='Банк'
                        rules={requiredRule}
                        renderContent={({ onBlur, onFinish }) => {
                            return (
                                <BankSuggestions
                                    inputProps={{
                                        autoFocus: true,
                                        onBlur,
                                    }}
                                    onChange={(address) => {
                                        if (typeof address !== 'string') {
                                            form.setFieldsValue({
                                                bank_name: address?.value,
                                                bank_identification_code: address?.data?.bic,
                                                correspondent_account:
                                                    address.data.correspondent_account,
                                            });
                                        }

                                        onFinish();
                                    }}
                                    token={DADATA_API_KEY}
                                    containerClassName={styles.addressSuggestions}
                                />
                            );
                        }}
                    />

                    <ViewChangingFormItem
                        dependencies={['bank_name']}
                        type='number'
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('bank_identification_code')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='correspondent_account'
                        label='К/С'
                        rules={[
                            ...requiredRule,
                            {
                                max: 20,
                                message: 'Максимум 20 символов!',
                            },
                        ]}
                    />

                    <ViewChangingFormItem
                        dependencies={['bank_name']}
                        onFinish={updateFields}
                        onFocusNextItem={onFocusNextItem('legal_address')}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='bank_identification_code'
                        label='Бик'
                        type='number'
                        rules={[
                            ...requiredRule,
                            {
                                max: 9,
                                message: 'Максимум 9 символов!',
                            },
                        ]}
                    />

                    <ViewChangingFormItem
                        transViewText={transformDadata}
                        onFinish={updateFields}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        name='legal_address'
                        label='Юр. Адресс'
                        rules={requiredRule}
                        renderContent={({ onBlur, onFinish }) => {
                            return (
                                <AddressSuggestions
                                    inputProps={{
                                        autoFocus: true,
                                        onBlur,
                                    }}
                                    onChange={onFinish}
                                    token={DADATA_API_KEY}
                                    containerClassName={styles.addressSuggestions}
                                />
                            );
                        }}
                    />

                    <ViewChangingFormItem
                        onFinish={updateFields}
                        currentFocusName={currentFocusName}
                        setCurrentFocusName={setCurrentFocusName}
                        form={form}
                        rules={requiredRule}
                        name='mail_address'
                        label='Почтовый адрес'
                    />
                </Form.Item>

                <Controls openInfoPopup={openInfoPopup} form={form} />
            </Space>
        </Form>
    );
};
