import { useEffect, useState } from 'react';
import { Checkbox, Col, Form, Input, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import axios from 'axios';

import { CustomAutocomplete, FormItem, NumberInput } from '@components';
import { authorization, BACKEND_URL } from '@utils';

import { AutoFillItem } from '../../../components/NewRequestPopup/AutoFillItem';
import { nameItemsList } from '../../../components/NewRequestPopup/utils';
import { transformToPossibleDisabledItem } from '../../HomePage/CreateReqModal/utils';
import { formatDate } from '../../HomePage/utils';

import styles from './CreateRequest.module.scss';

export const RequestOtherInfo = ({ form, initial }) => {
    const [routeId, setRouteId] = useState(-1);
    const [routeOptions, setRouteOptions] = useState([]);

    const PossibleDisableCustomAutocomplete = transformToPossibleDisabledItem(
        CustomAutocomplete,
        'route',
        [],
        'disabled'
    );

    const date = form.getFieldValue('date1');
    const routeSearch = form.getFieldValue('route');
    useEffect(() => {
        updateRouteOptions();
    }, [routeSearch, date]);

    const updateRouteOptions = async () => {
        if (!date) return;

        const { data } = await axios({
            method: 'get',
            url: `${BACKEND_URL}gt/customer/request_autocomplete/`,
            params: {
                q: routeSearch,
                forward: JSON.stringify({
                    date: formatDate(date),
                }),
            },
            headers: {
                Authorization: `Bearer ${authorization.getToken()}`,
            },
        });

        setRouteOptions(data.results.map((r) => ({ text: r.text, id: +r.id })));
    };

    return (
        <Form
            form={form}
            layout='vertical'
            className={styles.requisitesForm}
            initialValues={initial}
        >
            <div>
                <Space direction='vertical'>
                    <Text className={styles.requisitesTitle}>Необязательные данные</Text>
                </Space>
                <Space direction='vertical' className={styles.importInput}>
                    <AutoFillItem form={form} routeId={routeId} />
                </Space>
                <Space direction='vertical'>
                    <FormItem label={nameItemsList.route} name='route'>
                        <PossibleDisableCustomAutocomplete
                            options={routeOptions}
                            onIdChange={setRouteId}
                            defaultId={routeId || -1}
                        />
                    </FormItem>

                    <Col span={8}>
                        <FormItem label={nameItemsList.index} name='index' notInfoMessage>
                            <Input className={styles.form_input} />
                        </FormItem>
                    </Col>

                    <Form.Item noStyle>
                        <div className={styles.gabaritData}>
                            <Col>
                                <FormItem label={nameItemsList.volume} name='volume'>
                                    <NumberInput className={styles.form_input} />
                                </FormItem>
                            </Col>

                            <Col>
                                <FormItem label={nameItemsList.mass} name='mass'>
                                    <NumberInput className={styles.form_input} />
                                </FormItem>
                            </Col>

                            <Col>
                                <FormItem label={nameItemsList.places} name='places'>
                                    <NumberInput className={styles.form_input} />
                                </FormItem>
                            </Col>
                        </div>
                    </Form.Item>

                    <Form.Item noStyle>
                        <Row className={styles.gabaritData}>
                            <Col>
                                <FormItem label={nameItemsList.max_size} name='max_size'>
                                    <Input className={styles.form_input} />
                                </FormItem>
                            </Col>

                            <Col>
                                <FormItem label={nameItemsList.floor} name='floor'>
                                    <Input className={styles.form_input} />
                                </FormItem>
                            </Col>

                            <Col>
                                <FormItem
                                    label={nameItemsList.carrying_distance}
                                    name='carrying_distance'
                                >
                                    <Input className={styles.form_input} />
                                </FormItem>
                            </Col>

                            <Col>
                                <FormItem
                                    label={nameItemsList.has_elevator}
                                    name='has_elevator'
                                    valuePropName='checked'
                                >
                                    <Checkbox className={styles.form_checkbox} type='checkbox' />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form.Item>
                </Space>
            </div>
        </Form>
    );
};
