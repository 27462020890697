import { useRef } from 'react';
import moment from 'moment';

import { ScrollSlider, Spinner } from '@components';
import { REQUEST_GROUP_NAMES } from '@utils';

import styles from './styles.module.scss';

import 'moment/locale/ru';

const RequestCell = ({ request = null, onCellClick = null }) => {
    let workers_required = 0;
    let earliest_start;
    let latest_end;
    for (const item of request.items) {
        workers_required += item.workers_required;
        if (!earliest_start || moment(item.interval_begin, 'HH:mm').isBefore(earliest_start)) {
            earliest_start = moment(item.interval_begin, 'HH:mm');
        }
        if (!latest_end || moment(item.interval_end, 'HH:mm').isAfter(latest_end)) {
            latest_end = moment(item.interval_end, 'HH:mm');
        }
    }
    return (
        <div
            className={`${styles.RequestCell} ${
                styles[REQUEST_GROUP_NAMES[request.status.id].class]
            }`}
            onClick={onCellClick.bind(this)}
        >
            <div>
                {workers_required}/{request.worker_count}
            </div>
            <div>
                {earliest_start.format('HH:mm')}-{latest_end.format('HH:mm')}
            </div>
        </div>
    );
};

export const CalendarTable = ({
    data,
    date_range,
    isLoading,
    className,
    pagination,
    createRequestAction,
    openRequestAction,
}) => {
    function getDateRange(startDate, endDate, type = 'days') {
        const diff = endDate.diff(startDate, type);
        const range = [];
        for (let i = 0; i <= diff; i++) {
            range.push(moment(startDate).add(i, type));
        }
        return range;
    }

    const wrapRef = useRef(null);
    const contentRef = useRef(null);
    const date_array = getDateRange(moment(date_range[0]), moment(date_range[1]));
    return (
        <div className={styles.tableWrapper} ref={wrapRef}>
            <table className={`${styles.table} ${className}`} ref={contentRef}>
                <thead className={!isLoading && styles.tableHead_sticky}>
                    <tr className={styles.tableHead}>
                        <td className={styles.staticCell} />
                        {date_array.map((date, i) => (
                            <td key={i} className={styles.tableHeadTd}>
                                <div>{date.format('DD.MM')}</div>
                                <div>{date.format('dd')}</div>
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, i) => (
                        <tr key={i}>
                            <td
                                className={`${styles.tableBody_td} ${
                                    !isLoading && styles.tableBody_td_sticky
                                }`}
                                onClick={() => createRequestAction(row.location)}
                            >
                                <div className={styles.tableBody_td_item}>
                                    <div
                                        className={`${className} ${styles.tableBodyLocation} ${styles.tableBody_td_item} ${styles.noIndent}`}
                                    >
                                        {row.location.name}
                                    </div>
                                    <img
                                        alt='icon'
                                        src='/add_icon.svg'
                                        className={styles.buttonContainerIcon}
                                    />
                                </div>
                            </td>
                            {date_array.map((date, j) => {
                                const request = row.requests[date.format('YYYY-MM-DD')];
                                return (
                                    <td key={j} className={styles.tableBody_td}>
                                        <div className={`${className} ${styles.tableBodyDiv}`}>
                                            {request ? (
                                                <RequestCell
                                                    request={request}
                                                    onCellClick={() => openRequestAction(request)}
                                                />
                                            ) : (
                                                <div className={styles.RequestCell}>
                                                    {String.fromCharCode(160)}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

            {isLoading && <Spinner />}

            <ScrollSlider wrapRef={wrapRef} contentRef={contentRef}>
                {pagination}
            </ScrollSlider>
        </div>
    );
};
