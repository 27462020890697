import { getEnvValue } from '@utils/env';

export const DADATA_API_KEY = '864c4422fa0e7f050a37d04c1dc17ef57bb4f879';
export const BACKEND_URL = getEnvValue(
    'REACT_APP_GT_BO_BASE_URL_SERVER',
    'https://test.gettask.ru/'
);
export const ITEMS_ON_PAGE = 50;
export const REQUESTS_PREFIX = 'requests';
export const REQUESTS2_PREFIX = 'requests2';
export const REQUESTS_ON_MAP_PREFIX = 'requests_on_map';
export const IMPORTS_PREFIX = 'imports';
export const REPORTS_PREFIX = 'reports';
export const BALANCE_PREFIX = 'balance';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const REQUEST_GROUP_NAMES = {
    autotarification_attempt: { title: 'Тарифицируется', class: 'status-autotarification_attempt' },
    new: { title: 'Новая', class: 'status-new' },
    timepoint_confirmed: { title: 'Поиск исполнителя', class: 'status-timepoint_confirmed' },
    partly_confirmed: { title: 'Назначен', class: 'status-partly_confirmed' },

    partly_arrived: { title: 'Принята исполнителем', class: 'status-partly_arrived' },
    partly_arrival_submitted: { title: 'Принята исполнителем', class: 'status-partly_arrived' },
    partly_photo_attached: { title: 'На месте', class: 'status-partly_photo_attached' },
    photo_attached: { title: 'Проверка табеля', class: 'status-photo_attached' },
    finished: { title: 'Выполнена', class: 'status-finished' },

    no_response: { title: 'Нет ответа', class: 'status-no_response' },
    driver_callback: { title: 'Перезвонит сам', class: 'status-driver_callback' },

    declined: { title: 'Не принята', class: 'status-declined' },
    cancelled: { title: 'Отмена', class: 'status-cancelled' },
    removed: { title: 'Удалена', class: 'status-removed' },
    failed: { title: 'Срыв заявки', class: 'status-failed' },
    cancelled_with_payment: { title: 'Отмена с оплатой', class: 'status-cancelled_with_payment' },
};
