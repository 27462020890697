import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { Form, Radio, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Button, Checkbox, FormItem, Input, Label } from '@components';
import { withAuthentication } from '@hocs';
import { accountInfo, finishRegistration, signup } from '@store/actions';

import { formatPhone } from '../PhoneLoginPage/utils';

import styles from './styles.module.scss';

const defaultRule = [{ required: true, message: 'Это поле обязательно' }];

const checkBoxRule = [
    {
        message: '',
        type: 'boolean',
        transform: (value) => {
            if (!value) {
                return undefined;
            }
            return value;
        },
        required: true,
    },
];

const EnterSmsCode = ({ form, uid }) => {
    const dispatch = useDispatch();

    const onCodeEntered = async () => {
        const res = await dispatch(
            finishRegistration({
                uid,
                phone: formatPhone(form.getFieldValue('phone')),
                code: form.getFieldValue('code'),
                is_legal_entity: form.getFieldValue('isLegalEntity') || false,
            })
        );
        localStorage.setItem('token', res.payload.data.access_token);
        await dispatch(accountInfo({ token: res.payload.data.access_token }));
    };

    return (
        <Form style={{ width: 320 }} form={form} onFinish={onCodeEntered}>
            <Typography.Title level={5} ellipsis style={{ fontSize: 24, marginBottom: 0 }}>
                Регистрация
            </Typography.Title>
            <FormItem
                // marginBottom={0}
                rules={defaultRule}
                isLabelVertical
                required={false}
                label={
                    <Label className={styles.formItemLabel}>
                        Введите код из смс, отправленный на номер {form.getFieldValue('phone')}
                    </Label>
                }
                name='code'
            >
                <Input size='large' />
            </FormItem>
            <FormItem>
                <Button
                    className={styles.singup_button}
                    size='large'
                    type='primary'
                    htmlType='submit'
                >
                    Подтвердить
                </Button>
            </FormItem>
        </Form>
    );
};

export const SignupPage = withAuthentication(({ history, setPopup }) => {
    const dispatch = useDispatch();
    const [form] = useForm();
    const [isError, setIsError] = useState(true);
    const [isLegalEntity, setIsLegalEntity] = useState(false);
    const [codeSent, setCodeSent] = useState(false);

    const requiredFields = [
        'name',
        'phone',
        'email',
        'password',
        'isAcceptServiceRule',
        'isAcceptPdp',
    ];

    const onSignup = async ({
        name,
        email,
        password,
        phone,
        organizationName,
        codeReceiveMethod,
    }) => {
        try {
            const data = {
                full_name: name,
                email,
                password,
                phone: formatPhone(phone),
                send_by_sms: codeReceiveMethod,
            };
            if (organizationName !== undefined) data.organization_name = organizationName;
            const response = await dispatch(signup(undefined, data));
            if (!codeReceiveMethod)
                setPopup({
                    open: true,
                    title: 'Информация',
                    content:
                        'Проверьте свою почту и перейдите по ссылке в письме для завершения регистрации.',
                    onClose: () => history.push('/login'),
                });
            else {
                setCodeSent(response.payload.data.uid);
            }
        } catch (err) {
            setPopup({
                open: true,
                title: 'Ошибка',
                content:
                    err?.error?.response?.data?.message ||
                    `${err?.error?.response?.status} ${err?.error?.response?.statusText}`,
            });
        }
    };

    const onFieldsChange = (_, fields) => {
        const isError = fields.some(
            ({ errors, value, name }) =>
                (requiredFields.includes(name[0]) && value === undefined) || errors.length
        );

        setIsError(isError);
    };

    return !codeSent ? (
        <Form
            style={{ width: 320 }}
            form={form}
            onFinish={onSignup}
            onFieldsChange={onFieldsChange}
        >
            <Typography.Title level={5} ellipsis style={{ fontSize: 24, marginBottom: 0 }}>
                Регистрация
            </Typography.Title>

            <FormItem
                marginBottom={0}
                rules={defaultRule}
                isLabelVertical
                required={false}
                label={<Label>Ваше ФИО</Label>}
                name='name'
            >
                <Input size='large' />
            </FormItem>

            <FormItem
                className={styles.legalCheckBox}
                name='isLegalEntity'
                valuePropName='checked'
                onChange={(e) => {
                    if (!e.target.checked)
                        form.setFieldsValue({
                            organizationName: undefined,
                        });
                    setIsLegalEntity(e.target.checked);
                }}
            >
                <Checkbox>Я хочу зарегистрироваться как организация</Checkbox>
            </FormItem>

            <FormItem
                marginBottom={0}
                // rules={defaultRule}
                required={false}
                isLabelVertical
                hidden={!isLegalEntity}
                label={<Label>Название организации</Label>}
                name='organizationName'
            >
                <Input size='large' />
            </FormItem>

            <FormItem
                marginBottom={0}
                rules={defaultRule}
                isLabelVertical
                required={false}
                label={<Label>Телефон</Label>}
                name='phone'
            >
                <InputMask
                    mask='+7 (999) 999-99-99'
                    alwaysShowMask
                    className='ant-input ant-input-lg ant-input-status-success sc-hKMtZM liAagz'
                    disabled={codeSent}
                />
            </FormItem>

            <FormItem
                marginBottom={0}
                required={false}
                rules={defaultRule}
                isLabelVertical
                label={<Label>Email</Label>}
                name='email'
            >
                <Input size='large' />
            </FormItem>

            <FormItem
                rules={defaultRule}
                isLabelVertical
                required={false}
                marginBottom={24}
                label={<Label>Пароль</Label>}
                name='password'
            >
                <Input type='password' size='large' />
            </FormItem>

            <FormItem
                help=''
                rules={checkBoxRule}
                marginBottom={0}
                name='isAcceptServiceRule'
                valuePropName='checked'
            >
                <Checkbox>
                    Я соглашаюсь с{' '}
                    <a href='https://gettask.ru/rules.doc' className={styles.checkbox_link}>
                        Правилами сервиса
                    </a>
                </Checkbox>
            </FormItem>

            <FormItem
                rules={checkBoxRule}
                marginBottom={12}
                name='isAcceptPdp'
                help=''
                valuePropName='checked'
            >
                <Checkbox>
                    Я соглашаюсь с{' '}
                    <a
                        href='https://gettask.ru/personal_data_policy.doc'
                        className={styles.checkbox_link}
                    >
                        Соглашением на обработку персональных данных
                    </a>
                </Checkbox>
            </FormItem>
            <FormItem
                label={<Label>Получить код</Label>}
                className={styles.codeOption}
                name='codeReceiveMethod'
            >
                <Radio.Group defaultValue={false} buttonStyle='solid'>
                    <Radio.Button value={false}>на email</Radio.Button>
                    <Radio.Button value>в смс</Radio.Button>
                </Radio.Group>
            </FormItem>
            <FormItem>
                <Button
                    disabled={isError}
                    className={styles.singup_button}
                    size='large'
                    type='primary'
                    htmlType='submit'
                >
                    Зарегистрироваться
                </Button>
            </FormItem>
        </Form>
    ) : (
        <EnterSmsCode form={form} uid={codeSent} />
    );
});
