import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message, Space, Spin, Typography } from 'antd';

import { createCard, deleteCard, fetchExistingCards } from '@store/actions';

// import { fetchExistingCards } from '@store/actions';
import { platformSelector } from '../../../store/reducers/platform';
import { CardDataInput } from '../../CreateRequest/components/CardDataInput';

import { ExistingCard } from './components/ExistingCard';

import styles from '../styles.module.scss';

export const NotLegalIngo = ({ form }) => {
    const dispatch = useDispatch();
    const platform = useSelector(platformSelector);
    const [showAddCard, setShowAddCard] = useState(false);
    const [isCardLoading, setIsCardLoading] = useState(false);
    const [newCardForm] = Form.useForm();
    const { data: cardList } = useSelector((state) => state.cards);

    const onCardDelete = async (cardId) => {
        setIsCardLoading(true);
        await dispatch(deleteCard({}, { id: cardId }));
        await dispatch(fetchExistingCards({}, {}));
        setIsCardLoading(false);
    };

    const onAddCard = async (onFinishValues) => {
        setIsCardLoading(true);
        try {
            await dispatch(
                createCard(
                    {},
                    {
                        cardholder_name: onFinishValues.cardholderName,
                        number: onFinishValues.cardNumber.replaceAll(' ', ''),
                        expiration_date: onFinishValues.expiryDate,
                        cvv: onFinishValues.cvv,
                    }
                )
            );
            await dispatch(fetchExistingCards({}, {}));
            setShowAddCard(false);
            setIsCardLoading(false);
            newCardForm.resetFields();
        } catch (e) {
            message.error(e.error.response.data.detail);
            setIsCardLoading(false);
        }
    };

    useEffect(async () => {
        setIsCardLoading(true);
        await dispatch(fetchExistingCards({}, {}));
        setIsCardLoading(false);
    }, []);

    return (
        <div className={styles.notLegalContainer} hidden={cardList === undefined}>
            <Form labelWrap form={form} className={`${styles.form} ${styles.notLegal}`}>
                <Space
                    className={styles.form_container}
                    direction='vertical'
                    size={platform === 'mobile' ? 'small' : 'large'}
                >
                    <Typography.Title className={styles.smallTitle} level={4}>
                        Ваши карты
                    </Typography.Title>
                </Space>
            </Form>
            <Spin spinning={isCardLoading}>
                <Form
                    layout='vertical'
                    className={`${styles.form} ${styles.notLegal} ${styles.noGap}`}
                    form={newCardForm}
                    onFinish={onAddCard}
                >
                    {cardList &&
                        cardList.map((card, key) => (
                            <ExistingCard card_data={card} onDelete={onCardDelete} key={key} />
                        ))}
                    {showAddCard && <CardDataInput styles={styles} />}
                    {!showAddCard && (
                        <Space
                            direction='horizontal'
                            className={`${styles.addCardButton} ${styles.buttonsContainerItem}`}
                            onClick={() => setShowAddCard(true)}
                        >
                            <img src='/add_icon.svg' alt='add_icon' />
                            <Typography.Text className={styles.addCardButtonText}>
                                Добавить карту
                            </Typography.Text>
                        </Space>
                    )}
                </Form>
            </Spin>
        </div>
    );
};
