import { useState } from 'react';

import styles from './styles.module.scss';

const pagination = (c, m) => {
    const current = c;
    const last = m;
    const delta = 1;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
        }
    }

    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
};

export const Pagination = ({ pages, onPageChange, stylesRef }) => {
    const [page, setPage] = useState(1);
    const currentPages = pages || 1;

    if (currentPages <= 7) {
        return (
            <div className={styles.bottomNav} style={stylesRef?.wrap}>
                <img
                    onClick={() => {
                        setPage(Math.max(page - 1, 1));
                        onPageChange(Math.max(page - 1, 1));
                    }}
                    alt='icon'
                    src='/prev_icon.svg'
                    className={styles.bottomNavItem}
                    style={stylesRef?.item}
                />
                {[...Array(currentPages).keys()].map((p) => (
                    <div
                        key={p}
                        className={`${styles.bottomNavItem} ${
                            page === p + 1 ? styles.bottomNavActive : ''
                        }`}
                        style={stylesRef?.item}
                        onClick={() => {
                            setPage(p + 1);
                            onPageChange(p + 1);
                        }}
                    >
                        {p + 1}
                    </div>
                ))}
                <img
                    onClick={() => {
                        setPage(Math.min(page + 1, currentPages));
                        onPageChange(Math.min(page + 1, currentPages));
                    }}
                    alt='icon'
                    src='/next_icon.svg'
                    className={styles.bottomNavItem}
                    style={stylesRef?.item}
                />
            </div>
        );
    }
    return (
        <div className={styles.bottomNav} style={stylesRef?.wrap}>
            <img
                onClick={() => {
                    setPage(Math.max(page - 1, 1));
                    onPageChange(Math.max(page - 1, 1));
                }}
                alt='icon'
                src='/prev_icon.svg'
                className={styles.bottomNavItem}
                style={stylesRef?.item}
            />
            {pagination(page, currentPages).map((el) => (
                <div
                    onClick={() => {
                        setPage(el);
                        onPageChange(el);
                    }}
                    className={`${el === '...' ? styles.bottomNavDots : styles.bottomNavItem} ${
                        page === el ? styles.bottomNavActive : ''
                    }`}
                    style={stylesRef?.item}
                >
                    {el}
                </div>
            ))}
            <img
                onClick={() => {
                    setPage(Math.min(page + 1, currentPages));
                    onPageChange(Math.min(page + 1, currentPages));
                }}
                alt='icon'
                src='/next_icon.svg'
                className={styles.bottomNavItem}
                style={stylesRef?.item}
            />
        </div>
    );
};
