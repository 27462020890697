import { forwardRef, useState } from 'react';

import { Input } from '@components';

const CustomInput = forwardRef(({ onAddressChange, onInputChange, ...props }, ref) => {
    const [value, setValue] = useState();

    const onChange = (event) => {
        setValue(event.target.value);
        onInputChange(event);
    };

    const onPressEnter = () => {
        onAddressChange(value);
    };

    return <Input {...props} autoFocus onPressEnter={onPressEnter} onChange={onChange} ref={ref} />;
});

const withCustomInput = (Input, onAddressChange) =>
    forwardRef(({ onChange: onInputChange, ...props }, ref) => {
        return (
            <Input
                {...props}
                ref={ref}
                onAddressChange={onAddressChange}
                onInputChange={onInputChange}
            />
        );
    });

export const withDadata =
    (Component) =>
    ({ onChange, ...props }) => {
        return (
            <Component
                {...props}
                onChange={onChange}
                customInput={withCustomInput(CustomInput, onChange)}
            />
        );
    };
