import { useCallback, useEffect, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Button, Carousel, Modal, Space, Spin } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'underscore';

import {
    ButtonsContainer,
    CustomSelectWithStorage,
    Dashboard,
    DatePickerApply,
    DownloadExcelButton,
    Editable,
    ImportsButton,
    NewRequestPopup,
    Pagination,
    PhotoTooltip,
    PopupInfo,
    PopupModal,
    SearchWrapper,
    TableRequestsWrapper,
    ViewButton,
    Wrapper,
} from '@components';
import {
    copyItem,
    fetchLocation,
    fetchRequests,
    moveItem,
    removeItem,
    removeRequest,
    setFilter,
    setLastStateSearch,
    setSorting,
    updateAddressOptions,
    updateRequest,
} from '@store/actions';
import { platformSelector } from '@store/reducers/platform';
import {
    DADATA_API_KEY,
    formatToMoney,
    ITEMS_ON_PAGE,
    REQUESTS_PREFIX,
    storageService,
} from '@utils';

import { downloadImg } from '../../components/PhotoTooltip/utils';

import { AddressesEditor, elevatorEditor, expandedElevatorEditor } from './editors';
import { ImageViewer, initColumns } from './utils';

import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

const currencyFormat = (num) => {
    return num?.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

const localStorageService = storageService(REQUESTS_PREFIX);

const ButtonApprove = ({ pk, value, style }) => {
    const dispatch = useDispatch();

    return (
        <div className={styles.buttonsContainer2} style={style}>
            <div
                className={styles.approveButton}
                onClick={() => {
                    dispatch(
                        updateRequest()(undefined, {
                            request: pk,
                            customer_confirmation: value,
                            force_commit: false,
                        })
                    );
                }}
            >
                {value ? (
                    <>
                        <img alt='icon' src='/check_icon.svg' />
                        <div>&nbsp;Согласовать</div>
                    </>
                ) : (
                    <div>x</div>
                )}
            </div>
        </div>
    );
};

const ExpectedHoursMax = ({ elem, addr }) => {
    const formattedData = addr?.workers_required_for_service.reduce(
        (prev, i) => (prev >= i.expected_hours ? prev : i.expected_hours),
        0
    );

    const formattedDataFinish =
        moment(elem.confirmed_timepoint, 'HH:mm').add(formattedData, 'hours') || moment();

    return <div>{elem.confirmed_timepoint ? formattedDataFinish.format('HH:mm') : '-'}</div>;
};

const ExpectedTotalHours = ({ addr }) => {
    const maxExpectedHours = addr.workers_required_for_service.reduce((prev, i) => {
        return prev + Number((i.workers_required || 0) * (i.expected_hours || 0));
    }, 0);
    return <div>{maxExpectedHours}</div>;
};

const ExpandedCellRoutePart = ({ elem, setModalPopup, updateRequests, addr }) => {
    const dispatch = useDispatch();
    return (
        <>
            <img
                className={styles.tableButton}
                style={{ opacity: 0, cursor: 'default' }}
                alt='icon'
                src='/add_icon2.svg'
            />
            <div style={{ opacity: 0, cursor: 'default' }} className={styles.tableExpandButton}>
                {elem.addresses.length}
            </div>
            <img
                onClick={async () => {
                    setModalPopup({
                        open: true,
                        title: 'Вынести адрес в отдельную заявку?',
                        onOk: async () => {
                            await dispatch(moveItem({ item_pk: addr.pk, target_pk: null }));

                            updateRequests();
                        },
                    });
                }}
                style={elem.status.id === 'new' ? {} : { opacity: 0, cursor: 'default' }}
                title='Вынести в отдельную заявку'
                className={styles.tableButton}
                alt='icon'
                src='/minus_icon.svg'
            />
            <img
                onClick={() => {
                    setModalPopup({
                        open: true,
                        title: 'Дублировать адрес в отдельную заявку?',
                        onOk: async () => {
                            await dispatch(copyItem({ item_pk: addr.pk }));

                            updateRequests();
                        },
                    });
                }}
                style={elem.status.id === 'new' ? {} : { opacity: 0, cursor: 'default' }}
                title='Дублировать в отдельную заявку'
                className={styles.tableButton}
                alt='icon'
                src='/copy_icon.svg'
            />
            <img
                onClick={() => {
                    setModalPopup({
                        open: true,
                        title: 'Удалить адрес?',
                        onOk: () => {
                            dispatch(removeItem({ item_pk: addr.pk }));

                            updateRequests();
                        },
                    });
                }}
                style={elem.status.id === 'new' ? {} : { opacity: 0, cursor: 'default' }}
                title='Удалить'
                className={styles.tableButton}
                alt='icon'
                src='/delete_icon.svg'
            />
        </>
    );
};

export const RequestsPage = () => {
    const {
        dashboard,
        filterStatus,
        isRequestsLoading,
        requestsList,
        sorting,
        allow_requests_creation,
        show_locations_filter,
    } = useSelector((state) => ({
        requestsList: state.requests.list,
        dashboard: state.requests.dashboard,
        sorting: state.requests.sorting,
        filterStatus: state.requests.filterStatus,
        isRequestsLoading: state.requests.isLoading,
        allow_requests_creation: state.user.info.allow_requests_creation,
        show_locations_filter: state.user.info.show_locations_filter,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const platform = useSelector(platformSelector);

    const [itemsOnPage, setItemsOnPage] = useState(
        localStorageService.get('itemsOnPage', ITEMS_ON_PAGE)
    );
    const [branch, setBranch] = useState([]);

    const [columns, setColumns] = useState({
        ...initColumns,
        ...(JSON.parse(localStorage.getItem('columns')) || {}),
    });

    const [showDetails, setShowDetails] = useState([]);
    const [expandedRow, setExpandedRow] = useState([]);
    const [page, setPage] = useState(1);
    const pages = Math.ceil(requestsList.length / itemsOnPage);
    const [addressPopupOpenedId] = useState(-1);
    const [addressPopupInput, setAddressPopupInput] = useState('');
    const [_addressId, setAddressId] = useState(-1);
    const [_addressOptions, setAddressOptions] = useState([]);
    const [requestPopup, setRequestPopup] = useState({ open: false, route: null, disable: null });
    const [tooltipContentHover, setTooltipContentHover] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipTriggerHover, setTooltipTriggerHover] = useState(false);
    const [tooltipElement, setTooltipElement] = useState(null);
    const [tooltipImgUrls, setTooltipImgUrls] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [modalPopup, setModalPopup] = useState({ open: false, content: '' });

    const [editPopup, setEditPopup] = useState({ open: false, data: null, disable: null });
    const [requestImages, setRequestImages] = useState({});
    const [displayRequestImages, setDisplayRequestImages] = useState({});
    const [previewImages, setPreviewImages] = useState([]);
    const requestImagesList = Object.values(displayRequestImages);

    const customerResolution = [
        { title: 'Согласована', value: 'confirmed' },
        { title: 'На рассмотрении', value: 'normal' },
        { title: 'Подозрительная', value: 'suspicious' },
    ];

    const statusInfo = {
        autotarification_attempt: {
            title: 'Тарифицируется',
            class: 'status-autotarification_attempt',
        },
        new: { title: 'Новая', class: 'status-new', show: true },
        timepoint_confirmed: { title: 'Поиск исполнителя', class: 'status-timepoint_confirmed' },
        partly_confirmed: { title: 'Назначен', class: 'status-partly_confirmed' },

        partly_arrived: { title: 'В пути', class: 'status-partly_arrived' },
        partly_photo_attached: { title: 'На месте', class: 'status-partly_photo_attached' },
        photo_attached: { title: 'Проверка табеля', class: 'status-photo_attached' },
        finished: { title: 'Выполнена', class: 'status-finished' },

        no_response: { title: 'Нет ответа', class: 'status-no_response' },
        driver_callback: { title: 'Перезвонит сам', class: 'status-driver_callback' },

        declined: { title: 'Не принята', class: 'status-declined' },
        cancelled: { title: 'Отмена', class: 'status-cancelled' },
        removed: { title: 'Удалена', class: 'status-removed', show: true },
        failed: { title: 'Срыв заявки', class: 'status-failed', show: true },
        cancelled_with_payment: {
            title: 'Отмена с оплатой',
            class: 'status-cancelled_with_payment',
        },
    };

    const filterMap = (filter) => {
        switch (filter) {
            case 'Несогласованные заявки':
                return 'active';
            case 'Заявки в оплате':
                return 'in_payment';
            case 'Оплаченные заявки':
                return 'paid';
            default:
                return null;
        }
    };
    const statusAllowCancel = [
        'driver_callback',
        'no_response',
        'partly_confirmed',
        'partly_arrived',
        'partly_photo_attached',
        'photo_attached',
    ];

    function getStatusClass(status) {
        let statusClass = 'status-default';

        if (status.id in statusInfo) {
            statusClass = statusInfo[status.id].class;
        }

        return `${styles.status} ${styles[statusClass]}`;
    }

    function calculateTop() {
        const scrollHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );
        return Math.min(
            tooltipElement && tooltipElement.getBoundingClientRect().top + window.pageYOffset,
            scrollHeight - 520
        );
    }

    async function _updateAddressOptions() {
        const res = await dispatch(
            updateAddressOptions({
                q: addressPopupInput,
                forward: JSON.stringify({
                    request: addressPopupOpenedId,
                }),
            })
        );
        setAddressOptions(res.payload.data.results.map((r) => ({ text: r.text, id: +r.id })));
    }

    function toggleExpandAll() {
        const currentRow = expandedRow.length ? [] : requestsList.map((item) => item.pk);

        setExpandedRow(currentRow);
    }

    function toggleExpandedRow(i) {
        const idx = expandedRow.indexOf(i);
        if (idx === -1) {
            setExpandedRow([...expandedRow, i]);
        } else {
            setExpandedRow([...expandedRow.slice(0, idx), ...expandedRow.slice(idx + 1)]);
        }
    }

    const updateRequests = useCallback(async () => {
        const res = await dispatch(
            fetchLocation({
                forward: JSON.stringify({
                    first_day: moment(localStorageService.get('startRanges')).format('DD.MM.YYYY'),
                    last_day: moment(localStorageService.get('endRanges')).format('DD.MM.YYYY'),
                }),
            })
        );

        setBranch(res.payload.data.results.map((r) => ({ id: +r.id, text: r.text })));

        return dispatch(
            fetchRequests({
                search_text: localStorageService.get('search'),
                payment_status: filterMap(localStorageService.get('filter')),
                status: localStorageService.get('status'),
                customer_resolution: localStorageService.get('customer_resolution'),
                location: localStorageService.get('branch'),
                first_day:
                    localStorageService.get('startRanges') &&
                    moment(localStorageService.get('startRanges')).format('DD.MM.YYYY'),
                last_day:
                    localStorageService.get('startRanges') &&
                    moment(localStorageService.get('endRanges')).format('DD.MM.YYYY'),
            })
        );
    }, []);

    function changeSorting(key) {
        let { direction } = sorting;

        if (sorting.key === key) {
            direction = direction === 'down' ? 'up' : 'down';
        }

        dispatch(setSorting({ key, direction }));
    }

    const updateData = _.throttle(updateRequests, 500);
    const updateAddressOptions_t = _.throttle(_updateAddressOptions, 500);

    useEffect(() => {
        setAddressPopupInput('');
        setAddressId(-1);
    }, [addressPopupOpenedId]);

    useEffect(() => {
        if (addressPopupOpenedId !== -1) {
            updateAddressOptions_t();
        }
    }, [addressPopupInput, addressPopupOpenedId]);

    useEffect(() => {
        localStorageService.set('itemsOnPage', itemsOnPage);
    }, [itemsOnPage]);
    useEffect(() => {
        updateRequests();
        dispatch(
            setLastStateSearch({
                curReq: '',
                location: '',
                createdRequestDate: '',
            })
        );
    }, [filterStatus]);

    function expandedCellPart(elem, c) {
        if (expandedRow.indexOf(elem.pk) === -1) {
            return null;
        }
        return elem.addresses.slice(1).map((addr, index) => {
            const renderExpandedCellPart = () => {
                const isEditable = elem.status.id !== 'new';

                switch (c) {
                    case 'expected_hours_max':
                        return <ExpectedHoursMax elem={elem} addr={addr} />;
                    case 'expected_total_hours':
                        return <ExpectedTotalHours addr={addr} />;

                    case 'route':
                        return (
                            <ExpandedCellRoutePart
                                elem={elem}
                                addr={addr}
                                setModalPopup={setModalPopup}
                                updateRequests={updateRequests}
                            />
                        );

                    case 'addresses':
                        return AddressesEditor({ elem, addr });

                    case 'has_elevator':
                        return expandedElevatorEditor({
                            elem,
                            c,
                            updateData,
                            index: index + 1,
                            addr,
                        });

                    case 'places':
                        return (
                            <Editable
                                field='place_count'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={addr.place_count}
                                editable={isEditable}
                            />
                        );

                    case 'max_size':
                        return (
                            <Editable
                                field='max_size'
                                pk={elem.pk}
                                item_pk={elem.addresses[index + 1].id}
                                text={elem.addresses[index + 1][c]}
                                editable={isEditable}
                            />
                        );
                    case 'workers_required_for_service':
                        return (
                            <Editable
                                field='workers_required_for_service'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={elem.items[index + 1]?.[c]
                                    ?.map((i) => i.service_name)
                                    ?.join(' ')}
                                editable={isEditable}
                            />
                        );
                    case 'index':
                        return (
                            <Editable
                                field='code'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={addr[c]}
                                editable={isEditable}
                            />
                        );
                    case 'interval':
                        return (
                            <Editable
                                field='time_interval'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={
                                    (addr[c] ?? addr?.time_interval)?.includes('-')
                                        ? addr[c] ?? addr?.time_interval
                                        : '-'
                                }
                                editable={isEditable}
                            />
                        );
                    case 'time':
                        return (
                            <Editable
                                field='time_interval'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={
                                    (addr[c] ?? addr?.time_interval)?.includes('-')
                                        ? '-'
                                        : addr[c] ?? addr?.time_interval
                                }
                                editable={isEditable}
                            />
                        );

                    case 'mass':
                        return (
                            <Editable
                                field='mass'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={addr[c] ? addr[c]?.toString()?.replace(/\./gi, ',') : null}
                                editable={isEditable}
                            />
                        );

                    case 'volume':
                        return (
                            <Editable
                                field='volume'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={addr[c] ? addr[c]?.toString()?.replace(/\./gi, ',') : null}
                                editable={isEditable}
                            />
                        );

                    case 'floor':
                        return (
                            <Editable
                                field='floor'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={addr[c]}
                                editable={isEditable}
                            />
                        );

                    case 'carrying_distance':
                        return (
                            <Editable
                                field='carrying_distance'
                                pk={elem.pk}
                                item_pk={elem.addresses[index + 1].id}
                                text={elem.addresses[index + 1][c]}
                                editable={isEditable}
                            />
                        );

                    case 'place_count':
                        return (
                            <Editable
                                field='place_count'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={addr.place_count}
                                editable={isEditable}
                            />
                        );

                    case 'shipment_type':
                        return (
                            <Editable
                                field='shipment_type'
                                pk={elem.pk}
                                item_pk={addr.id}
                                text={addr.shipment_type}
                                editable={isEditable}
                            />
                        );
                    default:
                        return addr[c];
                }
            };
            return (
                <div className={c === 'route' ? styles.tableImgWrapper : ''} key={addr.id}>
                    {renderExpandedCellPart()}
                </div>
            );
        });
    }

    function mainCellPart(elem, c) {
        const isEditable = elem.status.id !== 'new';
        if (c === 'pk') {
            const onClick = () => {
                dispatch(
                    setLastStateSearch({
                        curReq: `${elem.pk} ${elem.status.text}`,
                        location: elem.location.name,
                        createdRequestDate: moment(elem.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    })
                );

                history.push('/home');
            };

            return <div onClick={onClick}>{elem.pk}</div>;
        }

        if (c === 'expected_hours_max') {
            const formattedData = elem?.items?.reduce((pprev, i) => {
                const result = i?.workers_required_for_service?.reduce(
                    (prev, i) => (prev >= i.expected_hours ? prev : i.expected_hours),
                    0
                );
                return pprev >= result ? pprev : result;
            }, 0);

            const formattedDataFinish =
                moment(elem.confirmed_timepoint, 'HH:mm').add(formattedData, 'hours') || moment();

            return (
                <div>{elem.confirmed_timepoint ? formattedDataFinish.format('HH:mm') : '-'}</div>
            );
        }
        if (c === 'expected_total_hours') {
            const itemTextContent = (item, itemKey, defaultValue) => {
                if (!item) {
                    return '-';
                }

                return item[itemKey] || defaultValue;
            };

            const textContent = (request, itemOrRequestKey) =>
                request[itemOrRequestKey] ||
                itemTextContent(request.items[0], itemOrRequestKey, '');
            const workersRequiredForService =
                elem.items?.length > 1
                    ? elem.items.reduce((prev, i) => {
                          if (i.workers_required_for_service)
                              return [...prev, ...i.workers_required_for_service];
                          return prev;
                      }, [])
                    : textContent(elem, 'workers_required_for_service');
            const maxExpectedHours = (workersRequiredForService || [])?.reduce((prev, i) => {
                return prev + Number((i.workers_required || 0) * (i.expected_hours || 0));
            }, 0);

            return <div>{maxExpectedHours}</div>;
        }
        if (c === 'route') {
            if (elem.status.id === 'autotarification_attempt') {
                return (
                    <div
                        className={styles.tableExpandButton}
                        style={{ display: elem.addresses?.length > 1 ? 'block' : 'none' }}
                        onClick={() => toggleExpandedRow(elem.pk)}
                    >
                        {elem.addresses?.length}
                    </div>
                );
            }
            return (
                <>
                    <img
                        className={styles.tableButton}
                        onClick={() => {
                            const req = requestsList.find((r) => r.pk === elem.pk);

                            setRequestPopup({
                                open: true,
                                route: {
                                    routeId: elem.pk,
                                    locationId: elem.pk,
                                    date: req.date,
                                    driver: req.contact,
                                    phones: req.phone,
                                },
                                disable: ['route', 'location'],
                            });
                        }}
                        title='Добавить в маршрут'
                        alt='icon'
                        src='/add_icon2.svg'
                    />
                    <div
                        className={styles.tableExpandButton}
                        style={{ display: elem.addresses?.length > 1 ? 'block' : 'none' }}
                        onClick={() => toggleExpandedRow(elem.pk)}
                    >
                        {elem.addresses?.length}
                    </div>
                </>
            );
        }
        if (c === 'workers_required_for_service') {
            return (
                <Editable
                    field='workers_required_for_service'
                    pk={elem.pk}
                    item_pk={elem?.addresses?.[0]?.id}
                    text={elem?.items?.[0]?.workers_required_for_service?.map(
                        (i) => i.service_name
                    )}
                    editable={isEditable}
                />
            );
        }
        if (c === 'addresses') {
            return (
                <Editable
                    inputComponent={(value, setValue, handleSubmit) => (
                        <AddressSuggestions
                            inputProps={{ autoFocus: true }}
                            defaultQuery={elem.addresses?.[0]?.text}
                            token={DADATA_API_KEY}
                            value={value}
                            onChange={(addr) => {
                                setValue(addr.value);
                                handleSubmit(addr.value);
                            }}
                            containerClassName={styles.addressSuggestions}
                        />
                    )}
                    field='address'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.addresses?.[0]?.text}
                    editable={isEditable}
                />
            );
        }
        if (c === 'mass') {
            return (
                <Editable
                    field='mass'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.mass ? elem.mass?.toString()?.replace(/\./gi, ',') : null}
                    editable={isEditable}
                />
            );
        }
        if (c === 'volume') {
            return (
                <Editable
                    field='volume'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.volume ? elem.volume?.toString()?.replace(/\./gi, ',') : null}
                    editable={isEditable}
                />
            );
        }
        if (c === 'has_elevator') {
            return elevatorEditor({
                elem,
                c,
                updateData,
                itemPk: elem.addresses?.[0]?.id,
                index: 0,
            });
        }
        if (c === 'floor') {
            return (
                <Editable
                    field='floor'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                />
            );
        }
        if (c === 'max_size') {
            return (
                <Editable
                    field='max_size'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                />
            );
        }
        if (c === 'carrying_distance') {
            return (
                <Editable
                    field='carrying_distance'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                />
            );
        }
        if (c === 'time') {
            return (
                <Editable
                    field='time'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={
                        elem.addresses?.[0]?.interval?.includes('-')
                            ? '-'
                            : elem.addresses?.[0]?.interval
                    }
                    editable={isEditable}
                />
            );
        }
        if (c === 'interval') {
            return (
                <Editable
                    field='time_interval'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={
                        elem.addresses?.[0]?.interval?.includes('-')
                            ? elem.addresses?.[0]?.interval
                            : '-'
                    }
                    editable={isEditable}
                />
            );
        }
        if (c === 'index') {
            return (
                <>
                    <Editable
                        field='code'
                        pk={elem.pk}
                        item_pk={elem.addresses?.[0]?.id}
                        text={elem[c]}
                        editable={isEditable}
                    />
                    <div
                        style={{
                            width: 19,
                            minWidth: 19,
                            marginLeft: 10,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            alt={elem.customer_resolution}
                            src={`/customer_resolution_${elem.customer_resolution}.svg`}
                        />
                    </div>
                </>
            );
        }
        if (c === 'places') {
            return (
                <Editable
                    field='place_count'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem[c]}
                    editable={isEditable}
                />
            );
        }
        if (c === 'shipment_type') {
            return (
                <Editable
                    field='shipment_type'
                    pk={elem.pk}
                    item_pk={elem.addresses?.[0]?.id}
                    text={elem.addresses?.[0]?.shipment_type}
                    editable={isEditable}
                />
            );
        }
        if (c === 'date') {
            return (
                <Editable
                    field='date'
                    pk={elem.pk}
                    item_pk={null}
                    text={moment(elem[c]).format('DD.MM.YYYY')}
                    editable={isEditable}
                />
            );
        }

        if (c === 'contact') {
            return (
                <Editable
                    field='driver_name'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem[c]}
                    editable={isEditable}
                />
            );
        }
        if (c === 'phone') {
            return (
                <Editable
                    field='driver_phones'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem[c]?.join(' ')}
                    editable={isEditable}
                />
            );
        }
        if (c === 'comment') {
            return <Editable field='comment' pk={elem.pk} item_pk={null} text={elem[c]} editable />;
        }

        if (c === 'arrival_time') {
            return elem[c] ? moment(elem[c]).format('HH:mm') : null;
        }

        if (c === 'customer_comment') {
            return (
                <Editable
                    field='customer_comment'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem[c]}
                    editable={false}
                />
            );
        }
        if (c === 'hours') {
            return (
                <Editable
                    field='customer_comment'
                    pk={elem.pk}
                    item_pk={null}
                    text={elem.worked_hours}
                    editable={isEditable}
                />
            );
        }
        if (c === 'price') {
            return currencyFormat(elem.price);
        }
        if (c === 'status') {
            return elem.status.text;
        }

        if (c === 'approve') {
            const isVisible =
                elem.status.id === 'finished' || elem.status.id === 'cancelled_with_payment';

            const renderButtonApprove = (value) => <ButtonApprove pk={elem.pk} value={value} />;

            if (elem.customer_resolution !== 'confirmed' && isVisible) {
                return renderButtonApprove(true);
            }
            if (elem.customer_resolution === 'confirmed' && isVisible) {
                return renderButtonApprove(false);
            }
            return null;
        }

        return elem[c];
    }

    function tableCell(elem, c) {
        let className = null;

        if (c === 'route') {
            className = styles.tableImgWrapper;
        }

        if (c?.toString() === 'status') {
            className = getStatusClass(elem[c]);
        }

        return (
            <td key={c} style={columns[c].style} className={styles.tableBody_td}>
                <div
                    className={`${className} ${styles.tableBodyDiv} ${styles.tableBody_td_item}`}
                    style={{
                        overflow: c === 'addresses' ? 'visible' : '',
                        ...(c === 'index'
                            ? { display: 'flex', justifyContent: 'space-between' }
                            : {}),
                    }}
                >
                    {mainCellPart(elem, c)}
                </div>
                {expandedCellPart(elem, c)}
            </td>
        );
    }

    function tableRow(elem) {
        const getAllPhoto = () => {
            const photos = elem.items
                ? elem.items.reduce((prev, item) => [...prev, ...(item.photos || [])], [])
                : [];
            return [...elem.finish_photos, ...elem.start_photos, ...photos];
        };
        const cells = [];
        let has_photos = !!elem?.finish_photos?.length;
        if (!has_photos) {
            has_photos = elem.items.some((item) => !!item.photos?.length);
        }
        cells.push(
            <td
                className={`${styles.photoPopup} ${styles.tableBody_td} ${styles.tableBody_td_img}`}
                key={`button1_${elem.index}`}
            >
                <img
                    className={`${styles.tablePhotoIcon} ${styles.tableBody_td_img_item} ${
                        has_photos ? '' : styles.disabled
                    }`}
                    alt='icon'
                    src='/photo_icon.svg'
                    onMouseEnter={(e) => {
                        // if (elem.finish_photos.length) {
                        setTooltipTriggerHover(true);
                        setTooltipElement(e.target);
                        setTooltipImgUrls(getAllPhoto());
                        if (elem.items?.length) setItemToEdit(elem.items[0].id);
                        // }
                    }}
                    onMouseLeave={(e) => {
                        // if (elem.finish_photos.length) {
                        setTooltipTriggerHover(false);
                        setTooltipElement(e.target);
                        setTooltipOpen(true);
                        setTimeout(() => setTooltipOpen(false), 500);
                        // }
                    }}
                />
            </td>
        );

        cells.push(
            ...Object.keys(columns)
                .filter((c) => columns[c].isVisible)
                .map((c) => tableCell(elem, c))
        );

        const renderActions = () => {
            if (elem.status.id === 'new') {
                return (
                    <img
                        className={styles.tableButton2}
                        alt='icon'
                        src='/delete_circle_icon.svg'
                        onClick={async () => {
                            try {
                                await dispatch(removeRequest({ pk: elem.pk }));

                                setInfoPopup({
                                    open: true,
                                    title: 'Заявка удалена',
                                    content: '',
                                });

                                updateRequests();
                            } catch (err) {
                                setInfoPopup({
                                    open: true,
                                    content:
                                        err.error.response.data.message ||
                                        `${err.error.response.status} ${err.error.response.statusText}`,
                                    title: 'Ошибка',
                                });
                            }
                        }}
                    />
                );
            }
            if (statusAllowCancel.indexOf(elem.status.id) !== -1) {
                return (
                    <img
                        className={styles.tableButton2}
                        alt='icon'
                        src='/cancel_icon.svg'
                        onClick={() => {
                            setInfoPopup({
                                open: true,
                                title: 'Отмена заявки',
                                content: `Для отмены заявки №${elem.pk} обратитесь к диспетчеру по телефону +7 (969) 777-13-56`,
                            });
                        }}
                    />
                );
            }

            return null;
        };

        cells.push(
            <td key={`button2_${elem.index}`} className={styles.tableBody_td}>
                {renderActions()}
            </td>
        );

        return (
            <tr
                key={elem.index}
                className={[
                    styles.requestTable,
                    styles.tableBody_tr,
                    elem.customer_resolution === 'suspicious' ? styles.trSuspicious : '',
                    elem.customer_resolution === 'confirmed' ? styles.trConfirmed : '',
                ].join(' ')}
            >
                {cells}
            </tr>
        );
    }

    function tableBody() {
        return requestsList
            .slice(itemsOnPage * (page - 1), itemsOnPage * page)
            .map((elem) => tableRow(elem));
    }

    const onCloseNewRequestPopup = () => {
        setRequestPopup({ open: false, route: null, disable: null });
    };

    const onCloseEditPopup = () => {
        setEditPopup({ open: false, data: null, disable: null });
    };

    const loadPictures = async (addresses) => {
        addresses.forEach((item) => {
            item.photos?.map(async (photo) => {
                if (requestImages?.[photo.url]) return;
                await setRequestImages({
                    ...requestImages,
                    [photo.url]: await downloadImg(photo.url, () => {}),
                });
            });
        });
    };

    const onAddAddress = (item) => {
        const req = requestsList.find((r) => r.pk === item.pk);
        dispatch(
            setLastStateSearch({
                curReq: item.pk,
                location: req.location,
                createdRequestDate: req.date,
            })
        );

        history.push('/create_request');
    };

    const cartItem = useCallback(
        (elem) => {
            const renderActions = () => {
                if (elem.status.id === 'new') {
                    const deleteCart = () => {
                        setModalPopup({
                            open: true,
                            title: 'Удалить заявку?',
                            onOk: async () => {
                                try {
                                    await dispatch(removeRequest({ pk: elem.pk }));

                                    setInfoPopup({
                                        open: true,
                                        title: 'Заявка удалена',
                                        content: '',
                                    });

                                    updateRequests();
                                } catch (err) {
                                    setInfoPopup({
                                        open: true,
                                        content:
                                            err.error.response.data.message ||
                                            `${err.error.response.status} ${err.error.response.statusText}`,
                                        title: 'Ошибка',
                                    });
                                }
                            },
                        });
                    };
                    return (
                        <img
                            className={styles.tableButton2}
                            alt='icon'
                            src='/delete_circle_icon.svg'
                            onClick={deleteCart}
                        />
                    );
                }
                if (statusAllowCancel.indexOf(elem.status.id) !== -1) {
                    return (
                        <img
                            className={styles.tableButton2}
                            alt='icon'
                            src='/cancel_icon.svg'
                            onClick={() => {
                                setInfoPopup({
                                    open: true,
                                    title: 'Отмена заявки',
                                    content: `Для отмены заявки №${elem.pk} обратитесь к вашему менеджеру`,
                                });
                            }}
                        />
                    );
                }

                return null;
            };
            if (!elem) return null;
            const { date, status, customer_amount, addresses, route } = elem ?? {};

            const editCart = () => {
                const data = {
                    address: {
                        value: elem?.addresses?.[0]?.address,
                        unrestricted_value: elem?.addresses?.[0]?.address,
                    },
                    date: elem?.date ? moment(elem?.date, 'YYYY-MM-DD').toDate() : undefined,
                    time: elem?.confirmed_timepoint
                        ? [elem?.confirmed_timepoint, '__:__']
                        : [null, null],
                    time_interval: !elem?.confirmed_timepoint
                        ? [elem?.addresses?.[0]?.interval_begin, elem?.addresses?.[0]?.interval_end]
                        : [null, null],

                    index: elem?.index,
                    mass: elem?.mass,
                    volume: elem?.addresses?.[0]?.volume,
                    places: elem?.addresses?.[0]?.place_count,
                    shipment_type: elem?.addresses?.[0]?.shipment_type,
                    phones: elem?.phone?.[0],
                    driver: elem?.driver_name,
                    workers_required: elem?.addresses?.[0]?.workers_required,
                };
                setEditPopup((prev) => ({
                    ...prev,
                    data,
                    open: true,
                    title: elem?.pk,
                }));
            };

            const deleteItem = async (pk) => {
                dispatch(removeItem({ item_pk: pk }));
                await updateRequests();
            };

            const onMoveItem = async (pk) => {
                await dispatch(moveItem({ item_pk: pk, target_pk: null }));
                await updateRequests();
            };

            return (
                <div key={elem?.pk} className={styles.blockWrapper}>
                    <div className={styles.blockHeader}>
                        <div>{moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
                        <div className={getStatusClass(status)}>{status?.text}</div>
                        <div>{formatToMoney(customer_amount)}</div>
                        <div className={styles.action}>{renderActions()}</div>
                    </div>

                    <div className={styles.bodyWrapper}>
                        {route && <div className={styles.route}>Маршрут: {route}</div>}
                        <div className={styles.addressesWrapper}>
                            <div className={styles.addressText}>Адреса:</div>
                            <div className={styles.addresses}>
                                {Array.isArray(addresses) ? (
                                    addresses.map((item, idx) =>
                                        !showDetails.includes(elem.pk) ? (
                                            <span key={`${item.index} ${item.id}`}>
                                                {`${idx + 1}. 
                                    ${item.address}`}
                                            </span>
                                        ) : (
                                            <Space
                                                direction='vertical'
                                                className={styles.addressDetails}
                                                key={idx}
                                            >
                                                <div className={styles.addressDetailsHeader}>
                                                    <div
                                                        className={`${styles.status} ${
                                                            item.assigned_workers.length >=
                                                            item.workers_required
                                                                ? styles['status-finished']
                                                                : styles['status-failed']
                                                        }`}
                                                    >
                                                        {item.workers_required}/
                                                        {item.assigned_workers.length} чел
                                                    </div>
                                                    <div>
                                                        {item.interval_begin
                                                            ? moment(
                                                                  item.interval_begin,
                                                                  'HH:mm:ss'
                                                              ).format('HH:mm')
                                                            : '00:00'}
                                                    </div>
                                                </div>
                                                <div className={styles.addressDetailsHeader}>
                                                    <div />
                                                    <div>{item.amount}</div>
                                                </div>
                                                <span>{item.address}</span>
                                                <span>{item.shipment_type}</span>
                                                <div
                                                    className={styles.addressDetailsButtons}
                                                    hidden
                                                >
                                                    {/* to do btns not working + add comment btn */}
                                                    <Button onClick={() => onMoveItem(item.id)}>
                                                        В отдельную
                                                    </Button>
                                                    <Button onClick={() => deleteItem(item.id)}>
                                                        Удалить
                                                    </Button>
                                                    <Button>
                                                        <img
                                                            alt='icon'
                                                            src='/comment.svg'
                                                            className={styles.buttonsContainerIcon1}
                                                        />
                                                    </Button>
                                                </div>
                                                <Spin
                                                    wrapperClassName={styles.photoLoaderContainer}
                                                    className={styles.photoLoader}
                                                    spinning={
                                                        item.photos &&
                                                        !displayRequestImages?.[
                                                            item.photos?.[0]?.url
                                                        ]
                                                    }
                                                >
                                                    <Carousel
                                                        dotPosition='top'
                                                        hidden={!item.photos}
                                                        className={styles.photoGallery}
                                                    >
                                                        {item.photos?.map(
                                                            (photo) =>
                                                                displayRequestImages?.[
                                                                    photo.url
                                                                ] && (
                                                                    <img
                                                                        onClick={() =>
                                                                            setPreviewImages([
                                                                                window.URL.createObjectURL(
                                                                                    displayRequestImages?.[
                                                                                        photo.url
                                                                                    ]
                                                                                ).toString(),
                                                                            ])
                                                                        }
                                                                        src={window.URL.createObjectURL(
                                                                            displayRequestImages?.[
                                                                                photo.url
                                                                            ]
                                                                        )}
                                                                        alt={photo.id}
                                                                    />
                                                                )
                                                        )}
                                                    </Carousel>
                                                </Spin>
                                            </Space>
                                        )
                                    )
                                ) : (
                                    <span>{addresses}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.actionsWrapper}>
                        <div className={styles.actionsGroup}>
                            {((Array.isArray(addresses) && addresses?.length < 2) ||
                                !Array.isArray(addresses)) && (
                                <Button className={styles.route} onClick={editCart}>
                                    Изменить
                                </Button>
                            )}
                            <Button
                                className={styles.route}
                                onClick={() => {
                                    onAddAddress(elem);
                                }}
                            >
                                Добавить адрес
                            </Button>
                        </div>
                        <Button
                            className={styles.route}
                            onClick={async () => {
                                setShowDetails(
                                    showDetails.includes(elem.pk)
                                        ? showDetails.filter((item) => item !== elem.pk)
                                        : [...showDetails, elem.pk]
                                );
                                await loadPictures(elem.addresses);
                            }}
                        >
                            {!showDetails.includes(elem.pk) ? 'Подробнее' : 'Скрыть'}
                        </Button>
                    </div>
                </div>
            );
        },
        [requestImagesList, requestsList]
    );

    useEffect(() => {
        setDisplayRequestImages({
            ...displayRequestImages,
            ...requestImages,
        });
    }, [requestImages]);

    return (
        <Wrapper title='Заявки'>
            {platform === 'desktop' && (
                <Dashboard data={dashboard} filterStatus={filterStatus} setFilter={setFilter} />
            )}
            {platform === 'desktop' && (
                <SearchWrapper
                    placeHolder='Найти заявку'
                    localStorageService={localStorageService}
                    updateData={updateData}
                    itemsOnPage={itemsOnPage}
                    setItemsOnPage={setItemsOnPage}
                >
                    <ViewButton
                        columns={columns}
                        setColumns={setColumns}
                        localStorageField='columns'
                        initColumns={initColumns}
                    />
                </SearchWrapper>
            )}
            <ButtonsContainer
                classes={styles.mediaStyle}
                left={
                    <Space size='middle'>
                        <DatePickerApply
                            localStorageService={localStorageService}
                            defaultRangeStart={moment().startOf('month').toDate()}
                            defaultRangeEnd={moment().toDate()}
                            updateData={updateData}
                        />
                        <CustomSelectWithStorage
                            options={Object.keys(statusInfo).map((item) => ({
                                label: statusInfo[item].title,
                                value: item,
                            }))}
                            placeholder='Статус заявки'
                            localStorageService={localStorageService}
                            updateData={updateData}
                            optionName='status'
                            size='large'
                            allowClear
                            width={180}
                        />
                        <CustomSelectWithStorage
                            options={customerResolution.map(({ title, value }) => ({
                                label: title,
                                value,
                            }))}
                            placeholder='Резолюция'
                            localStorageService={localStorageService}
                            updateData={updateData}
                            optionName='customer_resolution'
                            size='large'
                            allowClear
                            width={180}
                        />
                        <CustomSelectWithStorage
                            options={[
                                { value: 'Несогласованные заявки' },
                                { value: 'Заявки в оплате' },
                                { value: 'Оплаченные заявки' },
                            ]}
                            placeholder='Тип заявок'
                            localStorageService={localStorageService}
                            updateData={updateData}
                            optionName='filter'
                            size='large'
                            allowClear
                            width={200}
                        />
                        {show_locations_filter && (
                            <CustomSelectWithStorage
                                options={branch.map(({ text, id }) => ({
                                    label: text,
                                    value: id,
                                }))}
                                placeholder='Филиал'
                                localStorageService={localStorageService}
                                updateData={updateData}
                                optionName='branch'
                                size='large'
                                allowClear
                                width={180}
                            />
                        )}
                    </Space>
                }
                right={
                    <>
                        <Modal
                            destroyOnClose
                            visible={requestPopup.open}
                            title='Новая заявка'
                            footer={null}
                            onCancel={onCloseNewRequestPopup}
                            className={styles.modal}
                        >
                            <NewRequestPopup
                                close={onCloseNewRequestPopup}
                                route={requestPopup.route}
                                disableList={requestPopup.disable}
                                onSuccess={updateRequests}
                            />
                            {/* )} */}
                        </Modal>

                        <div
                            onClick={() => {
                                if (platform === 'mobile') {
                                    history.push('/create_request');
                                }
                                setRequestPopup({ open: true, route: null, disable: null });
                            }}
                            className={styles.buttonsContainerItem}
                        >
                            <img
                                alt='icon'
                                src='/add_icon.svg'
                                className={styles.buttonsContainerIcon1}
                            />
                        </div>
                        <ImportsButton
                            allow_requests_creation={allow_requests_creation}
                            updateData={updateData}
                        />
                        <DownloadExcelButton />
                    </>
                }
            />

            {platform === 'desktop' && (
                <TableRequestsWrapper
                    isLoading={isRequestsLoading}
                    head={
                        <tr className={styles.tableHead}>
                            <td />
                            {Object.keys(columns)
                                .filter((c) => columns[c].isVisible)
                                .map((c) => {
                                    const img_link = `/sort_${sorting.direction}_icon.svg`;
                                    return (
                                        <td key={c} style={columns[c].style}>
                                            <div
                                                style={{ display: 'flex' }}
                                                className={styles.tableHeadTd}
                                            >
                                                {c === 'route' && (
                                                    <img
                                                        alt='expand icon'
                                                        onClick={toggleExpandAll}
                                                        style={{ marginRight: '5px' }}
                                                        src='/expand_icon.svg'
                                                    />
                                                )}
                                                <div
                                                    onClick={() => changeSorting(c)}
                                                    style={{ display: 'flex' }}
                                                >
                                                    {columns[c].text}
                                                    {sorting.key === c && (
                                                        <img alt='img' src={img_link} />
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    );
                                })}
                            <td />
                        </tr>
                    }
                    body={tableBody()}
                    pagination={
                        <Pagination
                            stylesRef={{
                                wrap: { margin: 0, width: 'auto' },
                                item: { fontSize: '12px', width: '28px', height: '28px' },
                            }}
                            pages={pages}
                            onPageChange={(p) => setPage(p)}
                        />
                    }
                />
            )}
            {platform === 'mobile' ? (
                <>
                    <div>
                        {previewImages.length ? (
                            <ImageViewer
                                images={previewImages}
                                activeImageIndex={0}
                                onClose={() => setPreviewImages([])}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={styles.ordersWraper}>
                        {requestsList?.map((i) => cartItem(i))}
                    </div>
                    <Modal
                        destroyOnClose
                        visible={editPopup.open}
                        title={`Редактирование заявки ${editPopup.title}`}
                        footer={null}
                        onCancel={onCloseEditPopup}
                        className={styles.modal}
                    >
                        <NewRequestPopup
                            close={onCloseEditPopup}
                            data={editPopup.data}
                            onSuccess={updateRequests}
                            route={editPopup.route}
                        />
                    </Modal>
                </>
            ) : null}
            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup.open}
                onClose={() => setInfoPopup({ ...infoPopup, open: false })}
            >
                {(close) => (
                    <PopupInfo title={infoPopup.title} close={close}>
                        <div dangerouslySetInnerHTML={{ __html: infoPopup.content }} />
                    </PopupInfo>
                )}
            </ShortPopup>
            <ShortPopup
                modal
                closeOnDocumentClick
                open={modalPopup.open}
                onClose={() => setModalPopup({ ...modalPopup, open: false })}
            >
                {(close) => (
                    <PopupModal
                        title={modalPopup.title}
                        close={close}
                        onOk={modalPopup.onOk}
                        onCancel={modalPopup.onCancel}
                    >
                        {modalPopup.content}
                    </PopupModal>
                )}
            </ShortPopup>
            <div
                onMouseEnter={() => setTooltipContentHover(true)}
                onMouseLeave={async () => {
                    setTooltipContentHover(false);
                    await updateRequests();
                }}
                className={styles.tooltipWrapper}
                style={{
                    display:
                        tooltipContentHover || tooltipTriggerHover || tooltipOpen
                            ? 'block'
                            : 'none',
                    top: calculateTop(),
                    left: 350,
                }}
            >
                <PhotoTooltip urls={tooltipImgUrls} title='Фото' itemToEdit={itemToEdit} />
            </div>
        </Wrapper>
    );
};
