import { Col, Row, Space, Typography } from 'antd';

import styles from './CreateRequest.module.scss';

export const TotalAmount = ({ calculatedAmount }) => {
    const amountList = [
        ...calculatedAmount.services.map((service) => ({
            label: `${service.service.name}, ${service.expected_hours} часов`,
            value: `${service.amount}₽`,
        })),
        { label: 'Общая сумма', value: `${calculatedAmount.total}₽` },
    ];
    return (
        <Space direction='vertical' className={styles.amountDescription}>
            {amountList.map((item, index) => (
                <Row>
                    <Col>
                        <label htmlFor={index.toString()}>{item.label}</label>
                    </Col>
                    <Col id={index.toString()}>
                        <Typography.Text>{item.value}</Typography.Text>
                    </Col>
                </Row>
            ))}
        </Space>
    );
};
