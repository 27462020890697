import clsx from 'clsx';

import styles from './styles.module.scss';

export const ButtonsContainer = ({ left, right, classes }) => {
    return (
        <div className={clsx(styles.buttonsContainer, classes)}>
            <div className={styles.buttonsContainerLeft}>{left}</div>

            <div className={styles.buttonsContainerRight}>{right}</div>
        </div>
    );
};
