const initState = {
    list: [],
    isLoading: false,
    error: null,
    lastStateSearch: { curReq: '', location: '', createdRequestDate: '', temporarySubmitData: '' },
};

const services = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_SERVICES_REQUEST':
            return { ...state, isLoading: true };
        case 'FETCH_SERVICES_SUCCESS':
            return { ...state, isLoading: false, list: action.payload.data.results };
        case 'FETCH_SERVICES_FAILURE':
            return { ...state, isLoading: false, error: action.error.response.message };
        case 'SET_LAST_STATE_SEARCH':
            return {
                ...state,
                lastStateSearch: {
                    curReq: action.payload.curReq ?? state.lastStateSearch.curReq,
                    location: action.payload.location ?? state.lastStateSearch.location,
                    createdRequestDate:
                        action.payload.createdRequestDate ??
                        state.lastStateSearch.createdRequestDate,
                    temporarySubmitData:
                        action.payload.temporarySubmitData ??
                        state.lastStateSearch.temporarySubmitData,
                },
            };

        default:
            return state;
    }
};

export default services;
