import { forwardRef } from 'react';

import { Input } from '@components/Input';

export const NumberInput = forwardRef(({ onChange, ...props }, ref) => {
    const onInputChange = ({ target }) => {
        const isNumberValue = /^[\d\,\.]*$/.test(target.value);
        if (isNumberValue) {
            onChange(target.value);
        }
    };

    return <Input {...props} onChange={onInputChange} ref={ref} />;
});
