import { authorization } from '@utils';

const user = (
    state = {
        info: { name: null, balance: null },
        token: null,
        isLoading: false,
        error: null,
        isAuthenticated: false,
    },
    action
) => {
    switch (action.type) {
        case 'OBTAIN_TOKEN_REQUEST':
            return { ...state, isLoading: true, isAuthenticated: false };
        case 'OBTAIN_TOKEN_SUCCESS':
            return {
                ...state,
                isLoading: false,
                token: action.payload.data.access_token,
                refresh_token: action.payload.data.refresh_token,
                isAuthenticated: true,
            };
        case 'OBTAIN_TOKEN_FAILURE':
            return {
                ...state,
                isLoading: false,
                error: action?.error?.response?.data?.message || 'Error fetch',
                isAuthenticated: false,
            };

        case 'FINISH_REGISTRATION_REQUEST':
            return { ...state, isLoading: true, isAuthenticated: false };
        case 'FINISH_REGISTRATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                token: action.payload.data.access_token,
                isAuthenticated: true,
            };
        case 'FINISH_REGISTRATION_FAILURE':
            return {
                ...state,
                isLoading: false,
                error: action.error.response.data.message,
                isAuthenticated: false,
            };

        case 'ACCOUNT_INFO_REQUEST':
            return { ...state, isLoading: false, isAuthenticated: false };
        case 'ACCOUNT_INFO_SUCCESS':
            return {
                ...state,
                token: authorization.getToken(),
                isLoading: false,
                info: {
                    ...action.payload.data,
                    name: action.payload.data.full_name,
                    balance: action.payload.data.balance,
                    registrationStatus: action.payload.data.registration_status,
                    importsUpdated: action.payload.data.imports_updated,
                },
                isAuthenticated: true,
            };
        case 'ACCOUNT_INFO_FAILURE':
            return {
                ...state,
                isLoading: false,
                error: action.error.response.data.message,
                isAuthenticated: false,
            };

        case 'LOG_OUT':
            return { ...state, token: null, isAuthenticated: false };

        default:
            return state;
    }
};

export default user;
