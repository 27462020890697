export const legalEntity = 'ООО';

const formatFormValues = (formValues) => {
    return Object.entries(formValues).filter(([name, value]) => {
        const isNotNeedReasonCode =
            name === 'reason_code' && formValues.entity !== legalEntity && !value;

        if (isNotNeedReasonCode) {
            return false;
        }

        return true;
    });
};

export const requiredRule = [
    {
        required: true,
        message: 'Это поле обязательно!',
    },
];

export const isAllFieldsFilled = (formValues) => {
    for (const [_, value] of formatFormValues(formValues)) {
        if (!value) {
            return false;
        }
    }

    return true;
};

export const transformDadata = (value) => {
    return value?.unrestricted_value;
};

export const formatDataToFormData = ({ is_legal_entity, ...data }) => {
    return {
        ...data,
        entity: is_legal_entity ? legalEntity : undefined,
    };
};
