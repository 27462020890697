import { Form } from 'antd';
import clsx from 'clsx';

import { Button } from '@components/Button';

import styles from './styles.module.scss';

const requiredTouchedList = ['shipment_type', 'address'];

const requiredList = [...requiredTouchedList, 'date'];

export const FormControls = () => {
    return (
        <Form.Item shouldUpdate>
            {({ getFieldsError, getFieldsValue, getFieldValue }) => {
                const isErrors = getFieldsError(requiredList).some(({ errors }) => errors.length);
                const isNotValues =
                    !Object.values(getFieldsValue(requiredList)).every((value) => {
                        if (Array.isArray(value)) {
                            return value.every((valueItem) => valueItem);
                        }

                        return value;
                    }) ||
                    (!getFieldValue('time') && !getFieldValue('time_interval'));

                const submitStyle = clsx(styles.button, {
                    [styles.disabled]: isErrors || isNotValues,
                });

                return (
                    <Button
                        htmlType='submit'
                        disabled={isErrors || isNotValues}
                        className={submitStyle}
                    >
                        Создать новую заявку
                    </Button>
                );
            }}
        </Form.Item>
    );
};
