import { useState } from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';

import { TextArea } from '@components/TextArea';

import { Label } from './Label';

import styles from './styles.module.scss';

const fieldsNameList = {
    'Планируемая дата': 'date',
    'Индекс груза': 'index',
    'Общий вес, кг': 'mass',
    'Объем, м3': 'volume',
    'Кол-во мест': 'places',
    'Характер работ': 'shipment_type',
    'Адрес забора/доставки': 'address',
    'Временной интервал': 'time',
    'Контактное лицо, тел': 'phones',
    'Контактное лицо, ФИО': 'driver',
};

const helperText =
    'В это поле можно вставить текст в формате\n' +
    '"Планируемая дата: 28.01.2023\n' +
    'Индекс груза: МИРЧТХ-11/277\n' +
    'Общий вес, кг: 134\n' +
    'Объем, м3: 1,55\n' +
    'Кол-во мест: 10\n' +
    'Характер работ: ЛИЧНЫЕ ВЕЩИ\n' +
    'Адрес забора/доставки: Москва Неманский проезд 7к1\n' +
    'Временной интервал: 09:00-18:00\n' +
    'Контактное лицо, ФИО: ВЕМЯЕВ АНАТОЛИЙ НИКОЛАЕВИЧ\n' +
    'Контактное лицо, тел: 89653676747", и все поля заполнятся автоматически.';

export const AutoFillItem = ({ form, routeId }) => {
    const [text, setText] = useState('');

    const onAutoFillForm = (value) => {
        const getDataRegex = /^([а-я ,-/\d]+): ([\d\w\.:-а-я/, ]+)/i;
        const formattedValue = value.replace(/"/g, '');

        const lines = formattedValue.split('\n').map((line) => {
            const [, key, value] = line.match(getDataRegex) || [];
            return [key, value];
        });

        for (const [key, value] of lines) {
            const fieldName = fieldsNameList[key];

            if (fieldName) {
                setFieldData(fieldName, value);
            }
        }

        form.validateFields();
    };

    const setFieldData = (name, value) => {
        const isExistRoute = routeId !== -1;
        let formattedValue = value;

        switch (name) {
            case 'address':
                formattedValue = {
                    value,
                };
                break;

            case 'date':
                formattedValue = moment(value, 'DD.MM.YYYY').toDate();
                break;

            case 'time': {
                formattedValue = value.split('-');
                break;
            }
            default:
                break;
        }

        if (name === 'date' && isExistRoute) {
            return;
        }

        if (name === 'time') {
            if (moment(formattedValue?.[1], 'HH:mm').isValid()) {
                form.setFields([{ name: 'time', value: [null, null] }]);
                form.setFields([{ name: 'time_interval', value: formattedValue }]);
                return;
            }
            form.setFields([{ name: 'time_interval', value: [null, null] }]);
            form.setFields([{ name, value: [formattedValue[0], '__:__'] }]);
            return;
        }

        form.setFields([{ name, value: formattedValue }]);
    };
    return (
        <>
            <Label>Весь текст заявки (из 1С)</Label>

            <Tooltip
                destroyTooltipOnHide
                title={<span className={styles.textarea_tooltip}>{helperText}</span>}
                placement='bottom'
                color='white'
            >
                <TextArea
                    autoSize={{ minRows: 1 }}
                    className={styles.textarea}
                    onPaste={(e) => {
                        const data = e.clipboardData.getData('Text');
                        onAutoFillForm(data);
                        setText(data);
                    }}
                    value={text}
                />
            </Tooltip>
        </>
    );
};
