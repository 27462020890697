import { memo } from 'react';

import { DashboardItem } from './DashboardItem';
import { DashboardPieChart } from './DashboardPieChart';

import styles from './styles.module.scss';

export const Dashboard = memo(({ data }) => {
    const dashboardItemsEl = [...data.items.values()].map(({ groups, title, total }) => (
        <DashboardItem
            key={title}
            groups={[...groups.values()]}
            dashBoardTotal={data.total}
            itemTotal={total}
            title={title}
        />
    ));

    return (
        <div className={styles.dashboard}>
            <DashboardPieChart data={data} />

            {dashboardItemsEl}
        </div>
    );
});
