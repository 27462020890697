import { Col, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import styles from './styles.module.scss';

const cardTypeMap = {
    4: 'VISA',
    5: 'MASTERCARD',
    2: 'МИР',
};

export const ExistingCard = ({ card_data, onDelete }) => {
    const cardType = cardTypeMap?.[card_data.number[0]] || '';
    return (
        <Space direction='horizontal' className={styles.existingCardData}>
            <Row className={styles.cardDataRow}>
                <Col className={styles.cardNumCol}>
                    <Text className={styles.cardType} hidden={!cardType || cardType === ''}>
                        {cardType}
                    </Text>
                    <Text className={styles.cardNum}>{card_data.number}</Text>
                    <Text>{card_data.expiration_date}</Text>
                </Col>
                <Col onClick={() => onDelete(card_data.id)}>
                    <img src='/delete_icon.svg' alt='delete_icon' />
                </Col>
            </Row>
        </Space>
    );
};
