import { useEffect, useState } from 'react';
import InputSlider from 'react-input-slider';
import { useSelector } from 'react-redux';

import { platformSelector } from '@store/reducers/platform';

import { useElementScroll, useElementSize, useWindowSize } from './hooks';

import styles from './styles.module.scss';

export const ScrollSlider = ({ wrapRef, contentRef, children }) => {
    const [x, setX] = useState(0);
    const elScroll = useElementScroll(wrapRef.current);
    useWindowSize();
    useElementSize(contentRef.current);

    useEffect(() => setX(elScroll), [elScroll]);

    const getMax = () => {
        const wrapWidth = wrapRef.current ? wrapRef.current.clientWidth : 0;
        const contentWidth = contentRef.current ? contentRef.current.clientWidth : 0;
        const max = wrapWidth - contentWidth;
        if (x > -max) {
            setX(-max);
        }
        return max < 0 ? -max : max;
    };

    const sliderHandler = (x) => {
        wrapRef.current.scrollLeft = x;
    };

    const platform = useSelector(platformSelector);

    if (platform === 'mobile') return null;

    return (
        <div className={styles.scrollWrapper} data-id-type='scrollWrapper'>
            <div className={styles.scrollContent}>
                <div className={styles.forSlider} style={{}}>
                    <InputSlider
                        xmin={0}
                        xmax={getMax()}
                        className={styles.scroll}
                        styles={{
                            track: {
                                backgroundColor: '#F2F2F2',
                                height: '4px',
                                borderRadius: '2px',
                                display: 'block',
                            },
                            active: {
                                backgroundColor: '#F2F2F2',
                            },
                            thumb: {
                                width: '100px',
                                height: '12px',
                                background:
                                    'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 32%, rgba(217,217,217,1) 33%, rgba(217,217,217,1) 66%, rgba(255,255,255,0) 67%, rgba(255,255,255,0) 100%)',
                                borderRadius: '4px',
                                boxShadow: 'none',
                            },
                        }}
                        axis='x'
                        x={elScroll}
                        onChange={({ x }) => sliderHandler(x)}
                    />
                </div>
                {children}
            </div>
        </div>
    );
};
