import InputMask from 'react-input-mask';
import { Button, Form, Input, Row, Space } from 'antd';

const defaultRule = [{ required: true, message: 'Это поле обязательно' }];
const cardRule = [
    { required: true, message: 'Это поле обязательно' },
    {
        max: 19,
        min: 19,
        message: 'Номер карты должен содержать 16 цифр',
    },
];

export const CardDataInput = ({ styles }) => (
    <Space direction='vertical' className={styles.cardData}>
        <Form.Item label='Имя на карте' name='cardholderName' rules={defaultRule}>
            <Input
                className={`${styles.cardInput} ${styles.cardHolder}`}
                placeholder='IVANOV IVAN'
            />
        </Form.Item>
        <Form.Item label='Номер карты' name='cardNumber' rules={cardRule}>
            <InputMask mask='9999 9999 9999 9999' placeholder='1234 1234 1234 1234'>
                <Input
                    prefix={<img src='/card.svg' alt='card_icon' className={styles.cardIcon} />}
                    className={styles.cardInput}
                    placeholder='1234 1234 1234 1234'
                />
            </InputMask>
        </Form.Item>
        <Row>
            <Form.Item label='Срок действия' name='expiryDate' rules={defaultRule}>
                <InputMask
                    mask='99/99'
                    className={`ant-input ${styles.cardInput}`}
                    // alwaysShowMask
                    placeholder='MM/YY'
                />
            </Form.Item>
            <Form.Item label='СVV/CVC' name='cvv' rules={defaultRule}>
                <InputMask
                    mask='999'
                    className={`ant-input ${styles.cardInput}`}
                    // alwaysShowMask
                    placeholder='123'
                />
            </Form.Item>
        </Row>
        <div className={styles.cardDataSubmit}>
            <Button type='primary' htmlType='submit'>
                Подтвердить
            </Button>
        </div>
    </Space>
);
