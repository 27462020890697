import { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'clsx';

import styles from './styles.module.scss';

export const TimePicker = ({ close, value, onChange, onlyOneValue, className }) => {
    const [input1, setInput1] = useState(value?.[0] || '__:__');
    const [input2, setInput2] = useState(value?.[1] || '__:__');

    useEffect(() => {
        setInput1(value?.[0] || '__:__');
        setInput2(value?.[1] || '__:__');
    }, [value]);

    const normalize = () => {
        let i1 = input1;
        let i2 = input2;
        // normalize input1
        if (!input1.includes('_')) {
            const [hh, mm] = input1.split(':');
            const HH = String(Math.min(+hh, 23)).padStart(2, '0');
            const MM = String(Math.min(+mm, 59)).padStart(2, '0');
            i1 = `${HH}:${MM}`;
        }
        // normalize input2
        if (!onlyOneValue || !input2.includes('_')) {
            const [hh, mm] = input2.split(':');
            const HH = String(Math.min(+hh, 23))?.padStart(2, '0');
            const MM = String(Math.min(+mm, 59))?.padStart(2, '0');
            if (HH && MM) {
                i2 = `${HH}:${MM}`;
            }
        }
        // swap inputs if second one's time less than the first one's
        const [hh1, mm1] = input1.split(':');
        const [hh2, mm2] = input2.split(':');
        const ss1 = hh1 * 3600 + mm1 * 60;
        const ss2 = hh2 * 3600 + mm2 * 60;

        let a1 = Number.isNaN(ss1) ? null : i1;
        let a2 = Number.isNaN(ss2) ? null : i2;
        if (ss1 > ss2 || (Number.isNaN(ss1) && ss2)) {
            [a1, a2] = [a2, a1];
        }

        onChange([a1, a2]);
        setInput1(i1);
        if (!onlyOneValue) setInput2(i2);
        if (!input1.includes('_') && onlyOneValue) onChange([i1, i2]);
    };

    const popapContent = useMemo(
        () => (
            <div className={classNames(styles.popupContent, className)}>
                <div className={styles.wrapper}>
                    <InputMask
                        onBlur={normalize}
                        mask='99:99'
                        alwaysShowMask
                        onChange={(e) => setInput1(e.target.value)}
                        value={input1}
                    />
                    {!onlyOneValue && (
                        <>
                            <div className={styles.dash} />
                            <InputMask
                                onBlur={normalize}
                                mask='99:99'
                                alwaysShowMask
                                onChange={(e) => setInput2(e.target.value)}
                                value={input2}
                            />
                        </>
                    )}
                </div>
            </div>
        ),
        [input1, input2, value]
    );

    return (
        <div className={styles.popup}>
            <div onClick={close} className={styles.popupClose}>
                <img alt='icon' src='/close_icon.svg' />
            </div>
            <div className={styles.popupTitle}>Выбрать время</div>
            {popapContent}
            <div onClick={close} className={styles.button}>
                Ок
            </div>
        </div>
    );
};
