import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { updateRequest } from '@store/actions';

import { sortingList } from './utils';

import styles from './styles.module.scss';

export const ButtonApprove = ({
    pk,
    value,
    style,
    sorting,
    isVisible: _isVisible,
    elemsData,
    setSelectedApprove,
    selectedApprove,
}) => {
    const dispatch = useDispatch();

    const isVisible =
        _isVisible &&
        elemsData?.every(
            (i) => i.worked_hours && i.start && i.end && i.name && i.score?.percentage
        );

    return (
        <div className={clsx(styles.buttonsContainer2, styles.inline)} style={style}>
            <div
                className={clsx(styles.approveButton, styles.inline, {
                    [styles.active]: isVisible,
                })}
                onClick={() => {
                    if (isVisible) {
                        dispatch(
                            updateRequest({ sortingList, sorting })(undefined, {
                                request: pk,
                                customer_confirmation: value,
                                force_commit: false,
                            })
                        );
                        setSelectedApprove(selectedApprove?.[0]?.pk === pk ? [] : selectedApprove);
                    } else {
                        setSelectedApprove(elemsData);
                    }
                }}
            >
                {value ? (
                    <div className={styles.displayNone}>
                        <img alt='icon' src='/check_icon.svg' />
                    </div>
                ) : (
                    <div className={styles.displayNone}>x</div>
                )}
                {value && (
                    <div
                        style={{
                            background: '#f96868',
                        }}
                        className={styles.displayFlex}
                    >
                        Нет
                    </div>
                )}
                {!value && (
                    <div
                        style={{
                            background: '#6ae574',
                        }}
                        className={styles.displayFlex}
                    >
                        Да
                    </div>
                )}
            </div>
        </div>
    );
};
