import { Form } from 'antd';
import styled from 'styled-components';

export const FormItem = styled(
    ({ children, isLabelVertical, style, marginBottom = 12, ...props }) => {
        const labelCol = isLabelVertical ? { span: 24 } : {};

        return (
            <Form.Item labelCol={labelCol} style={{ ...style, marginBottom }} {...props}>
                {children}
            </Form.Item>
        );
    }
)`
    ${({ notInfoMessage }) =>
        `
    & .ant-form-item-explain{
        display: ${notInfoMessage ? 'none' : 'block'}
    }


    `}}

    & .ant-form-item-label {
        padding: 5px 0 0 0;
    }
`;
