import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Unfilled } from './Unfilled';

export const InfoPage = withRouter(() => {
    const { registrationStatus } = useSelector((state) => state.user.info);

    if (registrationStatus) {
        return <Unfilled />;
    }

    return null;
});
