import { useDispatch, useSelector } from 'react-redux';

import { logout } from '@store/actions';
import { authorization } from '@utils';

import styles from './styles.module.scss';

export const UserMenu = () => {
    const dispatch = useDispatch();
    const { balance, name } = useSelector((state) => ({
        name: state.user.info.name,
        balance: state.user.info.balance,
    }));

    if (balance === null) {
        return null;
    }

    return (
        <div className={styles.user}>
            <div className={styles.usernameWrapper}>
                <div className={`${styles.balance} ${balance.highlight ? styles.red : ''}`}>
                    {balance.text}&nbsp;&#8381;
                </div>
                <div className={styles.username}>{name}</div>
                <img alt='icon' src='/arrow_down_icon.svg' />
            </div>
            <div className={styles.underline} />
            <div
                onClick={() => {
                    authorization.removeToken();
                    dispatch(logout());
                }}
                className={styles.logout}
            >
                <img alt='icon' src='/logout_icon.svg' />
                Выйти
            </div>
        </div>
    );
};
