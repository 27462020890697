import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Sidebar } from '@components';
import {
    BalancePage,
    DailyConfirmationPage,
    FinishRegistration,
    HomePage,
    ImportHistoryPage,
    InfoPage,
    LoginPage,
    ReportsPage,
    Requests2Page,
    RequestsOnMapPage,
    RequestsPage,
    ResetPasswordPage,
    SignupPage,
    UpdatePasswordPage,
} from '@pages';

import { CacheControlProvider } from '../../providers/CacheControlProvider';
import { CreateRequest } from '../CreateRequest';
import { PhoneLoginPage } from '../PhoneLoginPage';
import { RequestsCalendarPage } from '../RequestsCalendarPage';

import { PrivateRoute } from './PrivateRouter';

export const Routes = () => {
    const disable_lk_pages = useSelector((state) => state.user.info.disable_lk_pages);

    if (disable_lk_pages)
        return (
            <CacheControlProvider>
                <Switch>
                    <PrivateRoute exact path='/home'>
                        <Sidebar menu='home' />
                        <HomePage />
                    </PrivateRoute>

                    <PrivateRoute exact path='/requests_2'>
                        <Sidebar menu='requests_2' />
                        <Requests2Page />
                    </PrivateRoute>

                    <Route exact path='/daily_reconciliation/:uid'>
                        <DailyConfirmationPage />
                    </Route>

                    <Route exact path='/login'>
                        <LoginPage />
                    </Route>

                    <Route exact path='/phone_login'>
                        <PhoneLoginPage />
                    </Route>

                    <Route exact path='/signup'>
                        <SignupPage />
                    </Route>

                    <Route exact path='/reset_password'>
                        <ResetPasswordPage />
                    </Route>

                    <Route exact path='/update_password/:uid'>
                        <UpdatePasswordPage />
                    </Route>

                    <Route path='/'>
                        <Redirect to='/requests_2' />
                    </Route>
                </Switch>
            </CacheControlProvider>
        );

    return (
        <CacheControlProvider>
            <Switch>
                <PrivateRoute exact path='/home'>
                    <Sidebar menu='home' />
                    <HomePage />
                </PrivateRoute>

                <PrivateRoute exact path='/requests_2'>
                    <Sidebar menu='requests_2' />
                    <Requests2Page />
                </PrivateRoute>

                <PrivateRoute exact path='/requests'>
                    <Sidebar menu='requests' />
                    <RequestsPage />
                </PrivateRoute>

                <PrivateRoute exact path='/requests_calendar'>
                    <Sidebar menu='requests_calendar' />
                    <RequestsCalendarPage />
                </PrivateRoute>

                <PrivateRoute exact path='/create_request'>
                    <CreateRequest />
                </PrivateRoute>

                <PrivateRoute exact path='/requests_on_map'>
                    <Sidebar menu='requests_on_map' />
                    <RequestsOnMapPage />
                </PrivateRoute>

                <PrivateRoute exact path='/reports'>
                    <Sidebar menu='reports' />
                    <ReportsPage />
                </PrivateRoute>

                <PrivateRoute exact path='/info'>
                    <Sidebar menu='info' />
                    <InfoPage />
                </PrivateRoute>

                <PrivateRoute exact path='/imports'>
                    <Sidebar menu='imports' />
                    <ImportHistoryPage />
                </PrivateRoute>

                <PrivateRoute exact path='/balance'>
                    <Sidebar menu='balance' />
                    <BalancePage />
                </PrivateRoute>

                <Route exact path='/daily_reconciliation/:uid'>
                    <DailyConfirmationPage />
                </Route>

                <Route exact path='/login'>
                    <LoginPage />
                </Route>

                <Route exact path='/phone_login'>
                    <PhoneLoginPage />
                </Route>

                <Route exact path='/signup'>
                    <SignupPage />
                </Route>

                <Route exact path='/reset_password'>
                    <ResetPasswordPage />
                </Route>

                <Route exact path='/update_password/:uid'>
                    <UpdatePasswordPage />
                </Route>

                <Route path='/finish_registration/:uid' component={FinishRegistration} />

                <Route path='/'>
                    <Redirect to='/home' />
                </Route>
            </Switch>
        </CacheControlProvider>
    );
};
