import { forwardRef } from 'react';
import { Button, Form } from 'antd';

import styles from '../styles.module.scss';

const downloadIcon = <img alt='icon' src='/save_icon.svg' className={styles.icon} />;
const importIcon = <img alt='icon' src='/import_icon.svg' className={styles.icon} />;

export const ImportButton = forwardRef((props, ref) => {
    return (
        <Form.Item>
            <Button type='text' icon={importIcon} ref={ref} {...props}>
                Загрузить скан договора
            </Button>
        </Form.Item>
    );
});

export const DownloadButton = (props) => {
    return (
        <Form.Item>
            <Button type='text' icon={downloadIcon} {...props}>
                Скачать договор
            </Button>
        </Form.Item>
    );
};
