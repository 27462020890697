import { useCallback } from 'react';
import { Form, Typography } from 'antd';
import clsx from 'clsx';

import { FormItemHelpers, Input } from '@components';

import { ContainerFormItem } from './ContainerFormItem';
import { useItemStatus } from './hooks';

import styles from './styles.module.scss';

const Label = ({ onClick, children }) => {
    return <span onClick={onClick}>{children}</span>;
};

const enterCode = 13;

export const ViewChangingFormItem = ({
    renderContent,
    name,
    label,
    form,
    transViewText,
    getValueProps,
    currentFocusName,
    setCurrentFocusName,
    onFocusNextItem,
    onFinish,
    rules,
    type,
    hidden,
    dependencies = [],
    ...props
}) => {
    const { itemStatus, onError, onSuccess, onLoading } = useItemStatus();

    const isActiveFocus = currentFocusName === name;

    const onFieldClick = () => {
        if (!isActiveFocus) {
            setCurrentFocusName(name);
        }
    };

    const onFinishSucess = () => {
        onSuccess();
        onFocusNextItem?.();
    };

    const onFinishItem = async () => {
        try {
            await form.validateFields([name]);

            onFinish?.({ onLoading, onError, onSuccess: onFinishSucess });
        } catch (err) {
            onError();
        }
    };

    const onKeyDown = (event) => {
        if (event?.keyCode === enterCode) {
            event?.preventDefault();
        }
    };

    const onBlur = () => {
        setCurrentFocusName(null);
        onFinishItem();
    };

    const onPressEnter = () => {
        setCurrentFocusName(null);
        onFinishItem();
    };

    const onItemChange = (value) => {
        return getValueProps?.(value) || { value };
    };

    const itemStyles = clsx(styles.item, {
        [styles.item__pointer]: !isActiveFocus,
    });

    const renderItemContent = useCallback(
        (currentValue) => {
            if (itemStatus) {
                return <FormItemHelpers status={itemStatus} />;
            }

            if (isActiveFocus) {
                if (renderContent) {
                    return renderContent?.({ onBlur, onFinish: onPressEnter });
                }

                return (
                    <Input
                        {...props}
                        className={styles.input}
                        onPressEnter={onPressEnter}
                        type={type}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        autoFocus
                    />
                );
            }

            return <Typography.Text>{currentValue || '-'}</Typography.Text>;
        },
        [isActiveFocus, itemStatus]
    );

    return (
        <ContainerFormItem
            labelCol={{ span: 8, pull: 1 }}
            colon={false}
            labelAlign='left'
            hidden={hidden}
            label={<Label onClick={onFieldClick}>{label}</Label>}
            dependencies={[...dependencies, name]}
        >
            {({ getFieldValue, getFieldsValue }) => {
                const value = getFieldValue(name);
                const currentValue = transViewText?.(value) || value;

                const currentRules = typeof rules === 'function' ? rules(getFieldsValue()) : rules;

                return (
                    <Form.Item
                        className={itemStyles}
                        name={name}
                        wrapperCol={{ span: 24 }}
                        getValueProps={onItemChange}
                        onClick={onFieldClick}
                        rules={currentRules}
                        {...props}
                    >
                        {renderItemContent(currentValue)}
                    </Form.Item>
                );
            }}
        </ContainerFormItem>
    );
};
