import clsx from 'clsx';

import styles from './styles.module.scss';

export const PopupInfo = ({
    close,
    title,
    children,
    buttons = null,
    classNamePopupTitle,
    classNamePopupContent,
    classNameButtons,
}) => {
    const renderButtons = buttons ? (
        buttons.map(({ onClick, text }, key) => (
            <div onClick={onClick} className={styles.button} key={key}>
                {text}
            </div>
        ))
    ) : (
        <div onClick={close} className={styles.button}>
            Ок
        </div>
    );

    return (
        <div className={styles.popup}>
            <div onClick={close} className={styles.popupClose}>
                <img alt='icon' src='/close_icon.svg' />
            </div>
            <div className={clsx(styles.popupTitle, classNamePopupTitle)}>{title}</div>
            <div className={clsx(styles.popupContent, classNamePopupContent)}>{children}</div>
            <div className={clsx(styles.buttons, classNameButtons)}>{renderButtons}</div>
        </div>
    );
};
