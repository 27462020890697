export function setPlatform(platform) {
    return (dispatch) => {
        dispatch({ type: 'SET_PLATFORM', payload: platform });
    };
}

export function setOpenPlatform(open) {
    return (dispatch) => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        dispatch({ type: 'SET_OPEN_PLATFORM', payload: open });
    };
}
