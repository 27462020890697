import { useEffect } from 'react';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import { message } from 'antd';

const PAGE_RELOAD_TIMEOUT = 1000;

const AppDecorator = ({ children }) => {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
    useEffect(() => {
        if (!isLatestVersion) {
            message.info('Требуется обновление. Страница будет перезагружена.');
            setTimeout(() => {
                emptyCacheStorage();
                // window.location.reload();
            }, PAGE_RELOAD_TIMEOUT);
        }
    }, []);
    return children;
};

export const CacheControlProvider = ({ children }) => {
    return (
        <ClearCacheProvider duration={1000 * 60 * 10} auto>
            <AppDecorator>{children}</AppDecorator>
        </ClearCacheProvider>
    );
};
