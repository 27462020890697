const initState = { platform: 'desktop', open: false };

const platform = (state = initState, action) => {
    switch (action.type) {
        case 'SET_PLATFORM':
            return { ...state, platform: action.payload };

        case 'SET_OPEN_PLATFORM':
            return { ...state, open: action.payload };

        default:
            return state;
    }
};

export const platformSelector = (state) => state.platform.platform;
export const isOpenPlatformSelector = (state) => state.platform.open;

export default platform;
