const cards = (state = { list: [], isLoading: false, error: null }, action) => {
    switch (action.type) {
        case 'FETCH_CARDS_REQUEST':
            return { ...state, isLoading: true };
        case 'FETCH_CARDS_SUCCESS':
            return { ...state, isLoading: false, data: action.payload.data.cards };
        case 'FETCH_CARDS_FAILURE':
            return {
                ...state,
                isLoading: false,
                // error: action.error.response.message,
                data: undefined,
            };

        default:
            return state;
    }
};

export default cards;
